import {Modal, Form, Input, DatePicker, Table, Button, Col, Row, Skeleton, ConfigProvider} from 'antd';
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import ajax, {format} from "../../lib/util";
import {getOrderPriority} from "../Order/OrderEdit";
import {renderSequence} from "../Stages/Shared";
import locale from 'antd/es/date-picker/locale/ru_RU';
import OpHistory from "../../components/OpHistory";

const ShipmentEdit = (props) => {

    const {onClose, onOK} = props;
    const [form] = Form.useForm();
    const [selectedRows, setSelectedRows] = useState([]);
    const [rows, setRows] = useState(props.assigned);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    const orderColumns = [
        {title: '№', dataIndex: 'order_number', key: 'order_number', width: 30},
        {
            title: 'Дата создания', dataIndex: 'created_at', key: 'created_at', width: 70, render: (text) => {
                return dayjs(text).format('LL')
            }
        },
        {title: 'Заказчик', dataIndex: 'organization', key: 'organization', width: 200},
        {
            title: 'Приоритет', dataIndex: 'priority', key: 'priority', width: 80, render: (value) => {
                return getOrderPriority(value);
            }
        },
        {title: 'Тип заказа', dataIndex: 'order_type', key: 'order_type', width: 80},
    ];

    const assignedColumns = [
        {
            title: 'Дата синтеза', dataIndex: 'oligonucleotid', render(v, row) {
                return dayjs(row.places[0].calendar.date).format('LL');
            }
        },
        {
            title: 'Оборудование/Период', dataIndex: 'device_name', width: 180,
            render(v, item) {
                return `${item.places[0]?.device_short_name || '-'} / ${item.places[0]?.device_cycle_name || '-'}`;
            }
        }, {
            title: 'Позиция пробирки', dataIndex: 'device_name',
            render(v, item) {
                return `${item.places[0]?.device_place_name || '-'} / ${item.places[0]?.device_place_position || '-'}`;
            }
        },
        // масштаб
        {
            title: 'Масштаб', dataIndex: 'all_places', render(v, row) {
                if (v && v.length) {
                    return `${v[0].device_scale} ${v[0].device_scale_dimension}`;
                }
            }
        },
        {
            title: 'Наименование', dataIndex: 'oligonucleotid',
            render: (v) => {
                return v['name'] ? v['name'] : '?';
            }
        },
        {
            title: 'Последовательность', dataIndex: 'olig_sequence',
            render: (v, row) => {
                return renderSequence(v, row);
            }
        },
        {
            title: 'Длина', dataIndex: 'oligonucleotid',
            render: (v) => {
                return v['length'] ? v['length'] : '?';
            }
        },
        {title: 'OE', dataIndex: 'quantity', render: value => format(value)},
        {title: 'МЛ', dataIndex: 'mkl', render: value => format(value)},
        {title: '260/280', dataIndex: '260_280', render: value => format(value)},
        {title: '260/230', dataIndex: '260_230', render: value => format(value)},
        {
            title: 'НМОЛЬ', dataIndex: 'nanomole', render: value => format(value)
        },
        {
            title: 'E260', dataIndex: 'current_oligonucleotide', render: (v) => {
                return format(v.e260);
            }
        },
        {
            title: 'MW', dataIndex: 'current_oligonucleotide', render(v) {
                const mv = parseFloat(v.mw);
                return format(mv);
            }
        },
        {title: 'МГ', dataIndex: 'mkg', render: (v) => format(v)},
        {
            title: 'История операций', fieldName: 'operations', dataIndex: 'operations',
            key: 'operations', width: 220,
            render: (v, record) => {
                return <OpHistory history={record.operations} record={record} />;
            }
        },
    ];

    function saveShipment() {
        let url = props.data.id ? `/api/shipment/update/${props.data.id}` : '/api/shipment/store';
        let postData = {
            id: props.data.id,
            ...form.getFieldsValue(),
            portions: selectedRows.map((row) => row.id),
        }
        return new Promise((resolve, reject) => {
            ajax.postJSON(url, postData).then((response) => {
                if (onOK) {
                    onOK();
                }
                resolve();
            }).catch((error) => {
                console.log('save shipment error', error);
                reject();
            });
        });
    }

    function loadShipment(id) {
        ajax.getJSON(`/api/shipment/${id}`).then(async (response) => {
            form.setFieldsValue({
                ...response,
                passport_date: dayjs(response.passport_date),
                shipment_date: dayjs(response.shipment_date),
            });
            let portions = response.portions.map((portion) => {
                return {
                    ...portion,
                    name: portion.oligonucleotid.name,
                    full_sequence: portion.oligonucleotid.full_sequence,
                    all_places: portion.places,
                    types: portion.oligonucleotid.types.map((type) => type.name).join(', '),
                    length: portion.oligonucleotid.length,
                    work_sequence: portion.current_oligonucleotide?.full_sequence || '-',
                    target_sequence: portion.oligonucleotid?.full_sequence || '-',

                };
            });
            setRows(portions);
            setSelectedRows(response.portions);
        }).catch((error) => {
            console.log('load shipment error', error);
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        if (props.data.id) {
            setLoading(true);
            loadShipment(props.data.id);
        } else {
            form.resetFields();
        }
    }, []);

    function onSaveClick() {
        form.validateFields().then(() => {
            setSaving(true);
            saveShipment().then(() => {
                if (onClose) {
                    onClose(false);
                }
            }).finally(() => {
                setSaving(false);
            });
        });
    }

    function getDisabled() {
        return selectedRows.length === 0;
    }


    const getRows = () => {
        // 1) если создание отгрузки, то показываем только то, что можно прикрепить (props.assigned)
        // 2) если редактирование - и то, что уже прикреплено (rows), и что еще можно прикрепить (props.assigned)
        let data = props.data.id ? [].concat(rows, props.assigned) : props.assigned;
        /////////////////////////////////////////////////////////////////////////////////////////////////////////
        // удалить дубликаты, если есть
        let map = {};
        let result = [];
        data.forEach((row) => {
            if (!map[row.id]) {
                map[row.id] = true;
                result.push(row);
            }
        });
        return result;
    }

    return (
        <Modal open={true}
               destroyOnClose={true}
               title="Создание / Редактирование отгрузки"
               onCancel={() => onClose(true)}
               footer={[
                   <Button key="cancel" onClick={onClose}>Отмена</Button>,
                   <Button key="save" disabled={getDisabled()} loading={saving} type="primary"
                           onClick={onSaveClick}>Сохранить</Button>
               ]}
               width={'80%'}>
            {loading && <Skeleton active={true}/>}
            {!loading && <Form form={form} onFinish={saveShipment} labelCol={{span: 12}} wrapperCol={{span: 7}}>
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item name="passport_num" label="Номер паспорта" rules={[
                            {required: true, message: 'Введите номер паспорта'},
                        ]}>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="account_num" label="Номер счета" rules={[
                            {required: false, message: 'Введите номер счета'},
                        ]}>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="passport_date" label="Дата оформления паспорта" rules={[
                            {required: true, message: 'Введите дату оформления паспорта'},
                        ]}>
                            <DatePicker locale={locale} format="DD.MM.YYYY" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="shipment_date" label="Дата отгрузки" rules={[
                            {required: true, message: 'Введите дату отгрузки'},
                        ]}>
                            <DatePicker locale={locale} format="DD.MM.YYYY" />
                        </Form.Item>
                    </Col>
                </Row>
                <Table rowKey={'id'} size={'small'} dataSource={props.orderInfo} columns={orderColumns}
                       pagination={false}/>
                <Table dataSource={getRows()} size={'small'} columns={assignedColumns} style={{marginTop: 10}}
                       rowKey={'id'}
                       scroll={{x: 1700}}
                       rowSelection={{
                           type: 'checkbox',
                           defaultSelectedRowKeys: props.data?.id ? (rows?.map((row) => row.id) || []) : [],
                           onChange: (selectedRowKeys, selectedRows) => {
                               setSelectedRows(selectedRows);
                           }
                       }}
                />
            </Form>}
        </Modal>
    );
};

export default ShipmentEdit;
