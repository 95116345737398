import React, {useContext} from 'react';
import {CloseOutlined, StarFilled, StarOutlined} from "@ant-design/icons";
import {FavoritesContext} from "../../lib/FavoritesContext";
import {NotifyInfo, NotifySuccess} from "../../lib/notify";
import {Button} from "antd";
import {useHistory} from "react-router";

const FavoriteIcon = ({name, path}) => {

    const {addFavorite, removeFavorite, inFavorites} = useContext(FavoritesContext);

    const handleToggleFavorite = () => {
        if (inFavorites({path})) {
            removeFavorite({name, path});
            NotifyInfo('Раздел ' + name + ' удален из избранного');
        } else {
            addFavorite({name, path});
            NotifySuccess('Раздел ' + name + ' добавлен в избранное');
        }
    };

    return (
        <>
            {inFavorites({path}) ? (
                <StarFilled
                    style={{color: '#f0ad4e', fontSize: '20px'}}
                    onClick={handleToggleFavorite}
                />
            ) : (
                <StarOutlined
                    style={{color: '#f0ad4e', fontSize: '20px'}}
                    onClick={handleToggleFavorite}
                />
            )}
        </>
    );
};

const PageHeader = ({title, showFavorite, showCloseBtn}) => {

    let path = window.location.pathname;
    path = '/' + path.split('/')[1];

    const history = useHistory();

    return (
        <div className="page-header" style={{position: 'relative', left: 52, top: 1}}>
            <div className="ant-page-header-heading">
                <div className="ant-page-header-heading-left" style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{display: "inline-block"}}>
                        <span className="ant-page-header-heading-title" title={title}>{title}</span>
                        {showFavorite && <FavoriteIcon name={title} path={path}/>}
                    </div>
                    <div style={{display: "inline-block", position: "relative", right: 50}}>
                        {showCloseBtn && <Button className={'close-btn'} icon={<CloseOutlined/>} onClick={() => {
                            history.push('/main');
                        }} />}
                    </div>
                </div>
            </div>
        </div>
    );
}

PageHeader.defaultProps = {
    title: 'Заголовок',
    showFavorite: true,
    showCloseBtn: true,
};

export default PageHeader;

