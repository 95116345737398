import React, {useContext, useEffect, useState} from "react";
import './SyncTable.scss';
import _ from 'underscore';
import {Button, Input, Select, Space, Tooltip} from "antd";
import {DownOutlined} from "@ant-design/icons";
import PropTypes from "prop-types";
import {getBearerAuthHeader} from "../../../lib/util";
import {HotKeysContext} from "../../../lib/HotKeyContext";

const DRAG_SENSITIVITY = 2;
let validatorTimeout = null;

export function SynthTable(props) {

    const columns = props.children.filter(item => item.type === Column);
    const groups = props.groups;

    const deviceId = props.deviceId;
    const cycleId = props.cycleId;
    const synthDate = props.synthDate;
    const postErrors = props.postErrors || [];

    const [selectedRows, setSelectedRows] = useState([]);
    const [lastSelectedRow, setLastSelectedRow] = useState(null);
    const [dragMode, setDragMode] = useState(false);
    const [preDragMode, setPreDragMode] = useState(false);
    const [dragTarget, setDragTarget] = useState(null);
    const [mouseCoords, setMouseCoords] = useState([0, 0]);
    const [orderColumnName, setOrderColumnName] = useState();
    const [orderColumnDir, setOrderColumnDir] = useState();
    const [filter, setFilter] = useState({});
    const [dragSensitivity, setDragSensitivity] = useState(0);
    const [showEmptyBanks, setShowEmptyBanks] = useState(false);

    const [errors, setErrors] = useState([]);

    const [cursor, setCursor] = useState([0, 0]);
    const [editMode, setEditMode] = useState(false);
    const [selectedCells, setSelectedCells] = useState([]);

    const {setHotKeysEnabled} = useContext(HotKeysContext);

    const [buffer, setBuffer] = useState({});
    let groupedData = [];
    let filterControls = [];

    let currentGroupFieldName = '';

    aggregateGroupValues();

    function generateGroupFilter(fieldName, value) {
        return function (item) {
            return item[fieldName] === value;
        }
    }

    function aggregateGroupValues() {
        if (groups) {
            groups.forEach(item => {
                const value = item.props.field;
                if (groupedData.includes(value)) {
                    groupedData.push(value);
                }
            })
        }
    }

    function addError(rowIndex, columnName, message) {
        const newErrors = [...errors];
        const errorsByRowColumn = newErrors.find(item => item.row === rowIndex && item.column === columnName);
        if (errorsByRowColumn) {
            const index = errorsByRowColumn.messages.indexOf(message);
            if (index === -1) {
                errorsByRowColumn.messages.push(message);
            }
        } else {
            newErrors.push({
                row: rowIndex,
                column: columnName,
                messages: [message]
            })
        }
        setErrors([...newErrors]);
    }

    function clearErrorsAtRowAndColumn(rowIndex, columnName) {
        const newErrors = [...errors];
        for (const i in errors) {
            if (errors[i].row === rowIndex && errors[i].column === columnName) {
                newErrors.splice(i, 1);
            }
        }
        setErrors([...newErrors]);
    }

    function getErrorAtRowAndColumn(rowIndex, columnName) {
        const messages = errors.find(item => item.row === rowIndex && item.column === columnName) ||
            postErrors.find(item => item.row === rowIndex && item.column === columnName);
        if (messages) {
            return messages.messages;
        }
        return null;
    }

    function hasErrorAtRowAndColumn(rowIndex, columnName) {
        return getErrorAtRowAndColumn(rowIndex, columnName) !== null;
    }

    /**
     * Рендерит INPUT для фильтра в заголовке
     *
     * @param column
     * @returns {JSX.Element}
     */
    function renderInputFilter(column, callback) {
        const value = filter[column.props.name] ? filter[column.props.name] : '';
        let control = <Input type="text" className="ant-input input-filter" value={value}
                             onChange={(e) => callback(column.props.name, e.target.value)}
                             onClick={(e) => e.stopPropagation()}/>
        filterControls.push(control);
        return control;
    }

    /**
     * Рендерит селект для фильтра в заголовке
     *
     * @param column
     * @returns {JSX.Element}
     */
    function renderSelectFilter(column, options, callback) {
        const value = filter[column.props.name] ? filter[column.props.name] : null;
        let control = <Select value={value} options={options} className={"input-filter"}
                              onClick={(e) => e.stopPropagation()}
                              onChange={(v) => callback(column.props.name, v)}/>;
        filterControls.push(control);
        return control;
    }


    /**
     * Рендер заголовка таблицы
     * @returns {JSX.Element}
     */
    function renderHeader() {

        /**
         * Обработчик ввода значения в поле фильтра
         * @param colName
         * @param value
         */
        function filterHandle(colName, value) {
            let temp = {};
            temp[colName] = value;
            setFilter({...filter, ...temp});
            selectFilteredRows({...filter, ...temp});
        }

        return <div className={"header row"}>
            {(columns.map((col, indexCol) => {

                const width = (col.props.width + 'px') || "100px"
                let orderFunc = null;
                let _orderColName = orderColumnName;
                let _orderColDir = orderColumnDir;

                // вызов внешнего сортировщика
                if (col.props.sorted && props.onSort) {
                    orderFunc = (() => {
                        if (col.props.name !== orderColumnName) {
                            setOrderColumnName(col.props.name);
                            _orderColName = col.props.name;
                        }
                        if (orderColumnDir === 'asc') {
                            setOrderColumnDir('desc');
                            _orderColDir = 'desc';
                        } else {
                            setOrderColumnDir('asc');
                            _orderColDir = 'asc';
                        }
                        setSelectedRows([]);
                        props.onSort(_orderColName, _orderColDir, col.props.reverseSorting || false);
                    });
                }

                return <div key={indexCol} style={{"maxWidth": width, "width": width}}
                            className={"cell header"}
                            onClick={orderFunc}>{col.props.title}

                    {col.props.filtered ? (col.props.filterOptions ? renderSelectFilter(col, col.props.filterOptions, filterHandle) : renderInputFilter(col, filterHandle)) : null}
                </div>
            }))}

        </div>
    }

    /**
     * Выделение строки между последней выделенной и текущей
     * @param lastSelectedRow
     * @param index
     */
    function selectRowsBetween(lastSelectedRow, index) {
        let _selRows = [];
        if (index > lastSelectedRow) {
            for (let i = lastSelectedRow; i <= index; i++) {
                _selRows.push(i);
            }
        } else {
            for (let i = lastSelectedRow; i >= index; i--) {
                _selRows.push(i);
            }
        }
        let _uniques = [];
        _selRows.forEach((i) => {
            if (!_uniques.includes(i)) {
                _uniques.push(i);
            }
        });
        setSelectedRows([..._uniques.sort()]);
    }

    /**
     * Выделение строки по индексу
     * @param index
     * @param options
     */
    function selectRowByIndex(index, options = {invertSelection: true}) {
        if (!selectedRows.includes(index)) {
            const newSelectRows = [...selectedRows, index].sort();
            setSelectedRows(newSelectRows);
        } else {
            if (options.invertSelection) {
                const newSelectRows = selectedRows.filter((item) => item !== index);
                setSelectedRows([...(newSelectRows.sort())]);
            } else {
                setSelectedRows([index]);
            }
        }
    }

    /**
     * Обработка клика по строке (выделяет либо всю строку, либо диапазон, либо ячейку)
     * @param e
     * @param index
     */
    function onRowClick(e, index) {
        e.preventDefault();
        if (e.shiftKey) {
            if (sameGroup(lastSelectedRow, index)) {
                selectRowsBetween(lastSelectedRow, index);
            } else {
                let last_row = 0
                if (lastSelectedRow > index) {
                    last_row = getLastRowIndexInGroup(props.data[index][currentGroupFieldName] || null)
                } else {
                    last_row = getFirstRowIndexInGroup(props.data[index][currentGroupFieldName] || null)
                }
                setSelectedRows([]);
                selectRowsBetween(last_row, index);
            }
        } else if (e.ctrlKey || e.metaKey) {
            selectRowByIndex(index);
        } else {
            setSelectedRows([index]);
        }
        setLastSelectedRow(index);
    }

    function getLastRowIndexInGroup(group) {
        let filtered_data = props.data.filter(item => item[currentGroupFieldName] === group)
        let index = 0;
        if (filtered_data.length) {
            index = props.data.indexOf(filtered_data[filtered_data.length - 1])
        }
        return index;
    }

    /**
     * TODO переписать обработку кросс-груп селекта с использованием этого метода!
     * @param exceptGroupId
     */
    function unselectByGroupId(exceptGroupId) {
        let _result = [];
        for (let i in selectedRows) {
            if (props.data[selectedRows[i]][currentGroupFieldName] === exceptGroupId) {
                _result.push(selectedRows[i]);
            }
        }
        setSelectedRows(_result);
    }

    /**
     * Возращает индекс первой записи в группе
     *
     * @param group
     * @returns {number}
     */
    function getFirstRowIndexInGroup(group) {
        let filtered_data = props.data.filter(item => item[currentGroupFieldName] === group)
        let index = 0;
        if (filtered_data.length) {
            index = props.data.indexOf(filtered_data[0])
        }
        return index;
    }

    /**
     * Возращает индекс посделней записи в группе
     *
     * @param index1
     * @param index2
     * @returns {boolean}
     */
    function sameGroup(index1, index2) {
        const group1 = props.data[index1][currentGroupFieldName] || null;
        const group2 = props.data[index2][currentGroupFieldName] || null;
        return group1 == group2;
    }

    /**
     * Можно ли дропнуть строку на строку под курсором
     *
     * @param indexDataRow
     * @returns {boolean}
     */
    function canDropRow(indexDataRow) {
        const dragged = selectedRows.length ? [...selectedRows] : [lastSelectedRow];
        return (props.data[indexDataRow]['scale'] === props.data[dragged[0]]['scale']);
    }

    /**
     * Увеличение счетчика чувствительности drag'n'drop
     */
    function incDragSensitivity() {
        setDragSensitivity(dragSensitivity + 1)
    }

    /**
     * При перетаскивании множества, удаляем из выделения другие группы
     *
     * @param group
     */
    function filterSelectionByGroup(group) {
        let _result = []; // clone selectedRows
        for (let i in selectedRows) {
            if (props.data[selectedRows[i]].group === group) {
                _result.push(selectedRows[i]);
            }
        }
        setSelectedRows(_result);
    }

    /**
     * Обработка движения мыши над строкой
     * (может включить режим drag'n'drop)
     *
     * @param e
     * @param rowIndex
     */
    function rowMouseMove(e, rowIndex) {
        if (editMode || !preDragMode) {
            return;
        }
        // если мышка с левой кнопкой - включаем dragMode
        setMouseCoords([e.clientX, e.clientY]);
        if (e.buttons === 1 && !dragMode) {
            if (!dragMode) {
                incDragSensitivity();
                if (dragSensitivity > DRAG_SENSITIVITY) {
                    setDragMode(true);
                    const selectedGroup = props.data[lastSelectedRow].group || null;
                    if (selectedGroup) {
                        filterSelectionByGroup(selectedGroup);
                    }
                }
            }
        } else {
            if (dragMode && canDropRow(rowIndex)) {
                setDragTarget(rowIndex);
            } else {
                console.log('can\'t drop here')
            }
        }
    }

    /**
     * Дроп
     * @param dropRowIndex
     */
    function drop(dropRowIndex) {
        if (canDropRow(dropRowIndex)) {
            if (props.onDataDrop) {
                let rowsToDrop = selectedRows;
                if (selectedRows.length === 0) {
                    rowsToDrop = [lastSelectedRow];
                }
                let uniqueRows = rowsToDrop.filter((v, i, a) => a.indexOf(v) === i);
                props.onDataDrop(uniqueRows, dropRowIndex);
            }
        }
        resetFilter();
        setPreDragMode(false);
        setDragMode(false);
        setDragTarget(null);
        setSelectedRows([]);
        setDragSensitivity(0);
        setCursor([]);
    }

    function resetFilter() {
        setFilter({}, false);
    }

    /**
     * Сброс режима d'n'd и сброс выделения
     */
    function cancelDrag() {
        setDragMode(false);
        setPreDragMode(false);
        setDragTarget(null);
        setSelectedRows([]);
        setDragSensitivity(0);
    }

    /**
     * Обработка отпускания мыши
     * @param e
     * @param indexDataRow
     */
    function rowMouseUp(e, indexDataRow) {
        setPreDragMode(false);
        if (dragMode) {
            drop(indexDataRow);
        }
    }

    function stopEditMode() {
        setEditMode(false);
        //props.onChangeData.call(null, cursor[0], {_edit: false});
    }

    /**
     * Если нажали ESC - сбросить выделение и отменить d'n'd
     * @param e
     */
    function tableKeyPressHandle(e) {
        if (e.keyCode === 27) {
            cancelDrag();
            stopEditMode();
            setSelectedCells([]);
            setFilter({});
        }
        if (e.keyCode === 13) {
            stopEditMode();
        }
        if (e.keyCode === 9) {
            if (editMode) {
                setNextEditColumn();
                e.stopPropagation();
                e.preventDefault();
            }
        }
    }

    /**
     * Перемещает курсор в следующий редактируемый столбец
     */
    function setNextEditColumn() {
        // список редактируемых столбцов
        const editableColumns = columns.map((column, index) => column.props.editable ? index : null).filter(col => col != null);
        // увеличиваем строку
        let cursorCol = cursor[1];
        let nextEditableColumn = _getNextEditableColumn(cursorCol);
        if (nextEditableColumn) {
            setCursor([cursor[0], nextEditableColumn]);
        } else {
            let cursorRow = cursor[0] + 1;
            setCursor([cursorRow, editableColumns[0]]);
        }
    }

    /**
     * Возвращает индекс следующего редактируемого столбца
     *
     * @param columnIndex
     * @returns {null|*}
     * @private
     */
    function _getNextEditableColumn(columnIndex) {
        for (let i = columnIndex + 1; i < columns.length; i++) {
            if (columns[i].props.editable) {
                return i;
            }
        }
        return null;
    }

    /**
     * Выделение строк которые попадают под критерий фильтра
     * @param _filter
     */
    function selectFilteredRows(_filter) {
        /**
         * Определяет состоит ли фильтр из пустых значений
         * @param f
         * @returns {boolean}
         */
        function isEmpty(f) {
            return !f || Object.keys(f).length == Object.keys(f).filter((key) => _filter[key] === '').length
        }

        let result = [];
        if (!isEmpty(_filter)) {
            result = props.data;
            Object.keys(_filter).forEach((key) => {
                if (_filter[key] !== '') {
                    result = result.filter((row) => String(row[key]).toUpperCase().includes(_filter[key].toUpperCase()))
                }
            });
        }
        let indexes = result.map((row) => props.data.indexOf(row));
        setSelectedRows([...indexes]);
    }

    function isSelectedCell(row, column) {
        let result = false;
        for (let i in selectedCells) {
            if (selectedCells[i][0] === row && selectedCells[i][1] === column) {
                result = true;
                break;
            }
        }
        return result;
    }

    function unselectCell(row, column) {
        let cells = [...selectedCells];
        cells = cells.filter((cell) => !_.isEqual(cell, [row, column]))
        return cells;
    }

    function onCellClick(e, rowIndex, colIndex) {
        // обработка клика только если кликнули по ячейке editable или copyable
        if (columns[colIndex].props.editable || columns[colIndex].props.copyable) {
            setCursor([rowIndex, colIndex]);
            if (e.ctrlKey) {
                // если нажат ctrl, сбрасываем выделение этой колонки во всех строках
                let cells = [];
                if (isSelectedCell(rowIndex, colIndex)) {
                    cells = unselectCell(rowIndex, colIndex);
                    setSelectedCells([...cells]);
                } else {
                    // добавляем выделение этой ячейки
                    cells = [...selectedCells];
                    cells = cells.filter((cell) => cell[1] !== colIndex);
                    setSelectedCells([...cells, [rowIndex, colIndex]]);
                }
            }
        } else {
            // если кликнули по не редактируемой и не копируемой строке - сбрасываем режим редактирования
            setEditMode(false);
            if (e.ctrlKey) {
                selectRowByIndex(rowIndex);
            } else {
                setCursor([0, 0]);
            }
        }
    }

    /**
     * Рендер строки
     *
     * @param dataRow
     * @param indexDataRow
     * @returns {JSX.Element}
     */
    function renderDataRow(dataRow, indexDataRow, columnLimit = 0, renderBottomBorder = true) {
        const selectedRow = selectedRows.includes(indexDataRow)
        let rowClasses = ['row'];
        if (selectedRow) {
            rowClasses.push('selected');
        }
        if (dragTarget === indexDataRow) {
            rowClasses.push('drag');
            if (renderBottomBorder) {
                rowClasses.push('drag-border');
            }
        }

        function renderEditor(callback, value = '', indexRow, columnName, dataRow, column) {
            async function onChange(e) {
                if (callback) {
                    const changed = {};
                    changed[columnName] = e.target.value;
                    callback.call(null, indexRow, {...changed}, dataRow);
                }
                if (column.props.validator) {
                    clearErrorsAtRowAndColumn(indexRow, columnName);
                    if (validatorTimeout) {
                        clearTimeout(validatorTimeout);
                    }
                    validatorTimeout = setTimeout(async () => {
                        let validationError = null;
                        validationError = await column.props.validator(e.target.value);
                        if (validationError) {
                            console.log('add error', indexRow, columnName, validationError);
                            addError(indexRow, columnName, validationError);
                        } else {
                            if (column.props.onValidValue) {
                                column.props.onValidValue(e.target.value, indexRow, columnName, dataRow, column);
                            }
                        }
                    }, 500);

                }
            }

            let input = <input value={value} onChange={onChange}/>
            let result = input;
            if (column.props.tooltip) {
                const tooltip = typeof column.props.tooltip === 'function' ? column.props.tooltip(indexRow, columnName, dataRow, column) : column.props.tooltip;
                result = <Tooltip color={'#1a8d90'} title={tooltip} destroyTooltipOnHide={true} open={true}>{input}</Tooltip>;
            }
            return result
        }

        function cellDoubleClick(e, rowIndex, colIndex) {
            if (columns[colIndex].props.editable) {
                setEditMode(true);
            }
        }

        function cellMouseMove(e, indexRow, indexColumn) {
            if (dataRow._lastRow) {
                return;
            }
            if (e.buttons === 1) {
                if (columns[indexColumn].props.editable) {
                    e.stopPropagation();
                }
            }
        }

        function cellMouseDown(e, row, column) {
            // если нажата кнопка мыши и не нажаты контрольные клавиши,
            // возможно начнется drag'n'drop
            if (!e.ctrlKey && !e.shiftKey && !e.altKey && !e.metaKey) {
                setPreDragMode(true);
                setLastSelectedRow(row);
            }
        }

        function renderCell(column, indexColumn, groupIndex) {
            let currentCursor = false;
            let formattedValue = '';

            if (column.props.format) {
                formattedValue = column.props.format(dataRow[column.props.name], column, indexDataRow, indexColumn, dataRow);
            } else {
                formattedValue = dataRow[column.props.name];
            }

            if (column.props.render) {
                return column.props.render(formattedValue || null, column, indexDataRow, indexColumn, dataRow, groupIndex);
            }

            const width = (column.props.width + 'px') || "100px"
            let classes = ['cell'];
            if (_.isEqual([indexDataRow, indexColumn], cursor)) {
                classes.push('cursor');
                currentCursor = true;
                if (editMode) {
                    classes.push('editor');
                }
            }
            if (isSelectedCell(indexDataRow, indexColumn)) {
                classes.push('cell-selected');
            }

            let formatterTitle = formattedValue;

            const hasErr = hasErrorAtRowAndColumn(indexDataRow, column.props.name);

            if (hasErr) {
                if (!isSelectedCell(indexDataRow, indexColumn)) {
                    classes.push('error');
                }
                formatterTitle = getErrorAtRowAndColumn(indexDataRow, column.props.name) + ': ' + formatterTitle;
            }
            let color = null;
            if (typeof column.props.bgcolor === 'function') {
                color = column.props.bgcolor(dataRow, column);
            }
            if (typeof column.props.bgcolor === 'string') {
                color = column.props.bgcolor;
            }

            if (column.props.direction === 'rtl') {
                if (formattedValue.length > 30) {
                    formattedValue = '...' + formattedValue.slice(formattedValue.length - 30, formattedValue.length);
                }
                let style = {
                    maxWidth: width, width: width, overflow: 'hidden',
                    wordWrap: 'break-word', whiteSpace: 'nowrap', textAlign: 'right', position: 'relative'
                };
                if (color && !hasErr && !isSelectedCell(indexDataRow, indexColumn)) {
                    style.backgroundColor = color;
                }

                formattedValue = <div style={style}>{formattedValue}</div>;
            }

            let renderEditorFunc = column.props.editor || renderEditor
            let style = {maxWidth: width, width: width};
            if (color && !hasErr && !isSelectedCell(indexDataRow, indexColumn)) {
                style.backgroundColor = color;
            }
            return <div key={indexColumn} className={classes.join(' ')}
                        style={style}
                        title={formatterTitle}
                        onClick={(e) => onCellClick(e, indexDataRow, indexColumn)}
                        onContextMenu={(e) => onCellClick(e, indexDataRow, indexColumn)}
                        onDoubleClick={(e) => cellDoubleClick(e, indexDataRow, indexColumn)}
                        onMouseMove={(e) => cellMouseMove(e, indexDataRow, indexColumn)}
                        onMouseDown={(e) => cellMouseDown(e, indexDataRow, indexColumn)}
            >{editMode && currentCursor && column.props.editable ? renderEditorFunc(props.onChangeData, dataRow[column.props.name] || '', indexDataRow, column.props.name, dataRow, column) : formattedValue}</div>;

        }

        if (columnLimit === 0) {
            columnLimit = columns.length;
        }
        const result = !dataRow._lastRow ? <div key={indexDataRow}
                                                className={rowClasses.join(' ')}
                                                onClick={(e) => onRowClick(e, indexDataRow)}
                                                onContextMenu={(e) => e.preventDefault()}
                                                onMouseMove={(e) => rowMouseMove(e, indexDataRow)}
                                                onMouseUp={(e) => rowMouseUp(e, indexDataRow)}
        >
            {columns.slice(0, columnLimit).map((col, indexCol) => {
                return renderCell(col, indexCol);
            })}
        </div> : <div key={indexDataRow} className={'last-row ' + rowClasses.join(' ')}
                      onContextMenu={(e) => e.preventDefault()}
                      onMouseMove={(e) => rowMouseMove(e, indexDataRow)}
                      onMouseUp={(e) => rowMouseUp(e, indexDataRow)}
        >&nbsp;</div>
        return result;
    }

    function renderGroupHeader(group, groupIndex) {
        return props.groupHeaderRenderer ? props.groupHeaderRenderer(group, groupIndex) : null;
    }

    function renderGroups() {
        let result = null;
        if (groups.length > 0) {
            result =
                <>{groups.map((group, groupIndex) => {
                    currentGroupFieldName = group.props.field;
                    const groupFilter = generateGroupFilter(group.props.field, group.props.value);
                    let rowsCount = 0;
                    const _rows = props.data.map((dataRow, indexDataRow) => {
                        {
                            const _result_rows = groupFilter(dataRow) ? renderDataRow(dataRow, indexDataRow) : null;
                            if (_result_rows) {
                                rowsCount++;
                            }
                            return _result_rows;
                        }
                    });
                    return rowsCount > 0 || showEmptyBanks ?
                        <div key={groupIndex}>
                            {renderGroupHeader(group, groupIndex)}
                            {_rows}
                        </div> : null;
                })
                }</>
        } else {
            result = <>
                {props.data.map((dataRow, indexDataRow) => renderDataRow(dataRow, indexDataRow))}
            </>
        }
        return result;
    }


    /**
     * Рендер строк при d'n'd
     *
     * @returns {JSX.Element}
     */
    function renderDraggingRow() {
        const left = mouseCoords[0] + 30;
        const top = mouseCoords[1] - 90;
        const cut = 2;

        const dragged = selectedRows.length ? [...selectedRows] : [lastSelectedRow];
        const more = dragged.length > cut ? dragged.length - cut : 0;
        if (dragged.length > cut) {
            dragged.splice(cut, dragged.length - cut)
        }
        return <div className={"drag-rows-box"} style={{left, top}}>
            {dragged.map((index) => {
                return renderDataRow(props.data[index], index, 3, false)
            })}
            {more > 0 &&<div>Еще {more}...</div>}
        </div>
    }

    function copyToClipboard() {
        let newBuffer = {};
        let key = null;
        let value = null;
        let rawData = null;

        if (selectedCells.length > 0) {
            for (let i in selectedCells) {
                key = columns[selectedCells[i][1]].props.name;
                value = props.data[selectedCells[i][0]][key];
                rawData = props.data[selectedCells[i][0]];
                newBuffer[key] = value || null;
            }
        } else {
            if (cursor) {
                key = columns[cursor[1]].props.name;
                value = props.data[cursor[1]][key];
                rawData = props.data[cursor[1]];
                newBuffer[key] = value || null;
            }
        }
        if (props.onCopy && key) {
            newBuffer = props.onCopy(newBuffer, rawData);
        }
        setBuffer(newBuffer);
    }

    function pasteFromClipboard() {
        let values = [];
        for (let i in selectedRows) {
            values[selectedRows[i]] = buffer;
        }
        if (props.onPaste) {
            props.onPaste(values);
        }
    }

    let tableClasses = ['table'];

    function onSave() {
        if (props.onSave) {
            props.onSave()
        }
    }

    const downloadFile = () => {

        const params = {
            method: 'GET',
            headers: {}
        };
        params.headers = {
            ...params.headers,
            ...getBearerAuthHeader(),
        }
        // не переделывать в api.getJSON!
        fetch(`/api/synthesis/download/${synthDate}/${deviceId}/${cycleId}`, params).then(response => {
            const header = response.headers.get('Content-Disposition');
            const filename = header.match(/filename="(.+)"/)[1];
            response.blob().then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
        });
    }

    useEffect(() => {
        setHotKeysEnabled(!editMode && !dragMode);
    }, [editMode, dragMode]);

    return <>
        <div className={"table-wrap"}>
            <div className={tableClasses.join(' ')}
                 onKeyDown={tableKeyPressHandle}
                 tabIndex={0}>
                <div key={'k1'} className={'top-toolbar'}>
                    {props.extraTabs ? props.extraTabs : null}
                    <Space>
                        {/*<Checkbox onChange={() => setShowEmptyBanks(!showEmptyBanks)}*/}
                        {/*          checked={showEmptyBanks}>*/}
                        {/*    Показывать пустые банки*/}
                        {/*</Checkbox>*/}
                        <Button className={"ant-btn"} onClick={copyToClipboard}>Копировать</Button>
                        <Button className={"ant-btn"} onClick={pasteFromClipboard}>Вставить</Button>
                    </Space>
                    <div>
                        <Button icon={<DownOutlined/>} onClick={() => downloadFile()}>Скачать</Button>
                    </div>
                    {/*<div>*/}
                    {/*    <button className={"ant-btn ant-btn-primary"} onClick={onSave}>Сохранить</button>*/}
                    {/*</div>*/}
                    {props.renderActionButtons ? props.renderActionButtons() : null}
                </div>
                {renderHeader()}
                <div key={'k2'} className={'scroll'}>
                    {renderGroups()}
                </div>
                {dragMode ? renderDraggingRow() : null}
            </div>
        </div>
    </>;
}

SynthTable.propTypes = {
    groups: PropTypes.array,
    data: PropTypes.array,
    onChangeData: PropTypes.func,
    onSort: PropTypes.func,
    onCopy: PropTypes.func,
    onPaste: PropTypes.func,
    onSave: PropTypes.func,
    groupHeaderRenderer: PropTypes.func,
    extraTabs: PropTypes.any,
    renderActionButtons: PropTypes.func,
    deviceId: PropTypes.number,
    cycleId: PropTypes.number,
    synthDate: PropTypes.string,
    postErrors: PropTypes.array,
}

export function Column() {
    return null;
}

Column.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    width: PropTypes.number,
    render: PropTypes.func,
    editable: PropTypes.bool,
    editor: PropTypes.func,
    format: PropTypes.func,
    validator: PropTypes.func,
    onValidValue: PropTypes.func,
    direction: PropTypes.string,
    filtered: PropTypes.bool,
    filterOptions: PropTypes.array,
    reverseSorting: PropTypes.bool,
    sorted: PropTypes.bool,
    tooltip: PropTypes.any,
}

export function Group() {
    return null;
}

Group.propTypes = {
    field: PropTypes.string,
    value: PropTypes.any,
}
