import React, {useState} from 'react';
import {Input, Button, Modal, Table, Space} from 'antd';
import {EllipsisOutlined} from "@ant-design/icons";

const {Search} = Input;

const RangeSelector = ({start, end, onChange, options, disabled}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [filter, setFilter] = useState('');
    const [selectionPhase, setSelectionPhase] = useState(1);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleModalOk = () => {
        handleConfirmSelection();
        setModalOpen(false);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
        resetSelection();
    };

    const handleRangeSelection = (record) => {
        if (selectionPhase === 1) {
            onChange(record.value, '');
            setSelectionPhase(2);
        } else if (selectionPhase === 2) {
            if (options.indexOf(record.value) > options.indexOf(start)) {
                onChange(start, record.value);
                setSelectionPhase(1);
            } else {
                onChange(record.value, null);
            }
        }
    };

    const handleConfirmSelection = () => {
        if (selectionPhase === 2) {
            setSelectionPhase(1);
        }
    };

    const handleReset = () => {
        onChange(null, null);

        setSelectionPhase(1);
        onChange('', '');
    };

    const handleFilterChange = (e) => {

        setFilter(e.target.value);
    };

    const filteredOptions = options.filter((option) =>
        option.toLowerCase().startsWith(filter.toLowerCase())
    );

    const columns = [
        {
            title: 'Значение',
            dataIndex: 'value',
            key: 'value',
            render: (text, record) => {
                let className = '';
                if (text === start || text === end) {
                    className = 'selected';
                }
                return <span className={className}>{text}</span>;
            },
        },
    ];

    const data = filteredOptions.map((option, index) => ({key: index, value: option}));

    const resetSelection = () => {
        onChange(null, null);
        setSelectionPhase(1);
    };

    const dispSelStart = start ? start : '-';
    const dispSelEnd = end ? end : '-';

    return (
        <div>
            <Space.Compact>
                <Search disabled={disabled} value={`${dispSelStart}:${dispSelEnd}`} readOnly enterButton={
                    <Button onClick={handleOpenModal} disabled={disabled}>
                        <EllipsisOutlined/>
                    </Button>
                }/>
            </Space.Compact>
            <Modal
                title="Выберите диапазон"
                open={modalOpen}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                okButtonProps={{disabled: selectionPhase !== 1}}
                width={600}
                destroyOnClose={true}
            >
                <div style={{marginBottom: 20}}>
                    Выбрано: <strong>{dispSelStart}</strong> : <strong>{dispSelEnd}</strong>
                </div>
                <div>
                    <Input.Search value={filter} onChange={handleFilterChange}/>
                </div>
                <Table columns={columns} size={'small'}
                       dataSource={data} onRow={(record) => ({
                        onClick: () => handleRangeSelection(record),
                    })}
                    // pagination={false}
                    scroll={{y: 400}}
                    rowClassName={(record) => {
                        if (start && end) {
                            const startIdx = options.indexOf(start);
                            const endIdx = options.indexOf(end);
                            const currentIdx = options.indexOf(record.value);
                            if (startIdx !== -1 && endIdx !== -1 && currentIdx !== -1) {
                                return currentIdx >= startIdx && currentIdx <= endIdx ? 'highlighted' : '';
                            }
                        }
                        return '';
                    }}
                />
                <div>
                    <Button onClick={handleReset}>Сбросить</Button>
                </div>
            </Modal>
        </div>
    );
};

export default RangeSelector;
