import {getOrderRevisionStatuses, getOrderPriority} from "../Order/OrderEdit";
import dayjs from "dayjs";
import {format} from "../../lib/util";

// TODO убрать куда-нибудь в shared, например
export const FINISHED = 3;
export const IN_PROCESS = 2;

export const templates = [
    {key: 1, label: 'Стандарт (Раствор)'},
    {key: 2, label: 'Стандарт (Лиофилизат)'},
    {key: 3, label: 'ЦНИИЭ (Раствор)'},
    {key: 4, label: 'ЦНИИЭ (Лиофилизат)'},
];

export const orderColumns = [
    {title: '№', dataIndex: 'order_number', width: 50},
    {
        title: 'Дата создания',
        dataIndex: 'created_at',
        width: 200,
        render: (text) => dayjs(text).format('LL')
    },
    {title: 'Заказчик', dataIndex: 'organization', width: 200},
    {
        title: 'Приоритет', dataIndex: 'priority', width: 200, render: (value) => {
            return getOrderPriority(value);
        }
    },
    {title: 'Тип заказа', dataIndex: 'order_type', width: 200},
    // {title: 'Отгружено', dataIndex: 'shipped', width: 200, render: (val, record) => {
    //         console.log(record);
    //     }},
    // {title: 'Количество отгрузок', dataIndex: 'shipment_count', width: 200},
    {
        title: 'Статус сверки',
        dataIndex: 'revision_status_id',
        key: 'revision_status_id',
        width: 80,
        render: getOrderRevisionStatuses
    },
    {title: 'Статус заказа', dataIndex: 'order_status', width: 200},
];

export const orderedColumns = [
    {title: '№', dataIndex: 'index', width: 50},
    {title: 'Последовательность', dataIndex: 'full_sequence', width: 200},
    {title: 'Наименование', dataIndex: 'name', width: 200},
    {title: 'Назначено', dataIndex: 'quantity', width: 200, render: v => format(v)},
    {title: 'Отгружено OE по заказу', dataIndex: 'quantity_shipped', width: 200, render: v => format(v, true)},
    {
        title: 'Осталось отгрузить OE', dataIndex: 'shipped_left', width: 200,
        render: (text, record) => {
            return format(record.quantity - record.quantity_shipped);
        }
    },
    // в макете имеется ввиду номер отгрзуки, но нет данных для этого
    // {title: 'Отгрузка', dataIndex: 'shipment', width: 200},
    ////////////
    {
        title: 'Тип', dataIndex: 'types', width: 200, render(value, record) {
            return value ? value.map((item) => item.name).join(', ') : '-';
        }
    },
    {title: 'Длина', dataIndex: 'length', width: 200},
];
