import {Col, Row} from "antd";
import {DeblockTable} from "./DeblockTable";
import Modal from "antd/es/modal/Modal";
import api from "../../../lib/util";
import PropTypes from "prop-types";
import {NotifyError} from "../../../lib/notify";

export function ToPackaging(props) {

    function onOk() {
        const postData = {
            portions: props.data.map(item => ({id: item.id, comment: item.comment || ''})),
        };
        api.postJSON('/api/portion/end', postData).then(response => {
            props.onHide();
        }).catch(e => {
            const keys = Object.keys(e?.errors || {});
            const messages = [];
            keys.forEach(key => {
                messages.push(e.errors[key]);
            });
            if (messages.length === 0) {
                messages.push('Неизвестная ошибка');
            }
            NotifyError("Невозможно отправить на фасовку. " + messages.join(', '));
        });
    }

    return <Modal
        title="Передать на фасовку"
        open={true}
        width={1300}
        onOk={onOk}
        onCancel={props.onHide}
        okText={'OK'}
        cancelText={'Отмена'}
        destroyOnClose={true}
    >
        <Row>
            <Col span={24}>
                <DeblockTable dataSource={props.data}/>
            </Col>
        </Row>
    </Modal>

}

ToPackaging.propTypes = {
    data: PropTypes.array.isRequired,
};
