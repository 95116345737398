import React, {useState, useEffect} from 'react';
import {Row, Col, Table, Button, Input,} from "antd"
import PageHeader from "../../components/mock/PageHeader";
import {EditOutlined, FileAddOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {buildQuery, api} from "../../lib/util";
import usePermissions from "../../lib/Permissions";

const {Search} = Input;

const OligonucleotideList = () => {

    const [state, setState] = useState({
        search: '', data: [], total: 0, loading: false, pagination: 0,
    });

    const {canEdit} = usePermissions();

    useEffect(() => {
        const {pagination} = state;
        load({pagination});
    }, []);

    const getColumns = (obj) => {
        return [
            {
                title: 'Название',
                dataIndex: 'name',
                key: 'name',
                sorter: true,
                width: '200px',
            },
            {
                title: 'Тип',
                dataIndex: 'types',
                width: 100,
                sorter: true,
                render: function (text, record, index) {
                    const listItems = record.types0.map((item, index) => <div key={index} id={index}>{item.name}</div>);
                    return listItems
                }
            },
            {
                title: 'Подтип',
                dataIndex: 'types',
                width: 100,
                sorter: true,
                render: function (text, record, index) {
                    const listItems = record.types1.map((item, index) => <div key={index} id={index}>{item.name}</div>);
                    return listItems
                }
            },
            {
                title: 'G-богатый',
                dataIndex: 'types',
                width: 100,
                sorter: true,
                render: function (text, record, index) {
                    return <>{record.types2.length > 0 ? 'Да' : 'Нет'}</>
                }
            },
            {
                title: 'Длина',
                dataIndex: 'length',
                key: 'length',
                sorter: true,
                width: '100px',
            },
            {
                title: 'Последовательность',
                dataIndex: 'sequence',
                key: 'sequence',
                sorter: true,
                width: '100%',
                ellipsis: false,
                render: function (text, record, index) {
                    return <div>{record.full_sequence}</div>
                },
            },
            {
                title: '5\'',
                dataIndex: 'modification_5',
                key: 'modification_5',
                sorter: true,
                width: '150px',
            },
            {
                title: '3\'',
                dataIndex: 'modification_3',
                key: 'modification_3',
                sorter: true,
                width: '150px',
            },
            {
                title: 'Метка',
                dataIndex: 'inner',
                key: 'inner',
                sorter: true,
                width: '150px',
            },
            {
                title: '',
                dataIndex: 'edit',
                key: 'edit',
                render: function (text, record, index) {
                    return <div>
                        <Link to={`/oligs/edit/${record.id}`}>
                            <Button size="small" shape="circle" disabled={!canEdit}><EditOutlined/></Button>
                        </Link>
                    </div>
                },
                width: 85,
            }
        ];
    }

    const handleTableChange = (pagination, filters, sorter) => {
        load({
            page: pagination.current,
            search: state.search,
            sortField: sorter.field,
            sortOrder: sorter.order, ...filters,
        });
    }

    const handleSearch = (value) => {
        load({
            search: value,
        });
    }

    const load = (params = {}) => {
        const query = buildQuery(params);
        setState(prevState => ({...prevState, loading: true}));
        api.getJSON(`/api/oligonucleotid?${query}`).then((result) => {
            setState(prevState => ({...prevState, items: result.data, total: result.total,}));
        }).catch((error) => {
            setState(prevState => ({...prevState, error}));
        }).finally(() => {
            setState(prevState => ({...prevState, loading: false}));
        });
    }

    const {items, loading, total} = state;

    return <>
        <PageHeader
            title="Справочник олигонуклеотидов"
        />
        <div style={{background: '#fff', padding: 24, minHeight: 280}}>
            <Row>
                <Col span={24}>
                    <Row style={{paddingBottom: 10}}>
                        <Col span={12}>
                            <Search placeholder="поиск" onSearch={handleSearch}/>
                        </Col>
                        <Col span={12}>
                            {canEdit && <Button style={{float: 'right'}}><Link
                                to="/oligs/create"><FileAddOutlined/> Добавить</Link></Button>}
                        </Col>
                    </Row>
                    <Table size={'small'}
                        columns={getColumns()}
                        rowKey="id"
                        dataSource={items}
                        scroll={{y: '100%'}}
                        pagination={{pageSize: 50, total: total, pageSizeOptions: []}}
                        loading={loading}
                        onChange={handleTableChange}
                    />
                </Col>
            </Row>
        </div>
    </>;
}

export default OligonucleotideList;
