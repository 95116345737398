import React from 'react';
import {DatePicker} from "antd";
import locale from 'antd/es/date-picker/locale/ru_RU';

const NoFutureDate = (props) => {
    const {value, onChange, ...rest} = props;

    const isFutureDate = (current) => {
        const today = new Date();
        return current && current > today;
    };

    const handleDateChange = (date, dateString) => {
        if (!isFutureDate(date)) {
            onChange(date, dateString);
        }
    };

    return (
        <DatePicker
            value={value}
            onChange={handleDateChange}
            disabledDate={isFutureDate}
            locale={locale}
            {...rest}
        />
    );
};

export default NoFutureDate;
