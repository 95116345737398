import {Badge, Button, Col, Progress, Row, Spin, Table, Tooltip} from "antd";
import PageHeader from "../../components/mock/PageHeader";
import {useEffect, useState} from "react";
import api from "../../lib/util";
import dayjs from "dayjs";
import OligPick from "./OligPick";
import {extractOperation, getDefaultColumns, getMassResultsColumns, renderCurrentStage} from "../Stages/Shared";
import {getOrderPriority} from "../Order/OrderEdit";
import usePermissions from "../../lib/Permissions";
import {InfoOutlined} from "@ant-design/icons";

export const YesNoBadge = ({status}) => {
    const colors = [
        {title: 'Да', color: 'green'},
        {title: 'Нет', color: 'red'},
    ];
    const item = status ? colors[0] : colors[1];
    return <Badge status={'success'} color={item.color} text={item.title}/>;
}

export const YesBadge = ({status}) => {
    const color = 'green';
    return status ? <Badge status={'success'} color={color} text={'Да'}/> : 'Нет';
}

const PackagingEdit = (props) => {

    const [packageItem, setPackageItem] = useState();
    const [showOligPick, setShowOligPick] = useState(false);
    const [showOligPickAll, setShowOligPickAll] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [currentOligsGroupId, setCurrentOligsGroupId] = useState();
    const [columnFilters, setColumnFilters] = useState({});
    const {canEdit} = usePermissions();

    const getColumns = () => {

        let extra = [
            {
                title: 'Прогресс', dataIndex: 'progress', width: 90, render: (text, record) => {
                    const done = getIsAssigned(record.items);
                    const total = record.quantity;
                    const percent = Math.round(done / total * 100);
                    return <>
                        <Progress percent={percent}
                                  steps={10} strokeColor={percent === 100 ? "green" : "#1890ff"}
                        />
                        <span style={{marginLeft: 10}}>({done}/{total})</span>
                    </>;
                },
                after: 'olig_sequence',
            },
            {
                title: 'Осталось', dataIndex: 'left', key: 'left', width: 80, render: (text, record) => {
                    return record.left;
                },
                after: 'progress',
            },
            {
                title: 'NGS', dataIndex: 'ngs', key: 'ngs', width: 80, render: (text, record) => {
                    return <YesBadge status={!!record.ngs}/>
                }, after: 'left',
            },
            {
                title: 'Форма поставки', dataIndex: 'substance_type_id', key: 'substance_type_id', width: 120,
                render: (value) => {
                    return value === 1 ? 'Раствор' : 'Лиофилизат';
                }, after: 'ngs',
            },
            {
                title: 'Расфасовка', width: 200, dataIndex: 'package', key: 'package', render: (text, record) => {
                    return <Button type={'primary'} onClick={() => {
                        setCurrentOligsGroupId(record.id);
                        setShowOligPick(true);
                    }}>Фасовка</Button>;
                },
            },
        ];

        let columns = getDefaultColumns(columnFilters, setColumnFilters, false,
            true, true, extra);

        const onlyColumns = ['olig_name', 'olig_sequence', 'progress', 'left', 'ngs', 'substance_type_id', 'length',
            'comment', 'package'
        ];

        columns = columns.filter((item) => onlyColumns.includes(item.dataIndex));

        if (!canEdit) {
            columns = columns.filter((item) => item.dataIndex !== 'package');
        }

        return columns;
    }

    function getItemColumns() {

        let extraColumns = [
            {
                title: 'Расфасовано',
                width: 100,
                dataIndex: 'is_assigned',
                key: 'is_assigned',
                render: (text, record) => {
                    return <YesNoBadge status={record.is_assigned}/>
                },
                after: 'quantity'
            },
            {
                title: 'Отгружено', width: 100, dataIndex: 'is_shipped', key: 'is_shipped', render: (text, record) => {
                    return <YesNoBadge status={record.is_shipped}/>
                }, after: 'is_assigned'
            },
        ];
        let columns = getDefaultColumns(columnFilters, setColumnFilters, false,
            true, true, extraColumns).concat(getMassResultsColumns());

        const onlyColumns = ['index', 'date', 'olig_name', 'olig_sequence', 'device_name', 'place_name', 'scale_name',
            'quantity', 'mkl', 'nanomole', 'e260', 'mw', 'mkg', '260_280', '260_230', 'is_assigned', 'is_shipped', 'mass_results',
            'prefix', 'reason', 'operations'
        ];

        columns = columns.filter((item) => onlyColumns.includes(item.dataIndex));

        const currentStageIndex = columns.findIndex((item) => item.dataIndex === 'olig_sequence');
        columns.splice(currentStageIndex + 1, 0, {
            title: 'Текущий этап', dataIndex: 'current_stage', key: 'current_stage', width: 100,
            render: (value) => {
                return renderCurrentStage(value);
            }
        });
        return columns;

    }

    const getIsAssigned = (items) => {
        return items.reduce((acc, item) => {
            return acc + (item.is_assigned ? parseFloat(item.quantity) : 0);
        }, 0);
    }

    const getCurrentOligItems = () => {
        let items = packageItem.oligonucleotides.find((item) => item.id === currentOligsGroupId).items;
        items = items.filter((item) => !item.is_shipped);
        return items;
    }

    function getAllOligItems() {
        let items = [];
        packageItem.oligonucleotides.forEach((olig) => {
            items = items.concat(olig.items);
        });
        items = items.filter((item) => !item.is_shipped);
        return items;
    }

    const remapItems = (items) => {
        let result = items.map((olig, index) => {
            const orders = olig.orders?.filter((order, index, self) => self.findIndex(t => t.id === order.id) === index) || [];
            const probs = olig.items.map((item, itemIndex) => {
                const roundValue = 1000;
                const e260 = item.current_oligonucleotide?.e260 ? (Math.round(item.current_oligonucleotide.e260 * roundValue) / roundValue) : (item.oligonucleotid?.e260 ? Math.round(item.oligonucleotid?.e260 * roundValue) / roundValue : '-');
                const mw = item.current_oligonucleotide?.mw ? Math.round(item.current_oligonucleotide.mw * roundValue) / roundValue : (item.oligonucleotid?.mw ? Math.round(item.oligonucleotid?.mw * roundValue) / roundValue : '-');
                return {
                    ...item,
                    id: item.id,
                    index: itemIndex + 1,
                    name: item.oligonucleotid.name,
                    olig_name: item.oligonucleotid.name,
                    olig_sequence: item.oligonucleotid.full_sequence,
                    work_sequence: item.current_oligonucleotide?.full_sequence || '-',
                    target_sequence: item.oligonucleotid?.full_sequence || '-',
                    modification_3: item.oligonucleotid.modification_3,
                    modification_5: item.oligonucleotid.modification_5,
                    type: olig.types.map((item) => item.name).join(', '),
                    length: olig.length,
                    device_short_name: item.all_places[0].device_short_name,
                    device_cycle_name: item.all_places[0].device_cycle_name,
                    place_name: item.all_places[0].device_place_name,
                    device_place_position: item.all_places[0].device_place_position,
                    scale_name: item.all_places[0].device_scale + ' ' + item.all_places[0].device_scale_dimension,
                    reason: item.reason,
                    ngs: item.ngs,
                    last_measurement: item.last_measurement,
                    all_measurements: item.all_measurements,
                    previous_stage: item.previous_stage,
                    inner: item.current_oligonucleotide?.inner,
                    orders,
                    e260,
                    mw,
                }
            });
            return {...olig, items: probs, id: index}
        });

        result = result.map((item) => {
            item.olig_name = item.name;
            return item;
        });
        return result;
    };

    const remapData = (package_id, data) => {
        const {oligonucleotides, ...rest} = data;
        const packaged = `${data.fully_packaged} / ${oligonucleotides.length}`;
        let oligs = remapItems(oligonucleotides);
        oligs = oligs.map((olig) => {
            return {
                ...olig,
                work_sequence: olig.full_sequence || '-',
                target_sequence: olig.full_sequence || '-',
                items: olig.items.map((item) => {
                    item = {
                        ...item,
                        device_name: item.device_short_name + ' / ' + item.device_cycle_name,
                        place_name: (item.places[0]?.device_place_name || '-') + ' / ' + (item.places[0]?.device_place_position || '-'),
                        measurements: {...item.all_measurements[0], _all: item.all_measurements},
                        date: item.places[0].calendar.date,
                    };
                    return item;
                })
            }
        });
        setPackageItem({...rest, packaged, id: parseInt(package_id), oligonucleotides: oligs});
    }

    const loadData = (package_id) => {
        setLoaded(false);
        setPackageItem({});
        api.getJSON(`/api/assigment/edit/${package_id}`).then((response) => {
            remapData(package_id, {...response});
            setLoaded(true);
        });
    }

    useEffect(() => {
        const id = props.match.params?.id;
        if (id) {
            loadData(id);
        }
    }, []);

    const title = `Расфасовка заказа №${packageItem?.order_number}`;

    if (!loaded) {
        return <div
            style={{width: '100%', marginTop: '22%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Spin spinning={true}/></div>
    } else {
        return <>
            <div>
                <PageHeader title={title} showFavorite={false}/>
                <div style={{background: '#fff', padding: 24, minHeight: 280}}>
                    <Row style={{marginBottom: 20}}>
                        <Col span={3}>№ <br/>{packageItem?.order_number}</Col>
                        <Col span={3}>Дата создания<br/>{dayjs(packageItem?.created_at).format('LL')}</Col>
                        <Col span={3}>Заказчик<br/>{packageItem?.user?.organization}</Col>
                        <Col span={3}>Приоритет <br/>
                            {packageItem?.priority ? getOrderPriority(packageItem.priority) : 'Не указан'}
                        </Col>
                        <Col span={3}>Тип заказа <br/>{packageItem?.order_type}</Col>
                        <Col span={3}>Расфасовано<br/>
                            {packageItem?.packaged}
                        </Col>
                        <Col span={3}></Col>
                        <Col span={3}></Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <h3>Пробирки сгруппированные по последовательности</h3>
                        </Col>
                        <Col span={12} style={{textAlign: 'right'}}>
                            {canEdit &&
                                <Button type={'primary'} onClick={() => setShowOligPickAll(true)}>Фасовка</Button>}
                        </Col>
                    </Row>
                    <Row style={{marginTop: 10}}>
                        <Col span={24}>
                            <Table columns={getColumns()} size={'small'}
                                   dataSource={packageItem?.oligonucleotides}
                                   rowKey={'id'} pagination={false} bordered
                                   scroll={{x: "max-content"}}
                                   expandable={{
                                       expandedRowRender: record => {
                                           return <div>
                                               <Table columns={getItemColumns()} dataSource={record.items}
                                                      size={'small'}
                                                      rowKey={'index'}
                                                      bordered
                                                      scroll={{x: "max-content",}}
                                               />
                                           </div>
                                       }
                                   }}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
            {showOligPickAll && <OligPick packageId={packageItem.id} data={getAllOligItems()} onRefresh={() => {
                loadData(packageItem.id);
            }} onOk={() => setShowOligPickAll(false)}/>}

            {showOligPick && <OligPick packageId={packageItem.id} data={getCurrentOligItems()} onRefresh={() => {
                loadData(packageItem.id);
            }} onOk={() => setShowOligPick(false)}/>}
        </>
    }
}

export default PackagingEdit;
