import React, {useContext, useState} from 'react';
import {AuthContext} from "./lib/AuthContext";
import {Button, Col, Form, Input, Row} from "antd";
import {useForm} from "antd/lib/form/Form";
import {NotifyError} from "./lib/notify";
import api from "./lib/util";

const Login = () => {

    const auth = useContext(AuthContext);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [formInstance] = useForm();
    const [loggingIn, setLoggingIn] = useState(false);

    const onSubmit = () => {
        setLoggingIn(true);
        formInstance.validateFields().then(
            (values) => {
                api.postJSON('/api/user/auth', {
                    login: values.login,
                    password: values.password
                }).then(
                    (data) => {
                        if (data.success) {
                            api.token = data.token;
                            localStorage.setItem('token', data.token);
                            auth.setToken(data.token);
                        } else {
                            NotifyError('Неправильный логин или пароль');
                        }
                    }).catch(
                    (err) => {
                        NotifyError(err);
                    }).finally(() => {
                    setLoggingIn(false);
                })
            }
        ).catch(() => {
            NotifyError('Заполните правильно все поля');
        })
    }

    return (
        <div style={{margin: '50px auto', width: '20%', background: '#edf7ff', padding: '50px'}}>
            <Row>
                <Col span={24} style={{paddingBottom: 20, display: 'flex', justifyContent: 'center'}}>
                    <div style={{display: 'inline-block', padding: 50, backgroundColor: '#FFF', borderRadius: 130}}>
                        <img src={'/images/logo.png'} alt="logo" style={{borderRadius: 50}}/>
                    </div>
                </Col>
                <Col span={24}>
                    <Form form={formInstance} layout={'vertical'}>
                        <Form.Item key={'login'}
                                   name={'login'} label={'Имя пользователя'}
                                   labelCol={{span: 12}}
                                   rules={[{required: true, message: 'Заполните это поле'}]}>
                            <Input value={login} onChange={(e) => setLogin(e.target.value)} disabled={loggingIn}/>
                        </Form.Item>
                        <Form.Item key={'password'} name={'password'} label={'Пароль'}
                                   labelCol={{span: 12}}
                                   rules={[{required: true, message: 'Заполните это поле'}]}>
                            <Input value={password} type={'password'} onChange={(e) => setPassword(e.target.value)}
                                   disabled={loggingIn}/>
                        </Form.Item>
                        <div style={{marginTop: 40, display: 'flex', justifyContent: 'center'}}>
                            <Button onClick={onSubmit} type={'primary'} loading={loggingIn}>Войти</Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}

export default Login;
