import {Col, Input, Row, Select, Table} from "antd";
import {useEffect, useState} from "react";
import {OperationsApi, ServerOperations} from "../Operatoins";
import Modal from "antd/es/modal/Modal";
import FormattedInput from "../../../components/FormattedInput";
import {getDefaultOperationColumns} from "../Shared";
import {format} from "../../../lib/util";

export function OEMeasuring(props) {

    const [method, setMethod] = useState(1);
    const [device, setDevice] = useState(null);
    const [saving, setSaving] = useState(false);

    const [portionsToSend, setPortionsToSend] = useState([]);

    function onOk() {
        setSaving(true);
        OperationsApi.doOperation(ServerOperations.OE_MEASURING, portionsToSend).then((result) => {
            setSaving(false);
            props.onHide();
        });
    }

    const deviceOptions = [
        {value: 'Tekan', label: 'Tekan'},
        {value: 'Nanodrop', label: 'Nanodrop'},
        {value: 'Nanodrop ONE С', label: 'Nanodrop ONE С'},
    ]

    const methodOptions = [
        {value: 1, label: 'Ручной ввод OE'},
        {value: 2, label: 'Ручной ввод через формулу'}
    ];

    function changeMethod(value) {
        setMethod(value)
    }

    function changeDevice(value) {
        setDevice(value)
    }

    function renderCell(name, value, record) {
        function onChange(v) {
            const index = props.data.indexOf(record);
            record[name] = v;
            if (record['c_oe_ml'] && record['v_ml'] && record['dilute_mult']) {
                record['quantity'] = parseFloat(record['c_oe_ml']) *
                    parseFloat(record['v_ml']) *
                    parseFloat(record['dilute_mult']);
            } else {
                record['quantity'] = 0;
            }
            updateData(index, record);
        }

        return <FormattedInput value={record[name]} onChange={onChange}/>
    }

    function renderOE(value, record) {
        function onChange(v) {
            const index = props.data.indexOf(record);
            record['quantity'] = v;
            updateData(index, record);
        }
        return <FormattedInput value={record['quantity']} onChange={onChange}/>
    }

    function updateData(index, record) {
        const newData = props.data;
        newData[index] = record;
        const dataToSend = newData.map(item => ({id: item.id, quantity: item.quantity, params: {device: device}}));
        setPortionsToSend(dataToSend);
    }

    function getColumns() {
        let columns = getDefaultOperationColumns();
        if (method === 1) {
            columns.push({
                title: 'OE', dataIndex: 'quantity', key: 'quantity', width: 100,
                render: (value, record) => renderOE(value, record)
            });
        } else {
            columns.push({title: 'OE', dataIndex: 'quantity', key: 'quantity', width: 50,
                render(value, record) {
                    return format(value);
                }});
            columns.push({
                title: 'c (OE/мл)', dataIndex: 'c_oe_ml', key: 'c_oe_ml', width: 100,
                render: (value, record) => renderCell('c_oe_ml', value, record)
            });
            columns.push({
                title: 'V (мл)', dataIndex: 'v_ml', key: 'v_ml', width: 100,
                render: (value, record) => renderCell('v_ml', value, record)
            });
            columns.push({
                title: 'кратность разбавления', dataIndex: 'dilute_mult', key: 'dilute_mult', width: 100,
                render: (value, record) => renderCell('dilute_mult', value, record)
            });
        }
        return columns;
    }

    function OKDisabled() {
        return !(method && device);
    }

    useEffect(() => {
        let record = props.data[0] || false;
        if (record) {
            updateData(0, record);
        }
    }, [props.data])

    return <Modal title="Измерение О.Е."
                  width={1300}
                  onOk={onOk}
                  okButtonProps={{disabled: OKDisabled(), loading: saving}}
                  onCancel={props.onHide}
                  open={true}
                  okText={'OK'}
                  cancelText={'Отмена'}
                  destroyOnClose={true}>
        <Row>
            <Col span={5} style={{padding: '10px'}}>
                Прибор <Select options={deviceOptions} onChange={changeDevice}/>
            </Col>
            <Col span={5} style={{padding: '10px'}}>
                Способ <Select value={method} options={methodOptions} onChange={changeMethod}/>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <Table rowKey={"id"} size={'small'} dataSource={props.data} columns={getColumns()}/>
            </Col>
        </Row>
    </Modal>

}
