import PropTypes from "prop-types";
import {Button, Modal, Row, Table} from "antd";
import {EllipsisOutlined, DeleteOutlined} from '@ant-design/icons'
import React, {useEffect, useState} from "react";


const List = props => {

    const [addItemMode, setAddItemMode] = useState(false);
    const [value, setValue] = useState(props.value || []);
    const [tempValue, setTempValue] = useState([]);

    useEffect(() => {
        setValue(props.value || []);
    }, [props.value]);

    let selectorClone = null;
    if (props.selector) {
        selectorClone = React.cloneElement(props.selector, {
            onSelectChange: (selectedRowKeys, selectedRows) => {
                setTempValue(selectedRows);
            }
        });
    }

    const onChange = (value) => {
        if (props.returnId) {
            value = value.map(item => item.id);
        }
        if (props.onChange) {
            props.onChange(value);
        }
    }

    const columns = [...props.columns];
    if (props.canEdit) {
        columns.push({
            title: 'Удалить',
            dataIndex: '',
            width: '60px',
            key: 'edit',
            align: 'center',
            render: (record) => {
                return <Button
                    onClick={() => {
                        const newValue = value.filter(item => item.id !== record.id);
                        setValue(newValue);
                        onChange(newValue);
                    }}
                    className={'del-btn'}
                    type={'ghost'} size={'small'} shape={'circle'}
                    icon={<DeleteOutlined/>}></Button>
            }
        });
    }

    return <div>
        {value.length > 0 ? <><Table rowKey={'id'}
                                     dataSource={value}
                                     pagination={false}
                                     columns={columns}
                                     size={'small'}
        />
            <Row justify={'end'} style={{marginTop: 10}}>
                {props.canEdit && <Button shape={'circle'} icon={<EllipsisOutlined/>} onClick={() => setAddItemMode(true)}></Button>}
            </Row></> : null}

        {value.length === 0 ?
            <>
                <Button type={'primary'} onClick={() => setAddItemMode(true)}>Выбрать материалы</Button>
                {props.slot ? props.slot : null}
            </>
            : null}

        <Modal open={addItemMode}
               onOk={() => {
                   setAddItemMode(false);
                   setValue([...tempValue]);
                   onChange([...tempValue]);
               }}
               onCancel={() => setAddItemMode(false)}
               okText={'Выбрать'}
               cancelText={'Отмена'}
               width={'1080px'}
               height={'500px'}
               destroyOnClose={true}
        >{selectorClone}</Modal>
    </div>
}

List.propTypes = {
    selector: PropTypes.element,
    columns: PropTypes.array,
    value: PropTypes.array,
    returnId: PropTypes.bool,
    slot: PropTypes.element,
    canEdit: PropTypes.bool,
}

List.defaultProps = {
    returnId: true,
    canEdit: true,
}

export default List;

