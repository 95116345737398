import {Col, Modal, Row, Select, Table} from "antd";
import {useEffect, useState} from "react";
import {getDefaultOperationColumns} from "../Shared";
import {OperationsApi, ServerOperations} from "../Operatoins";
import MaterialParties from "../../Material/MaterialParties";
import TextArea from "antd/lib/input/TextArea";

export function Seating(props) {

    const methodOptions = [
        {id: null, value: null},
        {id: '4% LiClO4/ацетон', value: '4% LiClO4/ацетон'},
        {id: 'LiClO4', value: 'LiClO4'},
        {id: 'Этанол 96%, перегнанный', value: 'Этанол 96%, перегнанный'},
        {id: 'Ацетон', value: 'Ацетон'},
        {id: '10% ацетон/H2O', value: '10% ацетон/H2O'},
    ];

    const [method, setMethod] = useState(null);
    const [portionsToSend, setPortionsToSend] = useState([]);
    const [materials, setMaterials] = useState([]);
    const [comment, setComment] = useState();

    function updateData(index, record) {
        const newData = props.data;
        newData[index] = record;
        const dataToSend = newData.map(item => ({
            id: item.id, params: {
                method, comment
            }
        }));
        setPortionsToSend(dataToSend);
    }

    useEffect(() => {
        updateData();
    }, [method, comment])

    function onOk() {
        const ids = materials.map(item => item.id);
        const portions = portionsToSend.map(item => {
            item.params.materials = ids;
            return item;
        });
        OperationsApi.doOperation(ServerOperations.SEATING, portions).then((result) => {
            props.onHide();
        });
    }

    function OKDisabled() {
        return method === null;
    }

    return <Modal title="Высаживание" width={1300} onOk={onOk}
                  okButtonProps={{disabled: OKDisabled()}}
                  onCancel={props.onHide}
                  open={true}
                  okText={'OK'}
                  cancelText={'Отмена'}
                  destroyOnClose={true}>
        <Row>
            <Col span={6} style={{padding: '10px'}}>
                Метод
                <Select value={method} options={methodOptions}
                        onChange={value => setMethod(value)}
                />
            </Col>
            <Col span={18} style={{padding: '10px'}}>
                Комментарий
                <TextArea value={comment} style={{width: '100%', height: '100px'}}
                          onChange={(e) => setComment(e.target.value)}
                />
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <MaterialParties onChange={(v) => {
                    setMaterials(v);
                }} initialValue={materials}/>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <Table rowKey={"id"} size={'small'} dataSource={props.data} columns={getDefaultOperationColumns()}/>
            </Col>
        </Row>

    </Modal>

}
