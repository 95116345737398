import StageManager from "./StageManager";
import React, {useState} from "react";
import {
    getMassResultsColumns,
    mapDataFunction,
    operationMarkup as oms,
    operations as ops, useColumns
} from "./Shared";
import {Stages} from "./Operatoins";
import {Button, Space} from "antd";
import {FileExcelOutlined, UploadOutlined} from "@ant-design/icons";
import {NotifyError, NotifySuccess} from "../../lib/notify";
import {getBearerAuthHeader} from "../../lib/util";
import usePermissions from "../../lib/Permissions";

export default function FinalControl() {

    const [queryParams, setQueryParams] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const {columns, filters} = useColumns();

    const restColumns = columns.concat(getMassResultsColumns());

    const {canEdit} = usePermissions();


    function getOperations() {
        return [
            [oms[ops.JOIN], oms[ops.OE_MEASURING], null, oms[ops.TO_MASS_SPECTR], oms[ops.ADD_PREFIX], oms[ops.DEFECT], null],
            [oms[ops.SPLIT], oms[ops.MKL_MEASURING], oms[ops.MASS_SPECTR_TABLET], null, null, oms[ops.NEXT_STAGE], oms[ops.TO_PACKAGING]],
        ];
    }

    function exportToExcel() {
        if (selectedRows.length > 0) {
            const ids = selectedRows.map((row) => row.id);
            // не переделывать в api.postJSON!
            const params = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ids}),
            };
            params.headers = {
                ...params.headers,
                ...getBearerAuthHeader(),
            }
            fetch('/api/final/export', params).then(response => {
                response.blob().then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `final.xlsx`;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                });
            });
        } else {
            NotifyError('Выберите пробирки');
        }
    }

    function importFromExcel() {
        // TODO вынести upload файла в библиотеку
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.xlsx';
        input.onchange = (e) => {
            const file = e.target.files[0];
            const formData = new FormData();
            formData.append('file', file);
            // не переделывать в api.postJSON!
            const params = {
                method: 'POST',
                body: formData,
                headers: {}
            };
            params.headers = {
                ...params.headers,
                ...getBearerAuthHeader(),
            }
            fetch('/api/final/import', params).then(response => {
                if (response.status === 200) {
                    NotifySuccess('Данные успешно импортированы');
                } else {
                    NotifyError('Ошибка импорта');
                }
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            });
        }
        input.click();
    }

    function getExtraButtons() {
        return <Space>
            {canEdit && <Button size={'small'} key="export" onClick={exportToExcel} icon={<FileExcelOutlined/>} type="primary">Экспорт в
                Excel</Button>}
            {canEdit && <Button size={'small'} key="import" onClick={importFromExcel} icon={<UploadOutlined/>} type="primary">Импорт из
                Excel</Button>}
        </Space>;
    }

    return <StageManager
        title={'Финальный контроль'}
        currentStageId={Stages.FINAL_CONTROL}
        operationButtons={getOperations()}
        columns={restColumns}
        mapFucntion={mapDataFunction}
        extraFilter={getExtraButtons}
        url={'/api/final'}
        queryParams={queryParams}
        filter={filters}
        onSelectionChange={setSelectedRows}
    />;

}
