import {useContext} from "react";
import {PermissionsContext} from "./PermissionsContext";
import {getCurrentSectionPath} from "./util";


const usePermissions = () => {

    const {canEdit, canView} = useContext(PermissionsContext);
    const url = getCurrentSectionPath();

    return {
        canView: canView(url),
        canEdit: canEdit(url),
        canViewByPath: canView,
    };

}

export default usePermissions;