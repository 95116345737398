import {Chromatography} from "./Chromatography";

const basisOptions = [
    {id: 'Очистка', value: 'Очистка'},
    {id: 'Переочистка', value: 'Переочистка'},
];

const typeOptions = [
    {id: 'RP-LC', value: 'RP-LC'},
    {id: 'SD-RP-LC', value: 'SD-RP-LC'},
    {id: 'IE-TOSOH', value: 'IE-TOSOH'},
    {id: 'IE-DNAPac', value: 'IE-DNAPac'},
    {id: 'MM-LC', value: 'MM-LC'},
    {id: 'IE-RPLC', value: 'IE-RPLC'},
];


const chromatographOptions = [
    {id: 'Agilent 1200 LC1', value: 'Agilent 1200 LC1'},
    {id: 'Agilent 1200 LC2', value: 'Agilent 1200 LC2'},
    {id: 'Agilent 1200 LC3', value: 'Agilent 1200 LC3'},
    {id: 'Akta Pure 25M', value: 'Akta Pure 25M'},
    {id: 'Thermo Ultimate 3000_1', value: 'Thermo Ultimate 3000_1'},
    {id: 'Thermo Ultimate 3000_2', value: 'Thermo Ultimate 3000_2'},
];

function getColumnOptions() {
    const columnOptions = [
        'Waters XBridge 4.6x250',
        'Waters XBridge 10x250',
        'Waters XBridge 19x150',
        'Tosoh 7.5x75mm',
        'Tosoh 21.5x150mm',
        'Tosoh(GlassColumn)_1',
        'Tosoh(GlassColumn)_2',
        'DNAPac PA100 4x250mm',
        'DNAPac PA100 9x250mm',
        'DNAPac PA100 22x250mm',
    ];
    return columnOptions.map(item => ({id: item, value: item}))
}

const methodOptions = [
    {id: 'газ', value: 'газ'},
    {id: 'жидкость', value: 'жидкость'},
];

export default function ChromatographyVZH(props) {

    return <Chromatography chromatographOptions={chromatographOptions}
                           typeOptions={typeOptions}
                           basisOptions={basisOptions}
                           columnOptions={getColumnOptions()}
                           methodOptions={methodOptions}
                           data={props.data}
                           onHide={props.onHide}
                           options={props.options}
    />;
}
