import PropTypes from "prop-types";
import {Col, Input, Row, Table} from "antd";
import React, {useEffect} from "react";

export default function Tubes(props) {

    const columns = [
        // {title: 'ID', dataIndex: 'id', key: 'id', width: 80,},
        {
            title: '№', dataIndex: 'index', width: 50, fixed: 'left', render: (value, record, index) => {
                return <span>{index + 1}</span>
            }
        },
        {
            title: 'Фракции', width: 171, dataIndex: 'fractions', key: 'fractions', render: (fractions) => {
                return <div>{fractions.map((fraction, index) => {
                    return <span key={index} style={{
                        display: 'inline',
                        background: '#8bc34a',
                        margin: 2,
                        padding: 3,
                        marginTop: 3,
                        color: '#FFF',
                        fontSize: 10,
                        whiteSpace: 'nowrap',
                        fontWeight: 'bold',
                    }}>{fraction.code}</span>;
                })}</div>
            }
        },
        {
            title: 'Префикс', dataIndex: 'prefix', key: 'prefix', render: (value, record) => {
                return <Input value={value} onChange={(e) => {
                    props.onChange(record.id, 'prefix', e.target.value);
                }}/>
            }
        },
        {
            title: 'Комментарий', dataIndex: 'comment', key: 'comment', render: (value, record) => {
                return <Input value={value} onChange={(e) => {
                    props.onChange(record.id, 'comment', e.target.value);
                }}/>
            }
        }
    ];

    useEffect(() => {
        props.onSelectTubes([]);
    }, [props.tubes]);

    return <Row>
        <Col span={24}>
            <Table columns={columns} dataSource={props.tubes}
                   size={'small'}
                   rowKey={'id'}
                   pagination={false}
                   scroll={{y: 300}}
                   rowSelection={{
                       type: 'checkbox',
                       selectedRowKeys: props.selectedTubes.map(tube => tube.id),
                       onChange: (selectedRowKeys, selectedRows) => {
                           props.onSelectTubes(selectedRows);
                       },
                   }}
            />
        </Col>
    </Row>

}

Tubes.propTypes = {
    tubes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        fractions: PropTypes.arrayOf(PropTypes.string).isRequired,
        prefix: PropTypes.string,
        collector: PropTypes.string,
        comment: PropTypes.string,
    })),
    selectedTubes: PropTypes.array.isRequired,
    onSelectTubes: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
}

Tubes.defaultProps = {
    tubes: [],
    selectedTubes: [],
}
