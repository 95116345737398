import {Modal, Table} from "antd";
import {useEffect, useState} from "react";
import api from "../../lib/util";
import PropTypes from "prop-types";
import dayjs from "dayjs";

const ChooseParty = (props) => {

    const [data, setData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const loadMaterialParties = () => {
        api.getJSON(`/api/material/variants`).then(data => {
            setData(data.data.filter(d => d.material_id === props.materialId));
        });
    }

    useEffect(() => {
        loadMaterialParties();
    }, []);

    const columns = [
        {title: 'Производитель', dataIndex: 'manufacturer', key: 'manufacturer'},
        {title: 'Каталожный номер', dataIndex: 'catalogue_number', key: 'catalogue_number'},
        {
            title: 'Дата производства',
            dataIndex: 'production_date',
            key: 'production_date',
            render: (text, record) => <span>{dayjs(text).format('DD.MM.YYYY')}</span>
        },
    ]

    const onSelectChange = (keys) => {
        setSelectedRows(keys);
    }

    return (
        <Modal open={true} destroyOnClose={true}
               onOk={() => {
                   const row = data.find(item => item.id === selectedRows[0]);
                   props.onOk(row);
               }}
               onCancel={() => {
                   props.onCancel();
               }}
        >
            <div>Выбор партии</div>
            <Table columns={columns} dataSource={data} rowKey={'id'}
                   size={'small'}
                   rowSelection={{
                       type: 'radio',
                       selectedRowKeys: selectedRows,
                       onChange: onSelectChange,
                   }}
            />
        </Modal>
    );
}

export default ChooseParty;

ChooseParty.propTypes = {
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    materialId: PropTypes.number.isRequired,
}
