import React, {useEffect} from "react";
import PageHeader from "../../components/mock/PageHeader";
import api from "../../lib/util";
import {Button, Col, Collapse, Form, Popconfirm, Row, Select, Table} from "antd";
import dayjs from "dayjs";
import {ParamNames} from "./Shared";
import {NotifyError} from "../../lib/notify";

const renderMaterials = (materials) => {
    if (Array.isArray(materials)) {
        return <table>
            <thead>
            <tr>
                <th>Название</th>
                <th>Кат. №</th>
                <th>Партия</th>
                <th>Производитель</th>
            </tr>
            </thead>
            <tbody>
            {materials.map((item) => {
                return <tr key={item.id}>
                    <td>{item.material_name}</td>
                    <td>{item.catalogue_number}</td>
                    <td>{item.batch_number}
                    </td>
                    <td>{item.manufacturer}</td>
                </tr>;
            })}
            </tbody>
        </table>
    } else {
        return '';
    }
}

const PortionHistory = (props) => {

    const [loading, setLoading] = React.useState(false);

    const [portion, setPortion] = React.useState([]);
    const [filterStage, setFilterStage] = React.useState([]);
    const [filterOperation, setFilterOperation] = React.useState([]);

    const [parentPortions, setParentPortions] = React.useState([]);
    const [childPortions, setChildPortions] = React.useState([]);
    const [allExpanded, setAllExpanded] = React.useState(false);


    const toggleAllCollapses = () => {
        setAllExpanded(!allExpanded);
    };

    const deleteOperations = portionToOperationIds => {
        return new Promise((resolve, reject) => {
            api.postJSON(`/api/portion/delete-operation`, {portion_to_operation_ids: portionToOperationIds}).then(() => {
                resolve();
            }).catch((e) => {
                let errorMessage = "Не удалось удалить операцию: ";
                if (e.errors) {
                    errorMessage += Object.values(e.errors).join("\n");
                } else {
                    errorMessage += e;
                }
                NotifyError(errorMessage);
            });
        });
    }

    const renderParams = (params, portionToOperationId) => {
        const keys = Object.keys(params);
        if (keys.includes('paint_name')) {
            if (keys.includes('paint')) {
                delete params['paint'];
                keys.splice(keys.indexOf('paint'), 1);
            }
            if (keys.includes('materials')) {
                delete params['materials'];
                keys.splice(keys.indexOf('materials'), 1);
            }
        }

        const prepared = {};
        keys.map((key) => {
            let value = params[key] || '-';
            if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(value)) {
                value = dayjs(value).format('LLL');
            }
            prepared[key] = value;
        });
        if (keys.includes('materials')) {
            keys.splice(keys.indexOf('materials'), 1);
        }
        return <Row>
            <Col span={24}>
                {keys.map((key) => {
                    let value = prepared[key] || '-';
                    const name = ParamNames[key] || key;
                    if (typeof value === 'object') {
                        value = 'error';
                    }
                    return <Row key={key}>
                        <Col span={12} title={name}>{name}</Col>
                        <Col span={12} title={value} style={{textOverflow: 'ellipsis'}}>{value}</Col>
                    </Row>;
                })}
            </Col>
            <Col span={24}>
                <Popconfirm title={"Вы уверены что хотите удалить операцию?"} onConfirm={() => {
                    deleteOperations([portionToOperationId]).then(() => {
                        load();
                    });
                }}>
                    <Button size={'small'}>Удалить операцию</Button>
                </Popconfirm>
            </Col>
        </Row>;

    }

    const getStageOptions = () => {
        let stages = new Set();
        portion.forEach((item) => {
            if (item?.stage?.name) {
                stages.add(item.stage.name);
            }
        });

        parentPortions.forEach((portion) => {
            portion.forEach((item) => {
                if (item.stage?.name) {
                    stages.add(item.stage.name);
                }
            });
        });

        childPortions.forEach((portion) => {
            portion.forEach((item) => {
                if (item.stage?.name) {
                    stages.add(item.stage.name);
                }
            });
        });

        return stages.size > 0 ? Array.from(stages).map((item) => {
            return {label: item, value: item};
        }) : [];
    }

    const getOperationOptions = () => {
        let operations = new Set();
        portion.forEach((item) => {
            if (item?.operation?.name) {
                operations.add(item.operation.name);
            }
        });

        parentPortions.forEach((portion) => {
            portion.forEach((item) => {
                if (item.operation?.name) {
                    operations.add(item.operation.name);
                }
            });
        });

        childPortions.forEach((portion) => {
            portion.forEach((item) => {
                if (item.operation?.name) {
                    operations.add(item.operation.name);
                }
            });

        });
        return operations.size > 0 ? Array.from(operations).map((item) => {
            return {label: item, value: item};
        }) : [];
    }

    const mapPortion = (data) => {
        let result = [];
        let id = 1;
        for (let i = 0; i < data.stages.length; i++) {
            if (data.stages[i].operations?.length === 0) {
                result.push({
                    id: id++,
                    portion_id: data.id,
                    prefix: data.prefix,
                    olig_name: data.olig_name,
                    work_sequence: data.current_oligonucleotide?.full_sequence || '-',
                    target_sequence: data.oligonucleotid?.full_sequence || '-',
                    stage: data.stages[i],
                    operation: {},
                });
            } else {
                if (!data.stages[i].operations) {
                    continue;
                }
                for (let j = 0; j < data.stages[i].operations.length; j++) {
                    let operation = data.stages[i].operations[j];
                    if (operation.params) {
                        try {
                            operation.params = JSON.parse(operation.params);
                        } catch (e) {
                        }
                    }
                    result.push({
                        id: id++,
                        portion_id: data.id,
                        prefix: data.prefix,
                        olig_name: data.olig_name,
                        work_sequence: data.current_oligonucleotide?.full_sequence || '-',
                        target_sequence: data.oligonucleotid?.full_sequence || '-',
                        stage: data.stages[i],
                        operation,
                    });
                }
            }
        }

        result.sort((a, b) => {
            if (a.stage.date > b.stage.date) {
                return -1;
            } else if (a.stage.date < b.stage.date) {
                return 1;
            } else {
                if (a.operation.created_at > b.operation.created_at) {
                    return -1;
                } else if (a.operation.created_at < b.operation.created_at) {
                    return 1;
                } else {
                    return 0;
                }
            }
        });
        return result;
    }

    const load = () => {
        setLoading(true);
        api.getJSON(`/api/portion/history/${props.match.params.id}`).then((data) => {
            setPortion(mapPortion(data));
            if (data.parents) {
                let parents = [];
                data.parents.forEach((item) => {
                    parents.push(mapPortion(item));
                });
                setParentPortions(parents);
            }
            if (data.children) {
                let children = [];
                data.children.forEach((item) => {
                    children.push(mapPortion(item));
                });
                setChildPortions(children);
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    const getColumns = () => [
        {
            dataIndex: 'portion_id', title: 'ID', width: 40, render: (value) => {
                return <a href={`/portion/${value}`}>{value}</a>
            }
        },
        {
            dataIndex: 'stage', title: 'Этап', width: 400, children: [
                {
                    dataIndex: 'stage',
                    title: 'Дата',
                    width: 100,
                    render: (value) => {
                        const date = (value?.date ? dayjs(value.date).format('LLL') : '') || '';
                        return date;
                    }
                },
                {
                    dataIndex: 'stage', title: 'Название', width: 300, render: (value, record) => {
                        return value?.displayName || '';
                    }
                },
            ]
        },
        {
            dataIndex: 'operation', title: 'Операция', width: 1470, children: [
                {
                    dataIndex: 'operation', title: 'Создание', width: 150,
                    render: (value) => {
                        return dayjs(value?.created_at).format('LLL') || '';
                    }
                },
                {
                    dataIndex: 'operation', title: 'Послед. изм.', width: 150,
                    render: (value) => {
                        return dayjs(value?.updated_at).format('LLL') || '';
                    }
                },
                {
                    dataIndex: 'operation',
                    title: 'Название/Параметры',
                    width: 470,
                    render: (value, record) => {
                        const portionToOperationId = record.portion_to_operation_id;
                        let result = null;
                        const params = value?.params || {};
                        if (typeof params === 'string') {
                            try {
                                result = renderParams(JSON.parse(params), portionToOperationId);
                            } catch (e) {
                                result = '';
                            }
                        } else if (typeof params === 'object') {
                            result = renderParams(params, portionToOperationId);
                        } else {
                            result = '';
                        }
                        if (Object.keys(params).length > 0) {
                            return (
                                <Collapse
                                    collapsible={'header'}
                                    size={'small'}
                                    bordered={false}
                                    activeKey={allExpanded ? ['1'] : undefined}
                                >
                                    <Collapse.Panel header={value.name} key="1">
                                        {result}
                                    </Collapse.Panel>
                                </Collapse>
                            );
                        } else {
                            return <span style={{fontSize: 14}}>{value.name || ''}</span>;
                        }
                    }
                },
                {
                    dataIndex: 'operation',
                    title: 'Материалы',
                    width: 400,
                    render: (value) => {
                        let params = value?.params || {};
                        if (typeof params === 'string') {
                            try {
                                params = JSON.parse(params);
                            } catch (e) {
                                params = {};
                            }
                        }
                        let materials = params?.materials || [];
                        if (materials.length > 0) {
                            return (
                                <Collapse
                                    collapsible={'header'}
                                    size={'small'}
                                    bordered={false}
                                    activeKey={allExpanded ? ['1'] : undefined}
                                >
                                    <Collapse.Panel header="Материалы" key="1">
                                        {renderMaterials(materials)}
                                    </Collapse.Panel>
                                </Collapse>
                            );
                        }
                    }
                },
                {
                    dataIndex: 'operation',
                    title: 'Пользователь',
                    width: 300,
                    render: (value) => {
                        const updated_at = value?.pivot?.updated_at || '';
                        let result = value?.author?.name || '';
                        if (updated_at !== '') {
                            result += ` (${dayjs(updated_at).format('LLL')})`;
                        }
                        return result;
                    }
                },
            ]
        },
        {}
    ];

    useEffect(() => {
        const id = props.match.params.id;
        if (id) {
            load();
        }
    }, []);

    /**
     * Фильтрация пробирок по этапу и операции
     * @param portions
     * @returns {*}
     */
    const getFilteredData = (portions) => {
        let result = portions;
        if (filterStage) {
            result = result.filter((item) => {
                return filterStage.includes(item.stage.name) || filterStage.length === 0;
            });
        }
        if (filterOperation) {
            result = result.filter((item) => {
                return filterOperation.includes(item.operation.name) || filterOperation.length === 0;
            });
        }

        let currentStage = '';
        for (let i = 0; i < result.length; i++) {
            if (currentStage !== result[i].stage.name) {
                currentStage = result[i].stage.name;
                result[i].stage = {displayName: currentStage, name: currentStage, date: result[i].stage.date};
            } else {
                result[i].stage = {name: currentStage};
            }
        }

        return result;
    }

    const portionData = getFilteredData(portion);

    function renderChildPortions() {
        let resultPortions = [];
        childPortions.map((portion, index) => {
            resultPortions.push(getFilteredData(portion));
        });
        const hasNonEmptyArray = resultPortions.some((portion) => portion.length > 0);
        if (hasNonEmptyArray) {
            return <Row style={{margin: 24}}>
                <h3>Дочерние пробирки</h3>
                <Col span={24}>
                    {resultPortions.map((portion, index) => {
                        if (portion.length > 0) {
                            return <>
                                {renderPortionHeader(portion[0])}
                                <Table key={index} rowKey={"id"} columns={getColumns()}
                                       dataSource={portion}
                                       loading={loading} pagination={false}
                                       style={{marginBottom: 20}}
                                       className={'bold-header'}
                                /></>;
                        } else {
                            return null;
                        }
                    })}
                </Col>
            </Row>;
        } else {
            return null;
        }
    }

    function renderParentPortions() {
        let resultPortions = [];
        parentPortions.map((portion, index) => {
            resultPortions.push(getFilteredData(portion));
        });

        const hasNonEmptyArray = resultPortions.some((portion) => portion.length > 0);

        if (hasNonEmptyArray) {
            return <Row style={{margin: 24}}>
                <h3>Родительские пробирки</h3>
                <Col span={24}>
                    {resultPortions.map((portion, index) => {
                        if (portion.length > 0) {
                            return <>
                                {renderPortionHeader(portion[0])}
                                <Table key={index} rowKey={"id"} columns={getColumns()}
                                       dataSource={portion}
                                       loading={loading} pagination={false}
                                       style={{marginBottom: 20}}
                                       className={'bold-header'}
                                /></>;
                        } else {
                            return null;
                        }
                    })}
                </Col>
            </Row>;
        } else {
            return null;
        }
    }

    const renderPortionHeader = portion => {
        return <table style={{width: '100%'}}>
            <thead>
            <tr>
                <th align={'left'}>ID</th>
                <th align={'left'}>Название</th>
                <th align={'left'}>Префикс</th>
                <th align={'left'}>Рабочая последовательность</th>
                <th align={'left'}>Целевая последовательность</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td align={'left'}>{portion?.portion_id}</td>
                <td align={'left'}>{portion?.olig_name || '-'}</td>
                <td align={'left'}>{portion?.prefix || '-'}</td>
                <td align={'left'}>{portion?.work_sequence}</td>
                <td align={'left'}>{portion?.target_sequence}</td>
            </tr>
            </tbody>
        </table>;
    }

    return <>
        <PageHeader title={"История пробирки"} showFavorite={false}/>
        <Row style={{margin: 24}}>
            <Col span={20}>
                <Form layout={'inline'}>
                    <Form.Item label={"Этап"}>
                        <Select value={filterStage} options={getStageOptions()} style={{width: 400}}
                                onChange={(value) => {
                                    setFilterStage(value);
                                }}
                                allowClear={true}
                                mode={'multiple'}
                        />
                    </Form.Item>
                    <Form.Item label={"Операция"}>
                        <Select value={filterOperation} options={getOperationOptions()} style={{width: 800}}
                                onChange={(value) => {
                                    setFilterOperation(value);
                                }}
                                allowClear={true}
                                mode={'multiple'}
                        />
                    </Form.Item>
                </Form>
            </Col>
            <Col span={4} style={{display: 'flex', justifyContent: 'end'}}>
                <Button onClick={toggleAllCollapses}>
                    {allExpanded ? 'Свернуть все' : 'Развернуть все'}
                </Button>
            </Col>
        </Row>
        <Row style={{margin: 24}}>
            <Col span={24}>
                {renderPortionHeader(portion[0])}
            </Col>
        </Row>
        <Row style={{margin: 24}}>
            <Col span={24}>
                <Table rowKey={"id"} columns={getColumns()} dataSource={portionData}
                       loading={loading} pagination={false}
                       className={'bold-header'}
                />
            </Col>
        </Row>
        {renderParentPortions()}
        {renderChildPortions()}
    </>;

}
export default PortionHistory;
