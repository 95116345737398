import {
    getMassResultsColumns, mapDataFunction,
    operationMarkup as oms,
    operations as ops, useColumns
} from "../Stages/Shared";
import {Col, Row} from "antd";
import StageManager from "../Stages/StageManager";

export function Unpackaged() {

    const {columns, filters} = useColumns();
    const restColumns = columns.concat(getMassResultsColumns());

    function getOperations() {
        return [
            [
                oms[ops.JOIN], oms[ops.DEFECT],
            ],
            [
                oms[ops.SPLIT], oms[ops.NEXT_STAGE],
            ]
        ];
    }

    return <Row>
        <Col span={24}>
            <StageManager
                operationButtons={getOperations()}
                columns={restColumns}
                mapFucntion={mapDataFunction}
                url={"/api/stock"}
                queryParams={['status=1']}
                filter={filters}
                extraFilter={null}
                options={{
                    showStockBase: true,
                }}
                stage={'unpackaged'}
            />
        </Col>
    </Row>;
}
