import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {Checkbox, ConfigProvider, DatePicker, Form, Input, Modal} from "antd";
import MaterialParties from "../../Material/MaterialParties";
import locale from 'antd/lib/locale/ru_RU';

const BankMaterialsEdit = (props) => {

    const [material, setMaterial] = useState(null);
    const [form] = Form.useForm();
    const [values, setValues] = useState(null);

    const onOk = () => {
        form.validateFields().then(values => {
            if (props.onOk) {
                if (material && material.length > 0) {
                    values = {...values, material_variant_id: material[0].id, material_name: material[0].name};
                    values.material = material[0];
                }
                values.is_new = !values.is_new;
                props.onOk(values);
            }
        });
    }

    const onMaterialChange = (value) => {
        setMaterial(value);
    }

    const onCancel = () => {
        if (props.onCancel) {
            props.onCancel();
        }
    }

    useEffect(() => {
        if (props.value && props.value.material) {
            setMaterial([props.value.material]);
        }

        const vals = {...props.value};
        vals.sequence = vals.sequence ? vals.sequence : vals.standard;
        vals.is_new = !vals.is_new;
        form.setFieldsValue(vals);
    }, []);

    return <Modal title={'Банка'} open={true} width={'1080px'}
                  onOk={onOk} onCancel={onCancel}
                  okText={'Выбрать'} cancelText={'Отмена'}>
        <Form form={form} onChange={(values) => setValues(values)}>
            <Form.Item hidden={true} name={'id'}>
                <Input/>
            </Form.Item>
            <Form.Item hidden={true} name={'standard'}>
                <Input/>
            </Form.Item>
            <Form.Item label={'Код банки'} name={'jar_code'}>
                <Input style={{width: 200}} disabled/>
            </Form.Item>
            <Form.Item label={'Реагент'} name={'sequence'} rules={[{required: true, message: 'Заполните это поле'}]}>
                <Input/>
            </Form.Item>
            <Form.Item>
                <MaterialParties title={'Выбор материала'} onChange={onMaterialChange} type={'radio'}
                                 initialValue={material}/>
            </Form.Item>
            <ConfigProvider locale={locale}>
                <Form.Item label={'Дата установки на прибор'} name={'setup_at'}
                           rules={[{required: true, message: 'Заполните это поле'}]}>
                    <DatePicker format={'DD.MM.YYYY'} placeholder={'ДД.ММ.ГГГГ'}/>
                </Form.Item>
            </ConfigProvider>
            <Form.Item name={'is_new'} valuePropName={'checked'}>
                <Checkbox>Долив</Checkbox>
            </Form.Item>
            <Form.Item label={'Количество долива'} name={'topping_count'}>
                <Input style={{width: 100}}/>
            </Form.Item>
            <Form.Item label={'Примечание'} name={'notes'}>
                <Input/>
            </Form.Item>
        </Form>
    </Modal>

}

BankMaterialsEdit.propTypes = {
    value: PropTypes.object,
}

export default BankMaterialsEdit;
