import {Button, Col, message, Row, Space, Table} from "antd";
import {useEffect, useState} from "react";
import {EditOutlined} from '@ant-design/icons'
import api from "../../../lib/util";
import BankMaterialsEdit from "./BankMaterialsEdit";
import {NotifySuccess} from "../../../lib/notify";
import MaterialParties from "../../Material/MaterialParties";
import PrevSynthMaterials from "./PrevSynthMaterials";
import dayjs from "dayjs";

export default function BankMaterials({deviceId, cycleId, synthDate}) {

    const [materials, setMaterials] = useState([]);
    const [data, setData] = useState();
    const [editItemMode, setEditItemMode] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [saving, setSaving] = useState(false);
    const [prevMaterialsMode, setPrevMaterialsMode] = useState(false);

    const columns = [
        {title: '№', dataIndex: 'number', key: 'number'},
        {title: 'Код банки', dataIndex: 'jar_code', key: 'jar_code'},
        {
            title: 'Реагент', dataIndex: 'sequence', key: 'sequence', render: (text, record) => {
                return <span>{record.sequence || record.standard}</span>
            }
        },
        {
            title: 'Материал', dataIndex: 'material', key: 'material_name', render: (text, record) => {
                let result = null;
                if (record.material) {
                    result = <div>
                        {record.material.manufacturer || '-'},
                        {record.material.batch_number || '-'},
                        {record.material.catalogue_number || '-'},
                        {dayjs(record.material.production_date).format('DD.MM.YYYY') || '-'}
                    </div>;
                }
                return result;
            }
        },
        {
            title: 'Дата установки на прибор', dataIndex: 'setup_at', key: 'setup_at',
            render: (value) => value ? dayjs(value).format('LL') : '-'
        },
        {
            title: 'Долив или свежая банка', dataIndex: 'is_new', key: 'is_new', render(value) {
                return value ? 'Свежая банка' : 'Долив';
            }
        },
        {title: 'Количество долива', dataIndex: 'topping_count', key: 'topping_count', render: (value) => value || 0},
        {title: 'Примечание', dataIndex: 'notes', key: 'notes'},
        {
            title: 'Действия', dataIndex: 'actions', key: 'actions', render: (text, record) => (
                <Space size="small">
                    <Button shape={'circle'} size="small" icon={<EditOutlined/>}
                            className={'edit-btn'}
                            onClick={() => {
                                record = {...record, setup_at: record.setup_at ? dayjs(record.setup_at) : dayjs()};
                                setEditItem(record);
                                setEditItemMode(true);
                            }}/>
                </Space>
            )
        }
    ];

    function loadBanks() {
        api.getJSON(`/api/synthesis-device/calendar/jars/${synthDate}/${deviceId}/${cycleId}`).then(resp => {
            const date = dayjs(synthDate).format('YYYY-MM-DD');
            if (resp && resp[date]) {
                setData(resp[date]);
                if (resp.materials) {
                    setMaterials(resp.materials);
                }
            }
        });
    }

    const saveBanks = () => {
        const date = dayjs(synthDate).format('YYYY-MM-DD');
        const dataToSave = {};
        dataToSave[date] = data;
        dataToSave['id'] = parseInt(deviceId);
        if (materials) {
            dataToSave['materials'] = materials.map(m => m.id);
        }
        setSaving(true);
        api.postJSON(`/api/synthesis-device/calendar/jars/${synthDate}/${deviceId}/${cycleId}`, dataToSave).then(() => {
            NotifySuccess('Сохранено');
            setSaving(false);
            loadBanks();

        });
    }

    useEffect(() => {
        loadBanks()
    }, []);

    const onEditItem = (values) => {
        let newData = [...data];
        const index = newData.findIndex((item) => item.id === values.id);
        const keys = [...new Set([...Object.keys(newData[index]), ...Object.keys(values)])];
        if (index > -1) {
            newData[index] = keys.reduce((obj, key) => {
                obj[key] = values[key] != undefined ? values[key] : newData[index][key];
                return obj;
            }, {});
        }
        if (newData[index].standard === newData[index].sequence || newData[index].sequence === '') {
            newData[index].sequence = null;
        }
        setData(newData);
        setEditItemMode(false);
    }

    return <><Row style={{margin: '10px 20px'}}>
        <Col span={24}>
            <Row style={{marginBottom: 20, marginTop: 10}}>
                <Col span={12}>
                    <h3>Банки</h3>
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                    <Button loading={saving} type={'primary'} onClick={() => {
                        saveBanks()
                    }}>Сохранить</Button>
                </Col>
            </Row>
            <Table columns={columns} size={'small'} rowKey={'id'} dataSource={data} pagination={false}/>
            <Row>
                <Col span={24} style={{marginTop: 10}}>
                    <MaterialParties initialValue={materials} onChange={(v) => setMaterials(v)}/>
                </Col>
            </Row>
            <Row>
                <Col span={24} style={{marginTop: 10}}>
                    <Button type={'primary'} onClick={() => setPrevMaterialsMode(true)}>Материалы из предыдущего
                        синтеза</Button>
                </Col>
            </Row>
        </Col>
    </Row>
        {editItemMode &&
            <BankMaterialsEdit value={editItem} onOk={onEditItem} onCancel={() => setEditItemMode(false)}/>}
        {prevMaterialsMode && <PrevSynthMaterials date={synthDate} deviceId={deviceId}
                                                  onOk={(selectedMaterials) => {
                                                      setPrevMaterialsMode(false);
                                                      const newMaterials = [...materials, ...selectedMaterials];
                                                      const uniqueMaterials = newMaterials.filter((item, index) => {
                                                          return newMaterials.findIndex(i => i.id === item.id) === index;
                                                      });
                                                      setMaterials(uniqueMaterials);
                                                  }}
                                                  onCancel={() => setPrevMaterialsMode(false)}
                                                  title={'Выбор материалов из предыдущего синтеза'}
        />}
    </>;
}
