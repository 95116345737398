import React, {useEffect, useState} from 'react';
import {Input} from 'antd';
import PropTypes from "prop-types";
import {format, unformat} from "../lib/util";

const FormattedInput = (props) => {
    const { onChange, onBlur, disabled, value, min, max, ...rest } = props;

    const [internalValue, setInternalValue] = useState(unformat(value));
    const [formattedValue, setFormattedValue] = useState();
    const [focused, setFocused] = useState(false);

    const handleChange = (event) => {
        let v = event.target.value;
        v = v.replace(',', '.');
        v = v.replace(/[^0-9.]/g, '');
        setInternalValue(v);
        setFormattedValue(v);
    };

    const handleBlur = () => {
        let newValue = parseFloat(internalValue);
        if (!isNaN(min) && newValue < min) {
            newValue = min;
        } else if (!isNaN(max) && newValue > max) {
            newValue = max;
        }
        setInternalValue(newValue);
        setFormattedValue(format(newValue));
        callOnChange(newValue);
        callOnBlur(newValue);
    };

    useEffect(() => {
        if (props.value !== undefined) {
            setInternalValue(unformat(props.value));
            setFormattedValue(props.value);
        } else {
            setFormattedValue('');
        }
    }, [props.value]);

    useEffect(() => {
        if (focused) {
            setFormattedValue(internalValue);
        } else {
            setFormattedValue(format(internalValue));
        }
    }, [focused]);

    const callOnChange = (v) => {
        if (onChange) {
            onChange(v);
        }
    };

    const callOnBlur = (v) => {
        if (onBlur) {
            onBlur(v);
        }
    };

    return (
        <Input
            onFocus={() => setFocused(true)}
            value={formattedValue}
            onBlur={handleBlur}
            onChange={handleChange}
            className={'input-right'}
            disabled={disabled}
            {...rest}
        />
    );

}

FormattedInput.propTypes = {
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
}

export default FormattedInput;
