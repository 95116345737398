import {Col, ConfigProvider, Form, Input, Modal, Row, Table} from "antd";
import {useEffect, useRef, useState} from "react";
import NoFutureDate from "../../../../components/NoFutureDate";
import dayjs from "dayjs";
import locale from 'antd/lib/locale/ru_RU';
import PropTypes from "prop-types";
import {OperationsApi, ServerOperations} from "../../Operatoins";
import {defaultPositions} from "./MassSpectrTablet";

const ChromeTablet = (props) => {

    const [date, setDate] = useState('');
    const [tabletNumber, setTabletNumber] = useState('');

    const [data, setData] = useState(props.data);
    const [portionsToSend, setPortionsToSend] = useState(props.data);
    const [currentValue, setCurrentValue] = useState();

    const hasInitialized = useRef(false);

    const columns = [
        {title: 'п/п', dataIndex: 'id', width: 50,},
        {title: 'Коллектор', dataIndex: 'collector', width: 100,},
        {title: 'Позиция фракции', dataIndex: 'code', width: 100,},
        {
            title: 'Позиция в планшете',
            dataIndex: 'tablet_position',
            width: 200,
            render: (value, record, index) => {
                return <Input value={value} onChange={e => {
                    record.tablet_position = e.target.value.toUpperCase();
                    updatePortionsToSend(record, index);
                }}
                              onFocus={() => setCurrentValue(value)}
                              onBlur={() => {
                                  if (currentValue !== value) {
                                      autoFillPositions(index);
                                  }
                              }}
                />;
            }
        }
    ];

    const okDisabled = () => {
        return !date || !tabletNumber || portionsToSend.some(item => !item.tablet_position);
    }

    function updatePortionsToSend(record, index) {
        const newData = portionsToSend;
        newData[index] = record;
        setPortionsToSend([...newData]);
    }

    function initialFillDefaultPositions() {
        const newData = portionsToSend.map((item, index) => {
            return {...item, tablet_position: defaultPositions[index]}
        });
        setPortionsToSend(newData);
    }

    function autoFillPositions(startIndex) {
        const currentPosition = portionsToSend[startIndex].tablet_position;
        const currentPositionIndex = defaultPositions.findIndex((position) => position === currentPosition);
        if (currentPositionIndex >= 0) {
            const newData = portionsToSend.map((item, index) => {
                if (index >= startIndex) {
                    return {...item, tablet_position: defaultPositions[currentPositionIndex + index - startIndex]};
                }
                return item;
            });
            setPortionsToSend(newData);
        }
    }

    useEffect(() => {
        setPortionsToSend(props.data);
    }, [data]);

    useEffect(() => {
        if (!hasInitialized.current && portionsToSend.length > 0) {
            hasInitialized.current = true;
            initialFillDefaultPositions();
        }
    }, [portionsToSend]);

    const save = () => {
        const positions = portionsToSend.map((portion) => ({
            id: portion.portion_id,
            tablet_position: portion.tablet_position,
            code: portion.code,
            collector: portion.collector,
        }));
        const params = {
            date: date,
            tablet_number: tabletNumber
        }
        OperationsApi.doOperation(ServerOperations.TO_MASS_SPECTR, positions, params).then((result) => {
            props.onClose();
        });

    }

    return <Modal open={true} width={1100}
                  onClose={props.onClose}
                  onOk={save}
                  onCancel={props.onClose}
                  destroyOnClose={true}
                  okButtonProps={{disabled: okDisabled()}}
    >
        <Row>
            <Col span={24}>
                <h3>Масс спектр (Планшет)</h3>
            </Col>
        </Row>
        <Row style={{marginTop: 10}}>
            <Col span={24}>
                <Form layout={'inline'} style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Form.Item label={'Дата передачи'}>
                        <ConfigProvider locale={locale}>
                            <NoFutureDate format={'DD.MM.YYYY HH:mm'}
                                          value={date ? dayjs(date) : null}
                                          placeholder={'Дата и время'}
                                          showTime
                                          onChange={(value) => setDate(value)}/>
                        </ConfigProvider>
                    </Form.Item>
                    <Form.Item label={'Номер планшета'}>
                        <Input value={tabletNumber} onChange={e => setTabletNumber(e.target.value)}/>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
        <Row style={{marginTop: 20}}>
            <Col span={24}>
                <Table dataSource={portionsToSend} size={'small'} columns={columns} rowKey={'id'}/>
            </Col>
        </Row>
    </Modal>;
}

ChromeTablet.propTypes = {
    data: PropTypes.array.isRequired,
    onClose: PropTypes.func,
    portionId: PropTypes.number,
};

export default ChromeTablet;
