import {Button, Table, Tooltip} from "antd";

export function OperationsTooltip(fieldName, columns) {

    return {
        title: '',
        width: 50,
        fieldName: fieldName,
        dataIndex: fieldName,
        render: (v) => {
            v = v?._all || v;
            let result = null;
            if (v && v.length > 1) {
                let id = 1;
                v = v.map((item, index) => {
                    let i = {id: id++};
                    i[fieldName] = item;
                    return i;
                });
                const table = <Table columns={columns} size={'small'}  dataSource={v} pagination={false} rowKey={'id'}/>
                result = <Tooltip destroyTooltipOnHide={true}
                                  placement={'left'}
                                  color={'#1a8d90'}
                                  autoAdjustOverflow={true}
                                  arrow={{ pointAtCenter: true }}
                                  title={table}><Button type="primary" size={'small'}>i</Button></Tooltip>;
            }
            return result;
        }
    }
}
