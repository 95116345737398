import PropTypes from "prop-types";
import {Button, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import api, {getBearerAuthHeader} from "../../lib/util";

export default function FileList(props) {

    function onRemove(file) {
        const file_id = file.id;
        api.postJSON(`/api/file/delete/${file_id}`).finally(() => {
            if (props.onRemove) {
                props.onRemove(file_id);
            }
        });
    }

    function downloadFileWithPOST(url) {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('Authorization', getBearerAuthHeader().Authorization);
        xhr.responseType = 'blob';
        xhr.onload = function () {
            if (xhr.status === 200) {
                const blob = xhr.response;
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.target = '_blank';
                a.href = url;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            }
        };
        xhr.send();
    }

    async function onCustomRequest(options) {

        const {file} = options;

        const formData = new FormData();
        formData.append("file", file);
        const params = {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: formData
        }
        params.headers = {
            ...params.headers,
            ...getBearerAuthHeader()
        }
        // не переделывать в api.getJSON!
        const resp = await fetch(`/api/file/store`, params);
        if (resp.status === 200) {
            const result = await resp.json();
            props.onAdd(result[0].id);
        } else {
            if (props.onError) {
                props.onError(resp);
            }
        }

    }

    function remapFiles(files) {
        return files ? files.map(file_id => ({
            id: file_id,
            uid: file_id,
            name: 'Файл ' + file_id,
            state: 'done',
            url: `/api/file/${file_id}`,
        })) : null;
    }

    return <div>
        <Upload action={'/api/file/store'}
                method={"POST"}
                fileList={remapFiles(props.files)}
                headers={getBearerAuthHeader()}
                onRemove={onRemove}
                customRequest={onCustomRequest}
                progress={{strokeColor: {from: '#108ee9', to: '#87d068'}}}
                showUploadList={{showRemoveIcon: true, showPreviewIcon: true}}
                multiple={true}
                onPreview={file => {
                    downloadFileWithPOST(file.url);
                }}>
            <Button icon={<UploadOutlined/>}>Загрузить</Button>
        </Upload>
    </div>;

}

FileList.onAdd = PropTypes.func.isRequired;
FileList.onRemove = PropTypes.func.isRequired;
FileList.files = PropTypes.array.isRequired;
