import {Button, Checkbox, Col, Modal, Row, Space, Table} from "antd";
import {useState} from "react";
import {getDefaultOperationColumns} from "../Shared";
import {UploadOutlined} from "@ant-design/icons";
import api, {getBearerAuthHeader} from "../../../lib/util";
import {NotifyError, NotifySuccess} from "../../../lib/notify";
import dayjs from "dayjs";

export function Mass(props) {

    const [portionsToSend, setPortionsToSend] = useState([]);
    const [saving, setSaving] = useState(false);

    function onOk() {
        setSaving(true);
        api.postJSON('/api/mass-spectrometry/mark', portionsToSend).then(() => {
            NotifySuccess('Данные успешно отправлены');
        }).catch(e => {
            NotifyError('Ошибка при отправке данных', e);
        }).finally(() => {
            setSaving(false);
            props.onHide();
        });
    }

    function updateData(index, record) {
        const newData = [...props.data];
        newData[index] = record;
        const dataToSend = newData.map(item => ({
            id: item.id, send: item.send || false,
        }));
        setPortionsToSend(dataToSend);
    }

    const exportExcel = () => {
        const ids = props.data.map(item => item.id);
        const loaded_ids = props.data.filter(item => item.send).map(item => item.id);

        // не переделывать в api.postJSON!
        const params = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ids, loaded_ids}),
        };
        params.headers = {
            ...params.headers,
            ...getBearerAuthHeader(),
        }

        fetch('/api/mass-spectrometry/export', params).then(response => {
            response.blob().then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                const currentDateTime = dayjs().format('DD-MM-YYYY_HH-mm-ss');
                a.download = `mass-spectrometry-${currentDateTime}.xlsx`;
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
        });
    }

    const getColumns = () => {
        let columns = getDefaultOperationColumns();
        columns.push({
            title: () => {
                const onChange = () => {
                    const allSelected = props.data.every(item => item.send);
                    const newData = props.data;
                    newData.forEach(item => item.send = !allSelected);
                    const dataToSend = newData.map(item => ({
                        id: item.id, send: item.send || false
                    }));
                    setPortionsToSend(dataToSend);
                }
                return <Space style={{display: 'flex', alignItems: 'start'}}>
                    <Checkbox type="checkbox" checked={props.data.every(item => item.send)}
                              onChange={onChange}/>
                    <span onClick={onChange} style={{cursor: 'pointer'}}>Выгружено</span>
                </Space>
            },
            dataIndex: 'send',
            key: 'send',
            width: 60,
            render: (text, record, index) => {
                return <Checkbox type="checkbox" checked={record.send} onChange={() => {
                    record.send = !record.send;
                    updateData(index, record);
                }}/>
            },
        });
        return columns;
    }


    return <Modal title="Масс спектрометрия" width={1400} onOk={onOk}
                  onCancel={props.onHide}
                  open={true}
                  okText={'Закрыть'}
                  cancelButtonProps={{style: {display: 'none'}}}
                  okButtonProps={{loading: saving}}
                  destroyOnClose={true}
    >
        <Row>
            <Col span={24}>
                <Table rowKey={"id"} dataSource={props.data} columns={getColumns()}
                       size={'small'} scroll={{y: '100%'}}
                />
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <Button icon={<UploadOutlined/>} onClick={exportExcel}>Экспорт в Excel</Button>
            </Col>
        </Row>

    </Modal>;

}
