import React, {useEffect, useState} from "react";
import api, {formatFIO} from "../../../lib/util";
import {Modal, Table} from "antd";
import PropTypes from "prop-types";

const PrevMaterials = (props) => {

    const {url} = props;

    const [materials, setMaterials] = useState([]);
    const [selectedMaterials, setSelectedMaterials] = useState([]);
    const columns = [{title: 'Наименование', dataIndex: 'name', key: 'name'},
        {title: 'Производитель', dataIndex: 'manufacturer', key: 'manufacturer'},
        {title: 'Номер партии', dataIndex: 'batch_number', key: 'batch_number'},
        {title: 'Каталожный номер', dataIndex: 'catalogue_number', key: 'catalogue_number'},
        {
            title: 'Дата производства',
            dataIndex: 'production_date',
            key: 'production_date',
            render: (text, record) => {
                return text ? new Date(text).toLocaleDateString('ru-RU') : <span>&mdash;</span>;
            }
        },
        {
            title: 'Годен до', dataIndex: 'sell_by', key: 'sell_by',
            render: (text, record) => {
                return text ? new Date(text).toLocaleDateString('ru-RU') : <span>&mdash;</span>;
            }
        },
        {
            title: 'Автор', dataIndex: 'name', key: 'name', render: (text, record) => {
                return formatFIO(record?.created?.name);
            }
        },
    ];

    const load = () => {
        setMaterials([]);
        api.getJSON(url).then(resp => {
            setMaterials(resp.materials);
        });
    }

    useEffect(() => {
        load()
    }, []);

    const onOk = () => {
        if (props.onOk) {
            props.onOk(selectedMaterials);
        }

    }
    const onCancel = () => {
        if (props.onCancel) {
            props.onCancel();
        }
    }

    return <Modal title={props.title}
                  open={true}
                  destroyOnClose={true}
                  width={'1080px'}
                  onOk={onOk} onCancel={onCancel}
                  okText={'Выбрать'} cancelText={'Отмена'}>
        <Table dataSource={materials} columns={columns} pagination={false}
               rowKey={'id'} size={'small'}
               rowSelection={{
                   type: 'checkbox',
                   onChange: (selectedRowKeys, selectedRows) => {
                       setSelectedMaterials(selectedRows);
                   },
               }}
        />
    </Modal>

}

PrevMaterials.propTypes = {
    url: PropTypes.string.isRequired,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    title: PropTypes.string.isRequired,
}

export default PrevMaterials;
