import React, {Component} from 'react';
import {
    Button, Col, DatePicker, Divider, Form, Input, message, Row,
    Select, Space, Spin, Table, Tooltip, Upload
} from "antd"
import PageHeader from "../../components/mock/PageHeader";
import {CloseOutlined, DeleteOutlined, InboxOutlined, SaveOutlined} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import api, {getBearerAuthHeader} from "../../lib/util";
import locale from 'antd/es/date-picker/locale/ru_RU';
import Text from "antd/lib/typography/Text";
import {NotifyInfo} from "../../lib/notify";
import FormattedInput from "../../components/FormattedInput";
import dayjs from "dayjs";
import usePermissions from "../../lib/Permissions";

const {Option} = Select;
const layout = {
    labelCol: {span: 7},
    wrapperCol: {span: 17},
};

const {Dragger} = Upload;

const orderPriorities = [
    {value: 2, label: 'Обычный'},
    {value: 3, label: 'Высокий'},
    {value: 4, label: 'Критичный'},
]

const orderRevisionStatuses = [
    {value: 1, label: 'Не требует сверки'},
    {value: 2, label: 'Ожидает сверки'},
    {value: 3, label: 'Сверка проведена', hidden: true},
];

const orderStatuses = [
    {value: 1, label: 'В ожидании'},
    {value: 2, label: 'В производстве'},
    {value: 3, label: 'Завершен', hidden: true},
    {value: 4, label: 'Отменен'},
]


export const getOrderPriority = (priority) => {
    const priorityObj = orderPriorities.find((item) => item.value == priority);
    return priorityObj ? priorityObj.label : '';
}

export const getOrderRevisionStatuses = (status) => {
    const statusObj = orderRevisionStatuses.find((item) => item.value == status);
    return statusObj ? statusObj.label : '';
}

export const getOrderStatus = (status) => {
    const statusObj = orderStatuses.find((item) => item.value == status);
    return statusObj ? statusObj.label : '';
}

class OrderEdit extends Component {

    state = {
        search: '',
        order: {
            user_id: null,
            user_contact_id: null,
            order_number: null,
            order_client_number: null,
            order_request_number: null,
        },
        clients: [],
        contacts: [],
        total: 0,
        loading: false,
        pagination: 0,
        olig_default_type_id: 1,
        selected: {},
    };

    order_id = null;
    timeout_id = null;
    formRef = React.createRef();

    constructor(props) {
        super(props);
        //     // TODO сделать подачу order_id напрямую через props.orderId
        if (this.props.match?.params?.id) {
            this.order_id = this.props.match.params.id;
        }
    }

    getOrderStatus() {
        return orderStatuses.filter((item) => item.hidden !== true);
    }

    getRevisionStatuses() {
        return orderRevisionStatuses.filter((item) => item.hidden !== true);
    }

    async onChangeOlig(name, value, record, index) {
        if (this.timeout_id) {
            clearTimeout(this.timeout_id);
        }
        this.timeout_id = setTimeout(async () => {
            let oligs = this.state.order.oligonucleotids;
            if (name === 'quantity') {
                oligs[index]['pivot'][name] = value;
                oligs[index][name] = value;
            } else {
                oligs[index][name] = value;
            }
            this.setState({order: {...this.state.order, oligonucleotids: oligs}});
            if (name === 'full_sequence') {
                const result = await this.validateSequence(value);
                if (result) {
                    if (result.errors) {
                        oligs[index]['error'] = false;
                        oligs[index]['modification_5'] = null;
                        oligs[index]['modification_3'] = null;
                        oligs[index]['length'] = null;
                        oligs[index]['types'] = [];
                        this.formRef.current.setFields([
                            {
                                name: ['oligonucleotide', index, 'full_sequence'],
                                errors: [
                                    <Tooltip title={result.errors.sequence[0]}>{result.errors.sequence[0]}</Tooltip>
                                ]
                            },
                        ]);

                    } else {
                        oligs[index]['error'] = false;
                        oligs[index]['modification_5'] = result.modification_5;
                        oligs[index]['modification_3'] = result.modification_3;
                        oligs[index]['length'] = result.length;
                        oligs[index]['types'] = result.types;
                        this.formRef.current.setFields([
                            {
                                name: ['oligonucleotide', index, 'full_sequence'],
                                errors: []
                            },
                        ]);
                    }
                    this.setState({order: {...this.state.order, oligonucleotids: oligs}});
                }
            }
        }, 500);

    }

    async validateSequence(sequence) {
        const postData = {
            sequence: sequence
        };
        return new Promise((resolve) => {
            api.postJSON('/api/oligonucleotid/validate', postData).then((result) => {
                resolve(result);
            }).catch((error) => {
                resolve(error);
            });
        });
    }

    getColumns(obj) {
        return [
            {
                title: '№', dataIndex: 'index', key: 'index', width: 50, fixed: 'left',
                render: (val, row, idx) => {
                    return idx + 1;
                }
            },
            {
                title: 'Наименование',
                dataIndex: 'name',
                key: 'name',
                width: '200px',
                render: function (value, record, index) {
                    return <Form.Item wrapperCol={{sm: 24}} shouldUpdate initialValue={value}
                                      rules={[{required: true, message: 'Введите наименование'}]}
                                      onChange={(e) => obj.onChangeOlig('name', e.target.value, record, index)}
                                      name={['oligonucleotide', index, 'name']}><Input/></Form.Item>
                },
                fixed: 'left',
            },
            {
                title: 'Тип',
                dataIndex: 'type',
                key: 'type',
                ellipsis: false,
                width: '110px',
                render: function (value, record, index) {
                    if (record.types && record.types.length > 0) {
                        return <>{record.types.map((record, index) => <Text key={index}
                                                                            id={index}>{record.name}<br/></Text>)}</>
                    }
                    return <></>
                },
                fixed: 'left',
            },
            {
                title: "Длина",
                dataIndex: 'length',
                key: 'length',
                width: '80px',
                render: function (value, record, index) {
                    return value;
                },
                fixed: 'left',
            },
            {
                title: 'Последовательность',
                dataIndex: 'full_sequence',
                key: 'full_sequence',
                ellipsis: false,
                render: function (value, record, index) {

                    function renderSequence() {
                        const Component = obj.state.selected[index] ? TextArea : Input;
                        return <Component
                            autoFocus={obj.state.selected[index]}
                            onFocus={() => obj.setState({selected: {[index]: true}})}
                            onBlur={() => obj.setState({selected: {[index]: false}})}
                            rows={3}
                        />;
                    }

                    const ret = <>
                        <Form.Item wrapperCol={{sm: 24}} shouldUpdate initialValue={value}
                                   onChange={(e) => obj.onChangeOlig('full_sequence', e.target.value, record, index)}
                                   rules={[{required: true, message: 'Введите последовательность'}]}
                                   name={['oligonucleotide', index, 'full_sequence']}>
                            {renderSequence()}
                        </Form.Item>
                    </>
                    return ret
                },
                fixed: 'left',
            },
            {
                title: '5\'',
                dataIndex: 'modification_5',
                key: 'modification_5',
                width: '80px',
                render: function (value, record, index) {
                    return value;
                },
            },
            {
                title: '3\'',
                dataIndex: 'modification_3',
                key: 'modification_3',
                width: '80px',
                render: function (value, record, index) {
                    return value;
                },
            },
            {
                title: 'Кол-во',
                dataIndex: 'quantity',
                key: 'quantity',
                width: '200px',
                render: function (value, record, index) {
                    const parsedValue = parseFloat(record.quantity || record.pivot.quantity);
                    return <>
                        <Form.Item wrapperCol={{sm: 24}} initialValue={record.quantity || record.pivot.quantity}
                                   rules={[{required: true, message: 'Введите количество'}]}
                                   name={['oligonucleotide', index, 'quantity']}>
                            <FormattedInput value={parsedValue} onChange={(v) => {
                                obj.onChangeOlig('quantity', v, record, index);
                            }}/>
                        </Form.Item></>
                },
            },
            {
                title: 'NGS',
                dataIndex: 'ngs',
                key: 'ngs',
                width: '220px',
                style: {maxWidth: '100% !important'},
                render: function (value, record, index) {
                    return <Form.Item style={{maxWidth: '100% !important'}}
                                      name={['oligonucleotide', index, 'ngs']}
                                      onChange={(e) => obj.onChangeOlig('ngs', e.target.value, record, index)}
                                      initialValue={record.pivot?.ngs || false}>
                        <Select placeholder="NGS" style={{width: '100%'}}>
                            <Option value={false}>Нет</Option>
                            <Option value={true}>Да</Option>
                        </Select>
                    </Form.Item>
                },
            },
            {
                title: 'Форма поставки',
                dataIndex: 'substance_type_id',
                key: 'substance_type_id',
                width: '220px',
                style: {maxWidth: '100% !important'},
                render: function (value, record, index) {
                    return <Form.Item style={{maxWidth: '100% !important'}}
                                      name={['oligonucleotide', index, 'substance_type_id']}
                                      onChange={(e) => obj.onChangeOlig('substance_type_id', e.target.value, record, index)}
                                      initialValue={record.substance_type_id || record.pivot?.type || 1}>
                        <Select placeholder="Форма поставки" style={{width: '100%'}}>
                            <Option value={1}>Раствор</Option>
                            <Option value={2}>Лиофилизат</Option>
                        </Select>
                    </Form.Item>
                },
            },
            {
                title: 'Комментарий',
                dataIndex: 'comment',
                key: 'comment',
                width: '200px',
                render: function (value, record, index) {
                    return <Form.Item wrapperCol={{sm: 24}} initialValue={record.pivot?.comment || record.comment || ''}
                                      onChange={(e) => obj.onChangeOlig('comment', e.target.value, record, index)}
                                      name={['oligonucleotide', index, 'comment']}>
                        <TextArea disabled={obj.formDisabled()}/>
                    </Form.Item>
                },
            },
            {
                title: '',
                dataIndex: 'edit',
                key: 'edit',
                render: function (text, record, index) {
                    return <div><Button size="small" onClick={() => obj.handleDelete(record, index)}
                                        shape="circle"><DeleteOutlined/></Button></div>
                },
                width: 85,
            }
        ];
    }

    handleDelete(record, index) {
        const oligonucleotids = this.state.order.oligonucleotids.filter(function (record, iterator) {
            return iterator !== index;
        });

        this.setState({order: {...this.state.order, oligonucleotids: oligonucleotids}});
    }

    async componentDidMount() {
        await this.fetchClients()
        if (this.order_id) {
            await this.fetchOrder()
        }
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.order.user_id !== prevState.order.user_id) {
            this.setState({order: {...this.state.order, user_contact_id: null}});
            this.formRef.current.setFieldsValue({user_contact_id: null});
            this.fetchContacts();
        }
    }

    async fetchOrder() {
        this.setState({loading: true});
        let result = await api.getJSON(`/api/order/${this.order_id}`);
        result.client = result.user.id;
        this.setState({
            loading: false,
            order: result
        });
        let values = {
            id: result.id,
            comment: result.comment,
            priority: result.priority,
            order_type_id: result.order_type_id,
            user_id: result.user_id,
            user_contact_id: result.user_contact_id,
            created_at: dayjs(result.created_at),
            revision_status_id: result.revision_status_id,
            order_status_id: result.order_status_id,
            order_number: result.order_number,
            order_client_number: result.order_client_number,
            order_request_number: result.order_request_number,
        };
        if (result.deadline_at) {
            values.deadline_at = dayjs(result.deadline_at);
        }
        this.formRef.current.setFieldsValue(values);

    }

    fetchClients = async (params = {}) => {
        this.setState({loading: true});
        let result = await api.getJSON('/api/client/all');
        // const mappedClients = result.map((item) => ({label: `${item.name} (${item.organization})`, value: item.id}))
        this.setState({
            loading: false,
            clients: result,
        });
    };

    appendOligs(oligs) {
        let items = this.state.order.oligonucleotids || [];
        let i = 1;
        items = items.concat(oligs);
        items = items.map((item) => ({...item, id: i++}))
        return items;
    }

    applyDefaultValues(oligs) {
        oligs.forEach((item, index) => {
            if (!item.pivot) {
                item.pivot = {};
            }
            item.substance_type_id = this.state.olig_default_type_id;
            item.error = false;
            return item;
        });
        return oligs;
    }

    onUploadFile(info) {
        const {status} = info.file;
        if (status === 'done') {
            let oligs = this.appendOligs(info.file.response.items);
            this.applyDefaultValues(oligs);
            this.setState({order: {...this.state.order, oligonucleotids: oligs}}, () => {
                oligs.forEach((item, index) => {
                    if (item.error_message) {
                        this.formRef.current.setFields([{
                            name: ['oligonucleotide', index, 'full_sequence'],
                            errors: [
                                <Tooltip title={item.error_message}>{item.error_message}</Tooltip>
                            ],
                        }]);
                    }
                });
                this.scrollToError();
            });
        } else if (status === 'error') {
            message.error(`Ошибка при загрузке файла ${info.file.name}`);
        }
    }

    clearOligErrors() {
        let oligs = this.state.order.oligonucleotids;
        let errors = [];
        if (oligs) {
            oligs.forEach((item, index) => {
                Object.entries(item).forEach(([key, value]) => {
                    let error = this.formRef.current.getFieldError(['oligonucleotide', index, key]);
                    if (error.length > 0) {
                        const error = ({
                            name: ['oligonucleotide', index, key],
                            errors: [],
                        });
                        errors.push(error)
                    }
                });
            });
        }
        // эта строка тормозит
        this.formRef.current.setFields(errors);
    }

    onFinishFailed() {
        this.scrollToError();
    }

    saveOrder() {
        const fieldErrors = this.formRef.current.getFieldsError();
        const errors = [];
        Object.keys(fieldErrors).forEach((field) => {
            if (fieldErrors[field].errors.length > 0) {
                errors.push(fieldErrors[field].errors[0]);
            }
        });
        if (errors.length > 0) {
            this.scrollToError();
            return;
        }
        const values = this.formRef.current.getFieldsValue();
        this.setState({loading: true});
        const url = (this.state.order.id) ? `/api/order/update/${this.state.order.id}` : `/api/order/store`;
        this.clearOligErrors();
        api.postJSON(url, values).then((result) => {
            if (!result.message && !result.errors) {
                this.setState({order: {...this.state.order, id: result.id}});
                NotifyInfo('Сохранено');
            }
        }).catch((result) => {
            this.setState({loading: false});
            if (result.message && result.errors) {
                for (const [key, value] of Object.entries(result.errors)) {
                    let keys = key.split('.');
                    if (key == 'oligonucleotide') {
                        this.formRef.current.setFields([
                            {
                                name: 'oligonucleotide_wrapper',
                                errors: [value]
                            },
                        ]);
                        continue;
                    }
                    this.formRef.current.setFields([
                        {
                            name: (keys.length > 1) ? [keys[0], parseInt(keys[1]), keys[2]] : key,
                            errors: [value]
                        },
                    ]);
                }
                this.scrollToError();
            }
        }).finally(() => {
            this.setState({loading: false});
        });
    }

    scrollToError() {
        setTimeout(() => {
            let el = document.querySelectorAll('.ant-form-item-explain-error, .table-row-error, .ant-input-status-error');
            if (el.length) {
                el[0].scrollIntoView({block: "center", behavior: "smooth"});
            }
        }, 100);
    }

    async checkCreationDate(field, value) {
        return new Promise((resolve, reject) => {
            if (value > dayjs()) {
                return reject(new Error('Дата заказа не может быть больше текущей'));
            } else {
                return resolve();
            }
        })
    }

    async checkExecutionDate(field, value) {
        const created_at = this.formRef.current.getFieldValue('created_at');
        return new Promise((resolve, reject) => {
            if (value && !dayjs(value).isAfter(created_at)) {
                return reject(new Error('Дата поставки не может быть меньше даты заказа'));
            } else {
                return resolve();
            }
        })
    }

    addOlig() {
        const olig = [{
            id: Math.floor(Math.random() * 10000),
            sequence: '',
            quantity: '',
            pivot: {
                quantity: '',
            }
        }];
        this.setState({
            order: {
                ...this.state.order,
                oligonucleotids: [...this.state.order.oligonucleotids || [], ...olig]
            }
        });
    }

    formDisabled() {
        return this.state.order.order_status_id === 3 || this.state.order.revision_status_id === 3;
    }

    render() {
        const {order, loading, clients, contacts, olig_default_type_id} = this.state;
        const title = this.order_id ? "Редактирование заказа" : "Создание заказа";

        return (
            <React.Fragment>
                <PageHeader
                    title={title}
                />
                <Spin spinning={this.state.loading}>
                    <Form
                        disabled={this.formDisabled()}
                        {...layout}
                        preserve={false}
                        name="basic"
                        style={{background: '#fff', padding: 24, minHeight: 280}}
                        ref={this.formRef}
                        onFinish={this.saveOrder.bind(this)}
                        onFinishFailed={() => this.onFinishFailed()}
                    >

                        <Row align={"end"}>
                            <Form.Item>
                                <Space direction="horizontal" align={"center"}>
                                    {this.props.canEdit && <Button type="primary" icon={<SaveOutlined/>}
                                            htmlType="submit">Сохранить</Button>}
                                    <Button type="secondary" htmlType={"button"} icon={<CloseOutlined/>}
                                            href={"/order"}>Закрыть</Button>
                                </Space>
                            </Form.Item>
                        </Row>

                        <Row>
                            <Col span={16}>
                                <Form.Item
                                    label="Дата заказа"
                                    name="created_at"
                                    rules={[{
                                        required: false,
                                        message: 'Дата заказа обязательное'
                                    }, {validator: this.checkCreationDate.bind(this)}]}
                                    initialValue={dayjs(order.created_at)}
                                >
                                    <DatePicker locale={locale} format="DD.MM.YYYY"/>
                                </Form.Item>
                                <Form.Item
                                    label="Плановая дата завершения работ (поставки)"
                                    name="deadline_at"
                                    rules={[{required: false}, {validator: this.checkExecutionDate.bind(this)}]}
                                    initialValue={order.deadline_at ? dayjs(order.deadline_at) : ""}
                                >
                                    <DatePicker locale={locale} format="DD.MM.YYYY"/>
                                </Form.Item>
                                <Form.Item name={'order_number'}
                                           label={'Номер заказа'}
                                           initialValue={order.order_number}
                                           rules={[{
                                               required: true,
                                               message: 'Поле Номер заказа обязательное'
                                           }]}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item name={'order_client_number'}
                                           label={'Номер заказа клиента'}
                                           initialValue={order.order_client_number}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item name={'order_request_number'}
                                           label={'Номер заявки'}
                                           initialValue={order.order_request_number}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="Клиент"
                                    name="user_id"
                                    rules={[{required: true, message: 'Поле Клиент обязательное'}]}
                                    initialValue={order.user_id}
                                >
                                    <Select showSearch placeholder="Выберите клиента"
                                            onChange={(value) => {
                                                this.setState({order: {...this.state.order, user_id: value}});
                                            }}
                                            filterOption={function (input, option) {
                                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }}>
                                        {clients.map(function (item) {
                                            return <Option value={item.id}
                                                           key={item.id}>{item.organization}</Option>;
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Контакное лицо"
                                    name="user_contact_id"
                                    initialValue={order.user_contact_id}
                                >
                                    <Select showSearch placeholder="Выберите контактное лицо"
                                            filterOption={function (input, option) {
                                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }}>
                                        {contacts.map(function (item) {
                                            return <Option value={item.id}
                                                           key={item.id}>{item.name}</Option>;
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Тип заказа"
                                    name="order_type_id"
                                    rules={[{required: false, message: 'Поле Тип заказа обязательное'}]}
                                    initialValue={(order && order.order_type_id) || 1}
                                >
                                    <Select placeholder="Тип заказа">
                                        <Option value={1}>Внешний заказ</Option>
                                        <Option value={2}>Внешний пробный заказ</Option>
                                        <Option value={3}>Внутренний заказ</Option>
                                        <Option value={4}>Рекламация</Option>
                                        <Option value={5}>Эксперимент</Option>
                                    </Select>
                                </Form.Item>
                                {this.formDisabled() && <><Form.Item label={'Статус заказа'}>
                                    <Text strong>{getOrderStatus(order.order_status_id)}</Text>
                                </Form.Item>
                                    <Form.Item label={'Статус сверки'}>
                                        <Text strong>{getOrderRevisionStatuses(order.revision_status_id)}</Text>
                                    </Form.Item>
                                </>}
                                {!this.formDisabled() && <Form.Item
                                    label="Статус заказа"
                                    name="order_status_id"
                                    rules={[{required: false, message: 'Поле Статус заказа обязательное'}]}
                                    initialValue={1}
                                >
                                    <Select placeholder="Статус заказа" options={this.getOrderStatus()}/>
                                </Form.Item>}
                                {!this.formDisabled() && <Form.Item
                                    label="Статус сверки"
                                    name="revision_status_id"
                                    rules={[{required: false, message: 'Поле Статус сверки обязательное'}]}
                                    initialValue={2}
                                >
                                    <Select placeholder="Статус сверки" options={this.getRevisionStatuses()}/>
                                </Form.Item>}
                                <Form.Item
                                    label="Приоритет"
                                    name="priority"
                                    initialValue={(order && order.priority) || 2}
                                    rules={[{required: false, message: 'Поле Приоритет обязательное'}]}
                                >
                                    <Select placeholder="Тип заказа" options={orderPriorities}/>
                                </Form.Item>
                                <Form.Item
                                    label="Комментарий"
                                    name="comment"
                                    rules={[{required: false}]}
                                    initialValue={order.comment}
                                >
                                    <TextArea disabled={this.formDisabled()}/>
                                </Form.Item>
                            </Col>
                            <Col span={1}>
                                <Divider/>
                            </Col>
                            <Col span={7}>
                                <Row>
                                    <Col span={24}>
                                        {this.props.canEdit && <Dragger
                                            name='file'
                                            multiple={true}
                                            headers={getBearerAuthHeader()}
                                            action={`/api/oligonucleotid/import`}
                                            onChange={(info) => this.onUploadFile(info)}
                                            onDrop={this.onDrop}
                                            style={{maxHeight: '300px', minHeight: '300px'}}
                                        >
                                            <p className="ant-upload-drag-icon">
                                                <InboxOutlined/>
                                            </p>
                                            <p className="ant-upload-text">Нажмите или перетащите файл для
                                                загрузки</p>
                                        </Dragger>}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} style={{paddingTop: "10px"}}>
                                        <Form.Item
                                            label="Форма поставки"
                                            name="olig_default_type_id"
                                            initialValue={olig_default_type_id}
                                            rules={[{required: false, message: 'Поле Форма поставки обязательное'}]}
                                        >
                                            <Select
                                                onChange={(value) => {
                                                    const oligs = this.state.order.oligonucleotids;
                                                    oligs.map(function (item) {
                                                        item.substance_type_id = value;
                                                    });
                                                    this.setState({
                                                        order: {
                                                            ...this.state.order,
                                                            oligonucleotids: oligs,
                                                            olig_default_type_id: value
                                                        }
                                                    });
                                                    const order = this.formRef.current.getFieldsValue();
                                                    order.oligonucleotide.map(function (item) {
                                                        item.substance_type_id = value;
                                                    });
                                                    this.formRef.current.setFieldsValue({
                                                        ...order,
                                                        olig_default_type_id: value,
                                                    });
                                                }}
                                                placeholder="Форма поставки" style={{width: '100%'}}>
                                                <Option key={1} value={1}>Раствор</Option>
                                                <Option key={2} value={2}>Лиофилизат</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} style={{paddingTop: "10px"}}>
                                        <Form.Item
                                            label="NGS"
                                            name="ngs_default_value"
                                            initialValue={false}
                                        >
                                            <Select
                                                onChange={(value) => {
                                                    const order = this.formRef.current.getFieldsValue();
                                                    order.oligonucleotide.map(function (item) {
                                                        item.ngs = value;
                                                    });
                                                    this.formRef.current.setFieldsValue({...order});
                                                }}
                                                placeholder="Форма поставки" style={{width: '100%'}}>
                                                <Option key={1} value={true}>Да</Option>
                                                <Option key={2} value={false}>Нет</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                        <Table
                            columns={this.getColumns(this)}
                            rowClassName={(record, index) => record.error ? 'table-row-error' : ''}
                            rowKey='id'
                            dataSource={this.state.order.oligonucleotids}
                            scroll={{y: '100%', x: 1600}}
                            size="small"
                            pagination={false}
                            loading={loading}
                        />
                        <Row>
                            {this.props.canEdit &&
                                <Button type="link" onClick={this.addOlig.bind(this)}>Добавить олиг</Button>}
                        </Row>
                        <Form.Item
                            label=""
                            name="oligonucleotide_wrapper"
                            rules={[{required: false}]}
                        >
                            <Input style={{display: 'none'}} readOnly={true}/>
                        </Form.Item>
                        <Row align={"end"}>
                            <Form.Item>
                                <Space direction="horizontal" align={"center"}>
                                    {this.props.canEdit && <Button type="primary" icon={<SaveOutlined/>}
                                                                   htmlType="submit">Сохранить</Button>}
                                    <Button type="secondary" htmlType={"button"} icon={<CloseOutlined/>}
                                            href={"/order"}>Закрыть</Button>
                                </Space>
                            </Form.Item>
                        </Row>
                    </Form>
                </Spin>
            </React.Fragment>
        );
    }

    async fetchContacts() {
        this.setState({loading: true});
        let result = await api.getJSON(`/api/user/${this.state.order.user_id}/contacts`);
        this.setState({
            loading: false,
            contacts: result.data,
        });
    }
}

const OrderEditWrap = (props) => {
    const {canEdit} = usePermissions();
    return <OrderEdit {...props} canEdit={canEdit}/>;
}

export default OrderEditWrap;
