import {Col, Modal, Row, Select, Space} from "antd";
import {DeblockTable} from "./DeblockTable";
import {useEffect, useState} from "react";
import {OperationsApi, ServerOperations} from "../Operatoins";
import MaterialParties from "../../Material/MaterialParties";
import TextArea from "antd/lib/input/TextArea";

export function RemoveFromCarrier(props) {

    const [reactive, setReactive] = useState();
    const [volume, setVolume] = useState();
    const [time, setTime] = useState();
    const [repeating, setRepeating] = useState();
    const [materials, setMaterials] = useState([]);
    const [comment, setComment] = useState();

    const [portionsToSend, setPortionsToSend] = useState({});

    function onOk() {
        const ids = materials.map(item => item.id);
        const portions = portionsToSend.map(item => {
            item.params.materials = ids;
            return item;
        });
        OperationsApi.doOperation(ServerOperations.REMOVE_FROM_CARRIER, portions).then((result) => {
            props.onHide();
        });
    }

    const reactiveOptions = [
        {id: 'NH3', value: 'NH3'},
        {id: 'MeNH2', value: 'MeNH2'},
        {id: 'AMA', value: 'AMA'},
        {id: 'TAMRA', value: 'TAMRA'},
        {id: 'NH4F', value: 'NH4F'},
        {id: 'TEA THF', value: 'TEA THF'},
    ]


    const volumeOptions = [
        {id: '100', value: '100'},
        {id: '200', value: '200'},
        {id: '300', value: '300'},
        {id: '400', value: '400'},
    ];

    const timeOptions = [
        {id: 'сразу', value: 'сразу'},
        {id: '5 мин', value: '5 мин'},
        {id: '10 мин', value: '10 мин'},
        {id: '15 мин', value: '15 мин'},
        {id: '20 мин', value: '20 мин'},
        {id: '25 мин', value: '25 мин'},
        {id: '30 мин', value: '30 мин'},
        {id: '35 мин', value: '35 мин'},
        {id: '40 мин', value: '40 мин'},
        {id: '45 мин', value: '45 мин'},
        {id: '50 мин', value: '50 мин'},
        {id: '55 мин', value: '55 мин'},
        {id: '60 мин', value: '60 мин'},
    ];

    const repeatOptions = [
        {id: '1', value: '1'},
        {id: '2', value: '2'},
        {id: '3', value: '3'},
        {id: '4', value: '4'},
        {id: '5', value: '5'},
    ]

    function handleReactiveChange(value) {
        setReactive(value);
        updateData();
    }

    function handleVolumeChange(value) {
        setVolume(value);
        updateData();
    }

    function handleTimeChange(value) {
        setTime(value);
        updateData();
    }

    function handleRepeatChange(value) {
        setRepeating(value);
        updateData();
    }

    function updateData() {
        setPortionsToSend(props.data.map(item => ({
            id: item.id, params: {
                reactive: reactive, volume: volume,
                time: time, repeating: repeating,
                comment
            }
        })));
    }

    function OKDisabled() {
        return !(reactive && volume && time && repeating);
    }

    useEffect(() => {
        updateData();
    }, [reactive, volume, time, repeating]);

    return <Modal
        title="Снятие с носителя"
        okButtonProps={{disabled: OKDisabled()}}
        open={true}
        width={1300}
        onOk={onOk}
        onCancel={props.onHide}
        okText={'OK'}
        cancelText={'Отмена'}
        destroyOnClose={true}
    >
        <Row>
            <Col span={24} style={{padding: '10px'}}>
                <Space size={12} direction="vertical" style={{width: '150px', marginLeft: 10}}>
                    <div>Реактив</div>
                    <Select value={reactive} options={reactiveOptions} onChange={handleReactiveChange}/>
                </Space>
                <Space size={12} direction="vertical" style={{width: '150px', marginLeft: 10}}>
                    <div>Объем</div>
                    <Select value={volume} options={volumeOptions} onChange={handleVolumeChange}/>
                </Space>
                <Space size={12} direction="vertical" style={{width: '150px', marginLeft: 10}}>
                    <div>Время</div>
                    <Select value={time} options={timeOptions} onChange={handleTimeChange}/>
                </Space>
                <Space size={12} direction="vertical" style={{width: '150px', marginLeft: 10}}>
                    <div>Повторы, шт</div>
                    <Select value={repeating} options={repeatOptions} onChange={handleRepeatChange}/>
                </Space>
                <Space size={12} direction="vertical" style={{width: '500px', marginLeft: 10}}>
                    <div>Комментарий</div>
                    <TextArea value={comment} style={{width: '100%', height: '100px'}}
                              onChange={(e) => setComment(e.target.value)}/>
                </Space>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <MaterialParties onChange={(v) => {
                    setMaterials(v);
                }} initialValue={materials}/>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <DeblockTable dataSource={props.data}/>
            </Col>
        </Row>
    </Modal>

}
