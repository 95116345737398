import {Button, Modal} from "antd";
import Crud from "../../lib/Crud";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import {useEffect, useState} from "react";
import api, {formatFIO} from "../../lib/util";
import ChooseParty from "./ChooseParty";
import './MaterialOnStock.scss';

let _formInstance = null;
let _setItem = null;

const MaterialsOnStock = (props) => {

    const [material, setMaterial] = useState(null);
    const [chooseMaterial, setChooseMaterial] = useState(false);
    const [currentComponentId, setCurrentComponentId] = useState(null);

    const loadMaterial = (id) => {
        api.getJSON(`/api/material/${id}`).then(data => {
            setMaterial(data);
        });
    }

    useEffect(() => {
        loadMaterial(props.materialId);
    }, []);

    function getEditConfig() {
        let form = [];
        if (material.is_composite) {
            form.push({
                name: 'catalogue_number',
                title: 'Каталожный номер',
                type: 'text',
                style: {width: '100px'},
                rules: [{required: true, message: 'Заполните это поле'}]
            })

            form.push({
                name: 'production_date',
                title: 'Дата производства',
                type: 'date',
                rules: [{required: true, message: 'Заполните это поле'}]
            });

            form.push({
                name: 'sell_by',
                title: 'Годен до',
                type: 'date',
            });


            form.push({
                type: 'separator',
                title: 'Состав'
            });

            for (const c in material.components) {
                form.push({
                    name: `component_${material.components[c].id}`,
                    rules: [{required: true, message: 'Заполните это поле'}],
                    render: (item, formInstance, setItem) => {
                        _formInstance = formInstance;
                        _setItem = setItem;
                        const componentName = `component_${material.components[c].id}`;
                        const component = item[componentName];
                        return <>
                            <div style={{display: 'flex'}} className={'material-consist-row'}>
                            <span style={{padding: 10, display: 'inline-block'}}
                                  className={'material-consist-row__name'}>
                                Название <br/> <strong
                                className={'material-consist-row__count'}>{material.components[c].name}</strong>
                            </span>
                                <span style={{padding: 10, display: 'inline-block'}}
                                      className={'material-consist-row__name'}>
                                Производитель <br/> <strong
                                    className={'material-consist-row__count'}>{component?.manufacturer}</strong>
                            </span>
                                <span style={{padding: 10, display: 'inline-block'}}
                                      className={'material-consist-row__name'}>
                                Номер партии <br/> <strong
                                    className={'material-consist-row__count'}>{component?.catalogue_number}</strong>
                            </span>
                                <span style={{padding: 10, display: 'inline-block'}}
                                      className={'material-consist-row__name'}>
                                Дата производства <br/>
                                    <strong className={'material-consist-row__count'}>
                                    {component?.production_date ? dayjs(component.production_date).format('DD.MM.YYYY') : '-'}
                                    </strong>
                            </span>
                                <span style={{padding: 10, display: 'inline-block'}}
                                      className={'material-consist-row__name'}>
                                Каталожный номер <br/> <strong
                                    className={'material-consist-row__count'}>{component?.catalogue_number}</strong>
                            </span>
                                <span style={{
                                    padding: 10,
                                    display: 'inline-flex',
                                    justifyContent: 'end',
                                    flexGrow: 1
                                }}>
                                        {props.canEdit && <Button type={'primary'} onClick={() => {
                                            setCurrentComponentId(material.components[c].id);
                                            setChooseMaterial(true);
                                        }}>Выбрать партию</Button>}
                                    </span>
                            </div>
                        </>
                    }
                });
            }
        } else {
            form.push({
                name: 'manufacturer',
                title: 'Производитель',
                style: {width: '300px'},
                type: 'text',
                rules: [{required: true, message: 'Заполните это поле'}]
            });
            form.push({
                name: 'catalogue_number',
                title: 'Каталожный номер',
                type: 'text',
                style: {width: '200px'},
                rules: [{required: true, message: 'Заполните это поле'}]
            });
            form.push({
                name: 'batch_number',
                title: 'Номер партии',
                type: 'text',
                style: {width: '200px'},
                rules: [{required: true, message: 'Заполните это поле'}]
            })
            form.push({
                name: 'production_date',
                title: 'Дата производства',
                type: 'date',
                rules: [{required: true, message: 'Заполните это поле'}]
            });
            form.push({
                name: 'sell_by',
                title: 'Годен до',
                type: 'date',
            });
        }

        return form;
    }

    return <Modal width={'1080px'}
                  open={true}
                  destroyOnClose={true}
                  maskClosable={false}
                  onCancel={props.onClose}
                  footer={<Button type={'primary'} onClick={props.onClose}>Закрыть</Button>}>

        {chooseMaterial && <ChooseParty materialId={currentComponentId}
                                        onOk={(row) => {
                                            let field = `component_${currentComponentId}`;
                                            let values = _formInstance.getFieldsValue();
                                            values[field] = row;
                                            _setItem(values);
                                            _formInstance.setFieldsValue(values);
                                            setChooseMaterial(false);
                                        }}
                                        onCancel={() => {
                                            setChooseMaterial(false);
                                        }}
        />}
        {material && <Crud
            width={'1080px'}
            title={`Партии материала "${material.name}"`}
            viewConfig={[
                {title: 'Производитель', dataIndex: 'manufacturer', key: 'manufacturer'},
                {
                    title: 'Дата производства',
                    dataIndex: 'production_date',
                    key: 'production_date',
                    render: (value) => {
                        return dayjs(value).format('DD.MM.YYYY');
                    }
                },
                {title: 'Каталожный номер', dataIndex: 'catalogue_number', key: 'catalogue_number',},
                {title: 'Номер партии', dataIndex: 'batch_number', key: 'batch_number'},
                {
                    title: 'Годен до', dataIndex: 'sell_by', key: 'sell_by', render: (value) => {
                        let result = '-';
                        if (value) {
                            result = dayjs(value).format('DD.MM.YYYY');
                        }
                        return result;
                    }
                },
                {
                    title: 'Автор', dataIndex: 'name', key: 'name', render: (text, record) => {
                        return formatFIO(record?.created?.name);
                    }
                },

            ]}
            editConfig={getEditConfig()}
            editorWidth={material.is_composite ? '1080px' : '500px'}
            editTitle={'Редактирование партии материала'}
            createTitle={'Создание партии материала'}
            onMapLoad={data => data.data}
            onMapItem={data => {
                for (const c in data.components) {
                    data[`component_${data.components[c].material_id}`] = data.components[c];
                }
                delete data.components;
                return data;
            }}
            onMapSave={data => {
                data.components = [];
                for (let c in material.components) {
                    const fieldId = `component_${material.components[c].id}`;
                    if (data[fieldId]) {
                        data.components.push(data[fieldId].id);
                        delete data[fieldId];
                    }
                }
                return data;
            }}
            url={`/api/material/${props.materialId}/variants`}
            onRenderField={(field, data) => {
                if (field.name === 'components') {
                    return material.is_composite === true;
                }
                return field;
            }}
            {...props}
        />}
    </Modal>

}

MaterialsOnStock.propTypes = {
    materialId: PropTypes.number.isRequired,
}

export default MaterialsOnStock;
