import {Col, Modal, Row, Select} from "antd";
import {DeblockTable} from "./DeblockTable";
import api from "../../../lib/util";
import {NotifyError} from "../../../lib/notify";
import {useColumns} from "../Shared";

export function Dewaste(props) {

    const {columns} = useColumns(false);

    function onOK() {
        const ids = {ids: props.data.map((d) => d.id)};
        api.postJSON('/api/portion/dewaste', ids).then(response => {
            if (response.errors) {
                const values = Object.values(response.errors);
                values.map((error) => {
                    NotifyError(error);
                })
            } else {
                props.onHide();
            }
        });
    }

    const getColumns = () => {
        const onlyColumns = ['date', 'device_name', 'place_name', 'scale_name', 'olig_name', 'olig_sequence', 'types', 'quantity'];
        return columns.filter(c => onlyColumns.includes(c.dataIndex));
    };

    return <>
        <Modal
            title="Восстановить пробирки"
            open={true}
            width={1300}
            onOk={onOK}
            onCancel={props.onHide}
            okText={'OK'}
            cancelText={'Отмена'}
            destroyOnClose={true}
        >
            <Row>
                <Col span={24}>
                    <DeblockTable dataSource={props.data} columns={getColumns()}/>
                </Col>
            </Row>

        </Modal>
    </>

}
