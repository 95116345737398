import {useEffect, useState} from "react";
import api from "../../lib/util";
import {Button, Card, Col, ConfigProvider, DatePicker, Row, Select, Space, Table, Form, Spin} from "antd";
import PageHeader from "../../components/mock/PageHeader";
import './SythList.scss';
import {EditOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import locale from 'antd/lib/locale/ru_RU';
import dayjs from "dayjs";
import usePermissions from "../../lib/Permissions";

export function SynthPlanning(props) {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [devices, setDevices] = useState([]);
    const [deviceFilter, setDeviceFilter] = useState([]);

    const {canEdit} = usePermissions();

    function loadCalendar() {
        if (!loading) {
            setLoading(true);
            let url = `/api/synthesis-device/calendar?start_date=${dayjs(startDate).format('YYYY-MM-DD')}`;
            api.getJSON(url).then((result) => {
                setData(result);
                setDevices(getDevicesFromResult(result));
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    function getDevicesFromResult(result) {
        let devices = [];
        result.forEach((item) => {
            item.devices.forEach((device) => {
                devices.push({value: device.id, label: device.name});
            });
        });
        devices = devices.filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i);
        return devices;
    }

    function setDetails(date, device_id, cycle_id, details) {
        let d = [...data]
        let date_index = d.map((item) => item.date).indexOf(date);
        if (date_index >= 0) {
            let device_index = d[date_index].devices.map((device) => device.id).indexOf(device_id);
            if (device_index >= 0) {
                d[date_index].devices[device_index].cycles[cycle_id].details = details;
                setData(d);
            }
        }
    }

    function setSelectedCycle(date, device_id, device_name, cycle_id) {
        let d = [...data]
        let date_index = d.map((item) => item.date).indexOf(date);
        if (date_index >= 0) {
            d[date_index].selected = {date: date, device_id: device_id, device_name: device_name, cycle_id: cycle_id};
            setData(d);
        }
    }


    async function loadDetails(date, cycle_id, {device_id, device_name}) {
        let details = await api.getJSON(`/api/synthesis-device/calendar/${date}/${device_id}/${cycle_id}`)
        setDetails(date, device_id, cycle_id, details.places)
    }

    function getColumns() {
        return [
            {
                title: 'Оборудование',
                dataIndex: 'name',
                key: 'name',
                width: 100,
                render: (text, row, index) => {
                    if (row.type !== 'row-header') {
                        return <a>{text}</a>;
                    }
                    return {
                        children: text,
                        props: {
                            colSpan: 5,
                        },
                    };
                },
            },
            {title: 'Загрузка', dataIndex: 'payload', key: 'payload', width: 100},
            {title: 'Комментарий', dataIndex: 'comment', key: 'comment', width: '100%'},
        ]
    }

    function getDetailColumns() {
        return [
            {title: '№', dataIndex: 'num', key: 'num', width: 30},
            {title: 'Название', dataIndex: 'name', key: 'name', width: 100},
            {title: 'Последовательность', dataIndex: 'full_sequence', key: 'full_sequence', width: 300, ellipsis: true},
            {
                title: 'Тип', dataIndex: 'types', key: 'types', width: 100, render(value, row, index) {
                    let result = '-';
                    if (Array.isArray(value)) {
                        result = value.map((v) => v.name).join(', ')
                    }
                    return result;
                }
            },
            {title: 'Комментарий', dataIndex: 'comment', key: 'comment', width: 200},
        ]
    }


    function onRowClick(e, row) {
        loadDetails(row.date, row.cycle_id, {device_id: row.device_id, device_name: row.device_name});
        setSelectedCycle(row.date, row.device_id, row.device_name, row.cycle_id);
    }

    function selectedDataRow(record) {
        let dateItem = data.filter(item => item.date === record.date)
        if (dateItem[0] && dateItem[0].selected) {
            if (dateItem[0].selected.date === record.date &&
                dateItem[0].selected.device_id === record.device_id &&
                dateItem[0].selected.cycle_id === record.cycle_id
            ) {
                return 'selected';
            }
        }
    }

    function filterDevices(devices) {
        if (deviceFilter.length) {
            return devices.filter((device) => {
                return deviceFilter.indexOf(device.id) >= 0;
            });
        } else {
            return devices;
        }
    }

    useEffect(() => {
        loadCalendar();
    }, [startDate]);

    return <>
        <PageHeader
            title={props.title || "Планирование синтеза"}
        />
        {loading ? <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "80vh"}}>
            <Spin size="default" spinning={loading} delay={500} style={{marginTop: 100}}/>
        </div> : null}
        {data.map((dateItem, dataIndex) => {
            let data = [];
            let index = 1;
            filterDevices(dateItem.devices).forEach((device) => {
                data.push({id: ++index, name: device.name, type: 'row-header'})
                let cycleId = 0;
                device.cycles.forEach((cycleItem) => {
                    let cycle = {
                        id: ++index,
                        cycle_id: cycleId++,
                        cycle_name: cycleItem.name,
                        device_id: device.id,
                        device_name: device.name,
                        date: dateItem.date,
                        name: cycleItem.name,
                        payload: `${cycleItem.items_count}/${device.sum}`,
                        scales: cycleItem.scale?.length > 0 ? cycleItem.scale.join(', ') : '-'
                    }
                    data.push(cycle);
                })
            })

            function getTitle(date, index) {
                if (index === 0) {
                    return <>
                        <Row style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div>{date}</div>
                            <div>
                                <ConfigProvider locale={locale}>
                                    <DatePicker format={'DD.MM.YYYY'} value={dayjs(startDate)} placeholder={'Дата'}
                                                onChange={(value) => {
                                                    if (!value) {
                                                        value = new Date();
                                                    }
                                                    setStartDate(value);
                                                }}
                                    />
                                </ConfigProvider>
                            </div>
                        </Row>
                        <Row style={{marginTop: 10}}>
                            <Form style={{width: '100%'}}>
                                <Form.Item label={'Оборудование'}>
                                    <Select options={devices} popupMatchSelectWidth={false}
                                            onChange={(value) => setDeviceFilter(value)} value={deviceFilter}
                                            placeholder={'Оборудование'}
                                            allowClear={true} mode={'multiple'}
                                            style={{minWidth: '100%'}}
                                    />
                                </Form.Item>
                            </Form>
                        </Row>
                    </>;
                } else {
                    return date;
                }
            }

            const formatted_date = dayjs(dateItem.date).format('dddd, D MMMM YYYY');

            return <div key={dataIndex}>
                <Row>
                    <Col span={10}>
                        <Card key={dateItem.date} title={getTitle(formatted_date, dataIndex)}>
                            <Table columns={getColumns()}
                                   size={'small'}
                                   rowKey={"id"}
                                   dataSource={data}
                                   pagination={false}
                                   rowClassName={selectedDataRow}
                                   onRow={(record, rowIndex) => ({onClick: (e) => onRowClick(e, record, rowIndex)})}/>
                        </Card>
                    </Col>
                    <Col span={14}>
                        {dateItem.selected ? <div>
                            {dateItem.devices.map((device, deviceIndex) => {
                                if (device.id === dateItem.selected.device_id) {
                                    let details = device.cycles[dateItem.selected.cycle_id].details
                                    if (Array.isArray(details)) {

                                        let title = null;
                                        if (props.mode === 'synth') {
                                            title = <div className={'detail-header'}>
                                                <div className={'item'}>{dateItem.selected.device_name}</div>
                                                <Space size={10}>
                                                    {canEdit && <Link
                                                        to={`/synth/edit/${dateItem.date}/${device.id}/${dateItem.selected.cycle_id}`}
                                                        target={'_blank'}>
                                                        <Button type="primary"><EditOutlined/>Синтез</Button>
                                                    </Link>}
                                                </Space>
                                            </div>
                                        } else {
                                            title = <div className={'detail-header'}>
                                                <div className={'item'}>{dateItem.selected.device_name}</div>
                                                {canEdit && <Link
                                                    to={`/synth-planning/${dateItem.date}/${device.id}/${dateItem.selected.cycle_id}`}
                                                    target={'_blank'}>
                                                    <Button type="primary"><EditOutlined/>Редактировать</Button>
                                                </Link>}
                                            </div>
                                        }
                                        return <div key={deviceIndex}>
                                            <Card title={title}>
                                                <div style={{height: 448, overflowY: 'scroll'}}>
                                                    {details.length > 0 ? details.map((detail, detailIndex) => {
                                                        function addNumber(items) {
                                                            let counter = 1;
                                                            items.forEach((item, index) => {
                                                                item.num = counter++;
                                                            })
                                                            return items;
                                                        }

                                                        return <div key={detailIndex}>
                                                            <div>{detail.program} {'масштаб: ' + detail.scale}</div>
                                                            <Table rowKey={'id'}
                                                                   size={'small'}
                                                                   dataSource={addNumber(detail.items)}
                                                                   pagination={false}
                                                                   columns={getDetailColumns()}/>
                                                        </div>;
                                                    }) : <div>ничего нет</div>}
                                                </div>
                                            </Card>
                                        </div>
                                    } else {
                                        return null;
                                    }
                                }
                            })}
                        </div> : null}
                    </Col>
                </Row>
            </div>
        })}
    </>;
}
