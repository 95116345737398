import {Col, ConfigProvider, Input, Row, Table, Form} from "antd";
import {useEffect, useState} from "react";
import Modal from "antd/es/modal/Modal";
import {OperationsApi, ServerOperations} from "../Operatoins";
import NoFutureDate from "../../../components/NoFutureDate";

import dayjs from "dayjs";
import locale from 'antd/lib/locale/ru_RU';
import {getDefaultOperationColumns} from "../Shared";


export function MassResults(props) {

    const [portionsToSend, setPortionsToSend] = useState([]);
    const [analysisDate, setAnalysisDate] = useState();

    function onOk() {
        OperationsApi.doOperation(ServerOperations.MASS_RESULTS, portionsToSend).then((result) => {
            props.onHide();
        });
    }

    function renderCell(name, value, record, fieldType = 'text') {
        function onChange(val) {
            const index = props.data.indexOf(record);
            record[name] = val;
            updateData(index, record);
        }

        switch (fieldType) {
            case 'text':
                return <Input value={record[name]} onChange={(e) => onChange(e.target.value)}/>
            case 'date':
                return <ConfigProvider locale={locale}>
                    <NoFutureDate format={'DD.MM.YYYY HH:mm'} value={value ? dayjs(value) : null}
                                placeholder={'Дата и время'}
                                showTime
                                onChange={(value) => onChange(value)}/>
                </ConfigProvider>;

            default:
                return <Input value={record[name]} onChange={onChange}/>
        }

    }

    function updateData(index, record) {
        const newData = props.data;
        newData[index] = record;
        const dataToSend = newData.map(item => {
            let result = {
                id: item.id, params: {
                    analysis_date: dayjs(analysisDate).format('YYYY-MM-DD HH:mm'),
                    purity: item.purity || null,
                    nminus1: item.nminus1 || null,
                    nplus1: item.nplus1 || null,
                    additional: item.additional || null,
                    link: item.link || null,
                    conclusion: item.conclusion || null,
                }
            }
            for (let key in result.params) {
                if (result.params[key] === null) {
                    delete result.params[key];
                }
            }
            return result;
        });
        setPortionsToSend(dataToSend);
    }

    function getColumns() {
        let columns = getDefaultOperationColumns();
        return columns.concat([
            {
                title: 'Чистота, %', dataIndex: 'purity', key: 'purity', width: 60,
                render: (value, record) => renderCell('purity', value, record)
            },
            {
                title: 'N-1, %', dataIndex: 'nminus1', key: 'nminus1', width: 60,
                render: (value, record) => renderCell('nminus1', value, record)
            },
            {
                title: 'N+1, %', dataIndex: 'nplus1', key: 'nplus1', width: 60,
                render: (value, record) => renderCell('nplus1', value, record)
            },
            {
                title: 'Дополнительно', dataIndex: 'additional', key: 'additional', width: 260,
                render: (value, record) => renderCell('additional', value, record)
            },
            {
                title: 'Ссылка на результат анализа', dataIndex: 'link', key: 'link', width: 260,
                render: (value, record) => renderCell('link', value, record)
            },
            {
                title: 'Заключение', dataIndex: 'conclusion', key: 'conclusion', width: 260,
                render: (value, record) => renderCell('conclusion', value, record)
            }
        ]);
    }

    function OKDisabled() {
        return !analysisDate;
    }

    useEffect(() => {
        let record = props.data[0] || false;
        if (record) {
            updateData(0, record);
        }
    }, [props.data, analysisDate])

    return <Modal title="Внесение результатов"
                  width={"90%"}
                  onOk={onOk}
                  okButtonProps={{disabled: OKDisabled()}}
                  onCancel={props.onHide}
                  open={true}
                  okText={'OK'}
                  cancelText={'Отмена'}
                  destroyOnClose={true}>
        <Row>
            <Col span={24}>
                <Form.Item label={'Дата анализа'} initialValue={analysisDate}>
                    <ConfigProvider locale={locale}>
                        <NoFutureDate format={'DD.MM.YYYY HH:mm'} value={analysisDate ? dayjs(analysisDate) : null}
                                    placeholder={'Дата и время'}
                                    showTime
                                    onChange={(value) => setAnalysisDate(value)}/>
                    </ConfigProvider>
                </Form.Item>
            </Col>
        </Row>
        <Row>

            <Col span={24}>
                <Table size={'small'} rowKey={"id"} dataSource={props.data} columns={getColumns()}
                    scroll={{x: 'max-content'}}
                />
            </Col>
        </Row>
    </Modal>

}
