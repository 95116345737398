import {Col, Input, Modal, Row, Table} from "antd";
import {useEffect, useState} from "react";
import api from "../../../lib/util";
import {getDefaultOperationColumns} from "../Shared";

export function DescribePortion(props) {

    const [portionsToSend, setPortionsToSend] = useState([]);

    function onOk() {
        const prefixes = portionsToSend.map((portion) => ({
            id: portion.id, prefix: portion.prefix,
            comment: portion.comment
        }));
        api.postJSON('/api/operations/prefix', prefixes).then((result) => {
            props.onHide();
        });
    }

    function updateData(index, record) {
        const newData = portionsToSend;
        newData[index] = record;
        setPortionsToSend([...newData]);
    }

    function renderPrefix(value, record) {
        function onChange(e) {
            const index = props.data.indexOf(record);
            record['prefix'] = e.target.value;
            updateData(index, record);
        }

        return <Input value={record['prefix']} onChange={onChange}/>
    }

    function renderComment(value, record) {
        function onChange(e) {
            const index = props.data.indexOf(record);
            record['comment'] = e.target.value;
            updateData(index, record);
        }
        return <Input value={record['comment']} onChange={onChange}/>
    }

    function getColumns() {
        let columns = getDefaultOperationColumns();
        columns = columns.concat([
            {
                title: 'Префикс', dataIndex: 'prefix', key: 'prefix', width: 50,
                render: (value, record) => renderPrefix(value, record)
            },
            {
                title: 'Комментарий', dataIndex: 'comment', key: 'comment', width: 250,
                render: (value, record) => renderComment(value, record)
            }
        ]);
        return columns;
    }

    function OKDisabled() {
        return false;
    }

    useEffect(() => {
        setPortionsToSend(props.data);
    }, [props.data]);

    return <Modal title="Сведения о пробирке" width={1400} onOk={onOk}
                  okButtonProps={{disabled: OKDisabled()}}
                  onCancel={props.onHide}
                  open={true}
                  okText={'OK'}
                  cancelText={'Отмена'}
                  destroyOnClose={true}>
        <Row>
            <Col span={24}>
                <Table rowKey={"id"} size={'small'} dataSource={portionsToSend} columns={getColumns()}/>
            </Col>
        </Row>

    </Modal>

}
