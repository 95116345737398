import {JoinPortions} from "./JoinPortions";
import api from "../../../lib/util";
import {useState} from "react";

export function JoinColumnsManager(props) {

    const [joinResultData, setJoinResultData] = useState([]);

    function onOK() {
        const ids = props.data.map(item => item.id)
        api.postJSON('/api/portion/merge', {ids: ids}).then(response => {
            props.onHide();
        })
    }

    return <JoinPortions data={props.data} resultData={joinResultData} onOK={onOK} onHide={props.onHide}/>;

}

