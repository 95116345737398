import Default from "./Default";
import SynthesisDetails from "./Synth/SynthesisDetails";
import {SynthEnding} from "./Synth/SynthEnding";
import {SynthPlanning} from "./Synth/SynthPlannig";
import OligonucleotideEdit from "./Oligonucleotide/OligonucleotideEdit";
import OligonucleotideCreate from "./Oligonucleotide/OligonucleotideCreate";
import ClientEdit from "./Client/ClientEdit";
import OrderEdit from "./Order/OrderEdit";
import PackagingEdit from "./Packaging/PackagingEdit";
import ShipmentEdit from "./Shipment/ShipmentEdit";
import ShipmentDetails from "./Shipment/ShipmentDetails";
import Main from "./Main/Main";
import ClientList from "./Client/ClientList";
import OrderList from "./Order/OrderList";
import PlanningList from "./Planning/PlanningList";
import SynthList from "./Synth/SynthList";
import DeblockManager from "./Stages/Deblock";
import Click from "./Stages/Click";
import Acylation from "./Stages/Acylation";
import Chromatography from "./Stages/Chromatography";
import Cartridge from "./Stages/Cartridge";
import Paag from "./Stages/Paag";
import DistrAndCleaning from "./Stages/DistrAndCleaning";
import Mass from "./Stages/Mass";
import FinalControl from "./Stages/FinalControl";
import PackagingList from "./Packaging/Packaging";
import Shipment from "./Shipment/Shipment";
import Defect from "./Stages/Defect";
import OligonucleotideList from "./Oligonucleotide/OligonucleotideList";
import MaterialList from "./Material/MaterialList";
import Portions from "./Stages/Portions";
import Access from "./Access/Access";
import {Button} from "antd";
import {Logout} from "../lib/util";
import React from "react";
import PlaceList from "./Place/PlaceList";
import PortionHistory from "./Stages/PortionHistory";

export const menuItems = [
    {
        id: 'main',
        url: '/main',
        label: 'Главная',
        permission: '',
        breadcrumbName: 'main',
        component: Main
    },

    {
        id: 'client',
        url: '/client',
        label: 'Клиенты',
        permission: 'client.view',
        breadcrumbName: 'clients',
        component: ClientList
    },
    {
        id: 'order',
        url: '/order',
        label: 'Заказы',
        permission: 'order.view',
        breadcrumbName: 'orders',
        component: OrderList
    },
    {
        id: 'planning', url: '/planning', label: 'Передача в производство', permission: 'production_planning.view',
        breadcrumbName: 'planning', component: PlanningList
    },
    {
        id: 'synth-planning',
        url: '/synth-planning',
        label: 'Планирование синтеза',
        permission: 'synthesis_planning.view',
        breadcrumbName: 'synth-planning',
        component: SynthPlanning
    },
    {
        id: 'synth',
        url: '/synth',
        label: 'Синтез',
        permission: 'synthesis.view',
        breadcrumbName: 'synth',
        component: SynthList
    },
    {
        id: 'deblock',
        url: '/deblock',
        label: 'Деблокирование',
        permission: 'deblock.view',
        breadcrumbName: 'deblock',
        component: DeblockManager
    },
    {
        id: 'click',
        url: '/click',
        label: 'Клик',
        permission: 'click.view',
        breadcrumbName: 'click',
        component: Click
    },
    {
        id: 'acylation',
        url: '/acylation',
        label: 'Ацилирование',
        permission: 'acylation.view',
        breadcrumbName: 'acylation',
        component: Acylation
    },
    {
        id: 'chromotography', url: '/chromotography', label: 'Хроматография', permission: 'vzh.view',
        breadcrumbName: 'chromotography', component: Chromatography
    },
    {
        id: 'cartridge',
        url: '/cartridge',
        label: 'Картридж',
        permission: 'cartridge.view',
        breadcrumbName: 'cartridge',
        component: Cartridge
    },
    {
        id: 'paag',
        url: '/paag',
        label: 'Форез',
        permission: 'electroforez.view',
        breadcrumbName: 'paag',
        component: Paag
    },
    {
        id: 'distrib',
        url: '/distrib',
        label: 'Распределитель очистки',
        permission: 'distr.view',
        breadcrumbName: 'distrib',
        component: DistrAndCleaning
    },
    {
        id: 'mass',
        url: '/mass',
        label: 'Масс-спектрометрия',
        permission: 'mass_spectrometry.view',
        breadcrumbName: 'mass',
        component: Mass
    },
    {
        id: 'final',
        url: '/final',
        label: 'Финальный контроль',
        permission: 'final_control.view',
        breadcrumbName: 'final',
        component: FinalControl
    },
    {
        id: 'packaging',
        url: '/packaging',
        label: 'Фасовка',
        permission: 'packing.view',
        breadcrumbName: 'packaging',
        component: PackagingList
    },
    {
        id: 'shipment',
        url: '/shipment',
        label: 'Заказы и отгрузки',
        permission: 'shipping.view',
        breadcrumbName: 'shipment',
        component: Shipment
    },
    {
        id: 'defect',
        url: '/defect',
        label: 'Бракованные пробирки',
        permission: 'defect.view',
        breadcrumbName: 'defect',
        component: Defect
    },
    {
        id: 'oligs',
        url: '/oligs',
        label: 'Справочник олигонуклеотидов',
        permission: 'oligs.view',
        breadcrumbName: 'oligs',
        component: OligonucleotideList
    },
    {
        id: 'material',
        url: '/material',
        label: 'Материалы',
        permission: 'materials.view',
        breadcrumbName: 'material',
        component: MaterialList,
    },
    {
        id: 'portion',
        url: '/portion',
        label: 'Пробирки в производстве',
        permission: 'portions.view',
        breadcrumbName: 'portion',
        component: Portions
    },
    {
        id: 'access',
        url: '/access',
        label: 'Пользователи',
        permission: 'users.view',
        breadcrumbName: 'access',
        component: Access
    },
    {id: 'logout', url: '', label: 'Выход', render: () => <Button onClick={Logout}>Выход</Button>},
];

// роуты которые не вошли в меню
export const routes = [
    {url: '/', breadcrumbName: 'Default', component: Default},
    {url: '/synth/edit/:date/:device_id/:cycle_id', breadcrumbName: 'test', component: SynthesisDetails},
    {url: '/synth-end/:date/:device_id/:cycle_id', breadcrumbName: 'SynthEnding', component: SynthEnding},
    {url: '/synth-planning/:date/:device_id/:cycle_id', breadcrumbName: 'SynthPlanning', component: SynthPlanning},
    {url: '/oligs/edit/:id', breadcrumbName: 'OligonucleotideEdit', component: OligonucleotideEdit},
    {url: '/oligs/create', breadcrumbName: 'OligonucleotideCreate', component: OligonucleotideCreate},
    {url: '/client/edit/:id', breadcrumbName: 'ClientCreate', component: ClientEdit},
    {url: '/client/edit', breadcrumbName: 'ClientCreate', component: ClientEdit},
    {url: '/order/edit/:id', breadcrumbName: 'OrderEdit', component: OrderEdit},
    {url: '/order/create', breadcrumbName: 'OrderCreate', component: OrderEdit},
    {url: '/packaging/edit/:id', breadcrumbName: 'PackagingEdit', component: PackagingEdit},
    {url: '/packaging/edit', breadcrumbName: 'PackagingEdit', component: PackagingEdit},
    {url: '/shipment/edit', breadcrumbName: 'ShipmentEdit', component: ShipmentEdit},
    {url: '/shipment/view/:id', breadcrumbName: 'ShipmentView', component: ShipmentDetails},
    {url: '/synth-planning/:date/:id/:cycle_id', breadcrumbName: 'Place', component: PlaceList},
    {url: '/portion/:id', breadcrumbName: 'PortionHistory', component: PortionHistory},
];
