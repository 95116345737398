import PropTypes from "prop-types";
import {Col, Row, Table} from "antd";
import React, {useEffect} from "react";
import dayjs from "dayjs";

export function Fractions(props) {

    const renderMeasurement = (value, fieldName) => {
        if (value.measurements) {
            const v = value.measurements[fieldName] || '';
            if (fieldName === 'analysis_date') {
                return v ? dayjs(v).format('DD.MM.YYYY') : '';
            } else {
                return v;
            }
        }
    }


    const columns = [
        // {title: 'ID', dataIndex: 'id', width: 50, fixed: 'left' },
        {
            title: '№', dataIndex: 'index', width: 50, fixed: 'left', render: (value, record, index) => {
                return <span>{index + 1}</span>
            }
        },
        {title: 'Кодировка фракций - Коллектор', dataIndex: 'collector'},
        {title: 'Позиция фракций', dataIndex: 'code'},
        {
            title: 'Дата передачи/Номер планшета', dataIndex: 'date', render: (value, record) => {
                const date = record.started_at ? dayjs(record.started_at).format('DD.MM.YYYY') : '-';
                const tablet = record.tablet_number || '-';
                return `${date} / ${tablet}`;
            }
        },
        {title: 'Позиция в планшете', dataIndex: 'tablet_position'},
        {
            title: 'Результаты масс',
            children: [
                {
                    title: 'Дата анализа',
                    dataIndex: 'measurements',
                    render: (value, record) => {
                        return renderMeasurement(record, 'analysis_date')
                    }
                },
                {
                    title: 'Чистота, %',
                    dataIndex: 'measurements',
                    render: (value, record) => renderMeasurement(record, 'purity')
                },
                {
                    title: 'N-1, %',
                    dataIndex: 'measurements',
                    render: (value, record) => renderMeasurement(record, 'nminus1')
                },
                {
                    title: 'N+1, %',
                    dataIndex: 'measurements',
                    render: (value, record) => renderMeasurement(record, 'nplus1')
                },
                {
                    title: 'Дополнительно',
                    dataIndex: 'measurements',
                    render: (value, record) => renderMeasurement(record, 'additional')
                },
                {
                    title: 'Ссылка на результат',
                    dataIndex: 'measurements',
                    render: (value, record) => renderMeasurement(record, 'link')
                },
                {
                    title: 'Заключение',
                    dataIndex: 'measurements',
                    render: (value, record) => renderMeasurement(record, 'conclusion')
                },
            ]
        },
    ];

    useEffect(() => {
        props.onSelectFractions([]);
    }, [props.fractions]);

    return <Row>
        <Col span={24}>
            <Table columns={columns} dataSource={props.fractions}
                   size={'small'}
                   rowKey={'id'}
                   pagination={false}
                   scroll={{y: 250}}
                   rowSelection={{
                       type: 'checkbox',
                       selectedRowKeys: props.selectedFractions.map(fraction => fraction.id),
                       onChange: (selectedRowKeys, selectedRows) => {
                           props.onSelectFractions(selectedRows);
                       },
                   }}
            />
        </Col>
    </Row>
}

Fractions.propTypes = {
    fractions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        collector: PropTypes.string,
        code: PropTypes.string.isRequired,
    })).isRequired,
    selectedFractions: PropTypes.array.isRequired,
    onSelectFractions: PropTypes.func.isRequired,
}

Fractions.defaultProps = {
    fractions: [],
    selectedFractions: [],
}

export default Fractions;
