import {notification} from 'antd';

function Notify(type, title, message) {
    const config = {
        message: title,
        description: message,
        placement: 'topRight',
        duration: 3,
    };
    switch (type.toLowerCase()) {
        case 'error':
            notification.error(config);
            break;
        case 'success':
            notification.success(config);
            break;
        case 'info':
        default:
            notification.info(config);
            break;
    }
}

function NotifyError(message) {
    Notify('error', null, message);
}

function NotifyInfo(message) {
    Notify('info', null, message);
}

function NotifySuccess(message) {
    Notify('success', null, message);
}

export {NotifyError, NotifyInfo, NotifySuccess};
