import {Checkbox, Radio, Space} from "antd";
import StageManager from "./StageManager";
import {useEffect, useState} from "react";
import {
    evaporationQueueColumns,
    filterOperationOptions,
    getDeblockColumns,
    getEvaporationColumns, getMassResultsColumns,
    getRemoveFromCarrierColumns,
    mapDataFunction,
    operationMarkup as oms,
    operations as ops, useColumns,
} from "./Shared";
import QueueTable from "./QueueTable";
import {ServerOperations, Stages} from "./Operatoins";
import dayjs from "dayjs";

const visibleModes = {
    Waiting: 1,
    NonCompletedDeblock: 2,
    NonCompletedUpar: 3,
}

const none = 0b0000;
const withDeblock = 0b1000;
const withoutDeblock = 0b0100;
const withRemove = 0b0010;
const withoutRemove = 0b0001;

let chosen = {};

// четная позиция - тип операции, нечетная - статус
chosen[none] = [1, 5, 5, 5];
chosen[withDeblock] = [1, 3, 1, 5, 5, 5];
chosen[withoutDeblock] = [1, 1, 1, 5, 5, 5];
chosen[withRemove] = [1, 5, 2, 3, 5, 5];
chosen[withoutRemove] = [1, 5, 2, 1, 5, 5];
chosen[withoutDeblock | withoutRemove] = [1, 1, 1, 5, 2, 1, 5, 5];
chosen[withDeblock | withRemove] = [1, 3, 1, 5, 2, 3, 5, 5];
chosen[withoutDeblock | withRemove] = [1, 1, 1, 5, 2, 3, 5, 5];
chosen[withDeblock | withoutRemove] = [1, 3, 1, 5, 2, 1, 5, 5];

const visibleOptions = [
    {label: 'Ожидание', value: visibleModes.Waiting},
    {label: 'Незавершенный деблок', value: visibleModes.NonCompletedDeblock},
    {label: 'Незавершенное упаривание', value: visibleModes.NonCompletedUpar},
]

const queueColumns = [
    {id: 1, width: 60, title: 'Очередь', dataIndex: 'queue'},
    {
        id: 3, width: 150, title: 'начало', dataIndex: 'started_at', render: function (v) {
            return dayjs(v).format('DD.MM.YYYY HH:mm')
        }
    },
    {id: 4, width: 150, title: 'Реактив', dataIndex: 'reactive'},
    {id: 5, width: 50, title: 't', dataIndex: 'temperature'},
    {id: 6, width: 80, title: 'Время', dataIndex: 'time'},
    {id: 7, width: 80, title: 'Фаза', dataIndex: 'phase'},
];

export default function Deblock() {

    const [visibleMode, setVisibleMode] = useState(visibleModes.Waiting);
    const [queueIds, setQueueIds] = useState(null);
    const [deblockFilter, setDeblockFilter] = useState([]);
    const [removeFilter, setRemoveFilter] = useState([]);
    const {columns, filters} = useColumns();

    function getOperations() {
        if (visibleMode === visibleModes.Waiting) {
            return [
                [oms[ops.JOIN], oms[ops.DEBLOCK], oms[ops.EVAPORATION], oms[ops.OE_MEASURING], oms[ops.ADD_PREFIX], oms[ops.DEFECT]],
                [oms[ops.SPLIT], oms[ops.REMOVE_FROM_CARRIER], null, oms[ops.TO_MASS_SPECTR], null, oms[ops.NEXT_STAGE]],
            ];
        }
        if (visibleMode === visibleModes.NonCompletedDeblock) {
            return [
                [oms[ops.DEBLOCK]],
            ];
        }
        if (visibleMode === visibleModes.NonCompletedUpar) {
            return [
                [oms[ops.EVAPORATION_COMPLETE]],
            ];
        }

        return null;
    }

    function getQueryParams() {
        let result = [];
        if (queueIds) {
            for (let id of queueIds) {
                result.push(`queue_id[]=${id}`);
            }
        }
        if (visibleMode === visibleModes.Waiting) {
            let mask = deblockFilter.reduce((a, b) => a | b, 0) | removeFilter.reduce((a, b) => a | b, 0);
            if (chosen[mask]) {
                let values = chosen[mask];
                for (let i = 0; i < values.length; i += 2) {
                    result.push(`operations[${i / 2}][id]=${values[i]}&operations[${i / 2}][status]=${values[i + 1]}`);
                }
            }
        } else if (visibleMode === visibleModes.NonCompletedDeblock) {
            result.push(`operations[0][id]=${ServerOperations.DEBLOCK}&operations[0][status]=` + filterOperationOptions.hasNonCompleted);
        } else if (visibleMode === visibleModes.NonCompletedUpar) {
            result.push(`operations[0][id]=${ServerOperations.EVAPORATION}&operations[0][status]=` + filterOperationOptions.hasNonCompleted);
        }
        return result;
    }

    function onFilterRows(queueId) {
        setQueueIds(queueId);
    }

    function getExtraFilter(onChange, disabled = false) {
        return <>
            <Radio.Group
                options={visibleOptions} size={'small'}
                onChange={e => {
                    setVisibleMode(e.target.value);
                    if (e.target.value === visibleModes.Waiting) {
                        setDeblockFilter([]);
                        setRemoveFilter([]);
                    }
                    setQueueIds(null);
                    if (onChange) {
                        onChange();
                    }
                }}
                value={visibleMode}
                optionType="button"
                buttonStyle="solid"
                disabled={disabled}
            />
            {visibleMode === visibleModes.Waiting ?
                <Space size={20} style={{marginLeft: 50}}>
                    <Checkbox.Group options={[
                        {value: withDeblock, label: 'с деблоком'},
                        {value: withoutDeblock, label: 'без деблока'},
                    ]} onChange={(e) => {
                        if (deblockFilter.length > 0 && e.length > 0) {
                            e = e.filter(f => !deblockFilter.includes(f));
                        }
                        setDeblockFilter(e);
                    }} value={deblockFilter} disabled={disabled}/>
                    <Checkbox.Group options={[
                        {value: withRemove, label: 'со снятием'},
                        {value: withoutRemove, label: 'без снятия'}
                    ]} onChange={(e) => {
                        if (removeFilter.length > 0 && e.length > 0) {
                            e = e.filter(f => !removeFilter.includes(f));
                        }
                        setRemoveFilter(e);
                    }} value={removeFilter} disabled={disabled}/>
                </Space> : null}
            {visibleMode === visibleModes.NonCompletedDeblock ?
                <QueueTable columns={queueColumns} operationId={ServerOperations.DEBLOCK}
                            stageId={Stages.DEBLOCK} onChange={onFilterRows}/> : null}

            {visibleMode === visibleModes.NonCompletedUpar ?
                <QueueTable columns={evaporationQueueColumns} operationId={ServerOperations.EVAPORATION}
                            stageId={Stages.DEBLOCK} onChange={onFilterRows}/> : null}
        </>
    }

    const restColumns = columns.concat(getDeblockColumns(), getRemoveFromCarrierColumns(), getEvaporationColumns(),
        getMassResultsColumns());

    useEffect(() => {
        getQueryParams();
    }, [deblockFilter, removeFilter, visibleMode, queueIds]);

    const extraFilter = (data) => {
        data = mapDataFunction(data);
        return data;
    }


    return <>
        <StageManager
            title={'Деблокирование'}
            currentStageId={Stages.DEBLOCK}
            operationButtons={getOperations()}
            columns={restColumns}
            mapFucntion={extraFilter}
            url={'/api/deblock'}
            queryParams={getQueryParams()}
            filter={filters}
            extraFilter={getExtraFilter}
        />
    </>;

}
