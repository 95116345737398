import FileList from "./FileList";
import PropTypes from "prop-types";
import {useEffect} from "react";

const {useState} = require("react");
const {NotifyInfo, NotifyError} = require("../../lib/notify");

function FileIds(props) {

    const [files, setFiles] = useState();

    function onAddFile(fileId) {
        const newFiles = files ? [...files, fileId] : [fileId];
        setFiles(newFiles);
        props.onChange(newFiles);
        NotifyInfo('Файл загружен');
    }

    useEffect(() => {
        setFiles(props.ids);
    }, [props.ids]);

    function onRemoveFile(id) {
        const newFiles = files.filter(fileId => fileId !== id);
        setFiles(newFiles);
        props.onChange(newFiles);
        NotifyInfo('Файл удален');
    }

    function onError(error) {
        NotifyError('Ошибка загрузка файла');
    }

    return <FileList files={files} onAdd={onAddFile} onRemove={onRemoveFile} onError={onError}/>;

}

FileIds.ids = PropTypes.array.isRequired;
FileIds.onChange = PropTypes.func.isRequired;

export default FileIds;

