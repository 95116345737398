import {Col, Row, Select, Space, Table, Modal, ConfigProvider} from "antd";
import {useEffect, useState} from "react";
import locale from 'antd/lib/locale/ru_RU';
import {getDefaultOperationColumns} from "../Shared";
import {OperationsApi, ServerOperations} from "../Operatoins";
import MaterialParties from "../../Material/MaterialParties";
import NoFutureDate from "../../../components/NoFutureDate";
import TextArea from "antd/lib/input/TextArea";
import dayjs from "dayjs";

export function Deblock(props) {

    const [id, setId] = useState(null);
    const [temperature, setTemperature] = useState();
    const [time, setTime] = useState();
    const [phase, setPhase] = useState();
    const [started, setStarted] = useState();
    const [finished, setFinished] = useState();
    const [queueId, setQueueId] = useState();
    const [materials, setMaterials] = useState();
    const [reactive, setReactive] = useState();
    const [comment, setComment] = useState();

    const [portionsToSend, setPortionsToSend] = useState();

    function onOk() {
        const materialIds = (materials && materials.length && materials.map(item => item.id)) || [];
        if (queueId) {
            OperationsApi.editOperation(ServerOperations.DEBLOCK, queueId, portionsToSend, {materials: materialIds}).then((result) => {
                props.onHide();
            });
        } else {
            OperationsApi.doOperation(ServerOperations.DEBLOCK, portionsToSend, {materials: materialIds}).then((result) => {
                props.onHide();
            });
        }
    }

    const reactiveOptions = [
        {id: 'NH3', value: 'NH3'},
        {id: 'MeNH2', value: 'MeNH2'},
        {id: 'AMA', value: 'AMA'},
        {id: 'TAMRA', value: 'TAMRA'},
        {id: 'NH4F', value: 'NH4F'},
        {id: 'TEA THF', value: 'TEA THF'},
    ];

    const temperatureOptions = [
        {id: 'комн.', value: 'комн.'},
        {id: '40 °С', value: '40 °С'},
        {id: '45 °С', value: '45 °С'},
        {id: '50 °С', value: '50 °С'},
        {id: '55 °С', value: '55 °С'},
        {id: '60 °С', value: '60 °С'},
        {id: '65 °С', value: '65 °С'},
        {id: '70 °С', value: '70 °С'},
        {id: '75 °С', value: '75 °С'},
        {id: '80 °С', value: '80 °С'}
    ];

    const timeOptions = [
        {id: '1 ч', value: '1 ч'},
        {id: '2 ч', value: '2 ч'},
        {id: '3 ч', value: '3 ч'},
        {id: '8 ч', value: '8 ч'},
        {id: '12 ч', value: '12 ч'},
        {id: '15 ч', value: '15 ч'},
        {id: '24 ч', value: '24 ч'},
        {id: '36 ч', value: '36 ч'},
    ];

    const phaseOptions = [
        {id: 'газ', value: 'газ'},
        {id: 'жидкость', value: 'жидкость'},
    ];

    function handleChangeReactive(value) {
        setReactive(value);
    }

    function handleChangeTemperature(value) {
        setTemperature(value);
    }

    function handleChangeTime(value) {
        setTime(value);
    }

    function handleChangePhase(value) {
        setPhase(value);
    }

    function updateData() {
        const dataToSend = props.data.map(item => ({
            id: item.id, params: {
                reactive, temperature, time, phase, started_at: started, finished_at: finished,
                comment
            }
        }));
        setPortionsToSend(dataToSend);
    }

    function OKDisabled() {
        return !(reactive && temperature && time && phase && started);
    }

    useEffect(() => {
        updateData();
    }, [reactive, materials, temperature, time, phase, started, finished, comment])

    useEffect(() => {
        if (props.data[0].deblock?.pivot?.params) {
            if (!props.data[0].deblock.pivot.params.finished_at) {
                setId(props.data[0].deblock.pivot.id);
                setPhase(props.data[0].deblock.pivot.params.phase);
                setReactive(props.data[0].deblock.pivot.params.reactive);
                setTime(props.data[0].deblock.pivot.params.time);
                setTemperature(props.data[0].deblock.pivot.params.temperature);
                setComment(props.data[0].deblock.pivot.params.comment);
                setStarted(props.data[0].deblock.pivot.params.started_at);
                setFinished(props.data[0].deblock.pivot.params.finished_at);
                setQueueId(props.data[0].deblock.pivot.portion_to_operation_id);
                setMaterials(props.data[0].deblock.params.materials);
            }
        }
    }, [props.data]);

    return <Modal
        title="Деблокирование"
        open={true}
        width={1300}
        okButtonProps={{disabled: OKDisabled()}}
        onOk={onOk}
        onCancel={props.onHide}
        okText={'OK'}
        cancelText={'Отмена'}
        destroyOnClose={true}
    >
        <Row>
            <Col span={12}>
                Фактическое время и дата начала деблока
                <ConfigProvider locale={locale}>
                    <NoFutureDate format={'DD.MM.YYYY HH:mm'} value={started ? dayjs(started) : null}
                                  placeholder={'Дата и время'}
                                  showTime
                                  onChange={(value) => setStarted(value)}
                                  style={{marginLeft: '10px'}}
                    />
                </ConfigProvider>
            </Col>
            <Col span={12}>
                Фактическое время и дата завершения деблока
                <ConfigProvider locale={locale}>
                    <NoFutureDate format={'DD.MM.YYYY HH:mm'} value={finished ? dayjs(finished) : null}
                                  placeholder={'Дата и время'}
                                  showTime
                                  onChange={(value) => setFinished(value)}
                                  style={{marginLeft: '10px'}}
                    />
                </ConfigProvider>
            </Col>
        </Row>
        <Row>
            <Col span={24} style={{padding: '10px'}}>
                <Space direction="vertical" style={{width: '130px'}}>
                    <div>Реактив</div>
                    <Select value={reactive} options={reactiveOptions} onChange={handleChangeReactive}/>
                </Space>
                <Space direction="vertical" style={{width: '130px', marginLeft: 10}}>
                    <div>Температура</div>
                    <Select value={temperature} options={temperatureOptions} onChange={handleChangeTemperature}/>
                </Space>
                <Space direction="vertical" style={{width: '130px', marginLeft: 10}}>
                    <div>Время</div>
                    <Select value={time} options={timeOptions} onChange={handleChangeTime}/>
                </Space>
                <Space direction="vertical" style={{width: '130px', marginLeft: 10}}>
                    <div>Фаза</div>
                    <Select value={phase} options={phaseOptions} onChange={handleChangePhase}/>
                </Space>
                <Space direction="vertical" style={{width: '575px', marginLeft: 10}}>
                    <div>Комментарий</div>
                    <TextArea value={comment} onChange={(e) => setComment(e.target.value)}/>
                </Space>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <MaterialParties onChange={(v) => {
                    setMaterials(v);
                }} initialValue={materials}/>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <h3>Пробирки</h3>
                <Table rowKey="id" size={'small'} dataSource={props.data} columns={getDefaultOperationColumns()}/>
            </Col>
        </Row>
    </Modal>

}
