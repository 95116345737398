import {Button, Col, ConfigProvider, Input, Modal, Radio, Row, Select, Space, Spin, Table, Tabs} from "antd";
import NoFutureDate from "../../../components/NoFutureDate";
import {useEffect, useRef, useState} from "react";
import dayjs from "dayjs";
import locale from 'antd/lib/locale/ru_RU';
import {getDefaultOperationColumns, mapDataFunction} from "../Shared";
import {OperationsApi, ServerOperations} from "../Operatoins";
import FileIds from "../../../components/FileList/FileIds";
import MaterialParties from "../../Material/MaterialParties";
import {DeleteOutlined, LeftOutlined, PlusOutlined, RightOutlined} from "@ant-design/icons";
import RangeSelector from "../../../components/RangeSelector";
import Fractions from "../../../components/Fractions";
import Tubes from "../../../components/Tubes";
import {NotifyError, NotifySuccess} from "../../../lib/notify";
import ChromeTablet from "./Forms/ChromeTablet";
import api from "../../../lib/util";
import PropTypes from "prop-types";

const {TabPane} = Tabs;

const initialResultProbs = [
    {id: 1, prefix: '', comment: ''},
];

export function extractDiapason(fractions, start, end) {
    let startIndex = fractions.indexOf(start);
    let endIndex = fractions.indexOf(end);
    return fractions.slice(startIndex, endIndex + 1);
}

export function Chromatography(props) {

    const [id, setId] = useState(null);
    const [basis, setBasis] = useState();
    const [chromatograph, setChromatograph] = useState();
    const [column, setColumn] = useState();
    const [type, setType] = useState();
    const [method, setMethod] = useState();
    const [pik, setPik] = useState();
    const [comment, setComment] = useState();
    const [reactive, setReactive] = useState();
    const [startedAt, setStartedAt] = useState();
    const [finishedAt, setFinishedAt] = useState();
    const [queueId, setQueueId] = useState();
    const [portionsToSend, setPortionsToSend] = useState();
    const [files, setFiles] = useState([]);
    const [materials, setMaterials] = useState([]);

    const [chromeResult, setChromeResult] = useState(3);

    const [collector, setCollector] = useState();

    const [devices, setDevices] = useState([]);
    const [currentDevice, setCurrentDevice] = useState();

    const [diapasonStart, setDiapasonStart] = useState();
    const [diapasonEnd, setDiapasonEnd] = useState();

    const [resultProbs, setResultProbs] = useState(initialResultProbs);

    const [fractions, setFractions] = useState([]);
    const [selectedFractions, setSelectedFractions] = useState([]);

    const [tubes, setTubes] = useState([]);
    const [selectedTubes, setSelectedTubes] = useState([]);
    const [showChromeTablet, setShowChromeTablet] = useState(false);
    const [portionId, setPortionId] = useState();
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const loaded = useRef(false);

    function onOk(close = true) {

        const ids = materials.map(m => m.id);

        let params = {
            materials: ids,
            result_type: chromeResult,
            tubes: (chromeResult === 1) ? resultProbs : tubes
        }

        if (queueId) {
            setSaving(true);
            OperationsApi.editOperation(ServerOperations.CHROMATOGRAPHY_VZH, queueId,
                portionsToSend, params,
            ).then((result) => {
                NotifySuccess('Сохранено');
                const params = JSON.parse(result?.portions[0]?.operations[0]?.pivot.params);
                if (params?.fractions) {
                    setFractions(params.fractions);
                    setSelectedFractions([]);
                }
                setSaving(false);
                if (close) {
                    props.onHide();
                } else {
                    load();
                }
            });
        } else {
            OperationsApi.doOperation(ServerOperations.CHROMATOGRAPHY_VZH,
                portionsToSend, params).then((result) => {
                const _id = result?.portions[0]?.operations[0]?.pivot?.id;
                const _queueId = result?.queue_id;
                setId(_id);
                setQueueId(_queueId);
                const params = JSON.parse(result?.portions[0]?.operations[0]?.pivot.params);
                if (params?.fractions) {
                    setFractions(params.fractions);
                    setSelectedFractions([]);
                }
                setSaving(false);
                NotifySuccess('Сохранено');
                if (close) {
                    props.onHide();
                } else {
                    load();
                }
            });
        }
    }

    function loadDevices() {
        api.getJSON('/api/chromatography/devices').then((result) => {
            setDevices(result);
        });
    }

    function handleChangeBasis(value) {
        setBasis(value);
    }

    function handleChangeChromatograph(value) {
        setChromatograph(value);
    }

    function handleChangeColumn(value) {
        setColumn(value);
    }

    function handleChangeMethod(e) {
        setMethod(e.target.value);
    }

    function updateData() {
        const dataToSend = data.map(item => ({
            id: item.id,
            params: {
                started_at: startedAt, finished_at: finishedAt,
                type, basis, chromatograph, column,
                method, pik, comment, reactive,
                files, fractions, chromeResult,
                resultProbs, tubes,
            }
        }));
        setPortionsToSend(dataToSend);
    }

    function sendToMassDisabled() {
        return fractions.some(item => !item.portion_id);
    }

    function OKDisabled() {
        // Чтобы не запутаться, условия следует читать так:
        // Если нет одно из полей (type, basis, chromatograph, column, method, startedAt),
        // вернуть true (кнопка OK будет выключена)

        // А если заполнено finishedAt (попытка завершить хроматографию) и chromeResult === 1,
        // то должны быть заполнены все поля prefix resultProbs, и их должно быть больше 0,
        // Если chromeResult === 2, то должны быть заполнены все поля tubes.

        let result = !(type && basis && chromatograph && column && method && startedAt);
        if (finishedAt) {
            if (chromeResult === 1) {
                result = result || resultProbs.length === 0 || resultProbs.some(item => !item.prefix);
            } else if (chromeResult === 2) {
                result = result || tubes.length === 0;
            }
        }
        return result;
    }

    useEffect(() => {
        updateData();
    }, [
        type, basis, chromatograph, pik, comment, reactive, column, method, files, startedAt, finishedAt,
        chromeResult, resultProbs, tubes, fractions
    ]);

    useEffect(() => {
        if (resultProbs.length === 0 && chromeResult === 3) {
            setResultProbs([
                {id: 1, prefix: '', comment: ''},
                {id: 2, prefix: '', comment: ''},
            ]);
        } else if (resultProbs.length === 1 && chromeResult === 3) {
            setResultProbs([
                ...resultProbs,
                {id: 2, prefix: '', comment: ''},
            ]);
        }
    }, [chromeResult]);

    const updateParams = (params) => {
        setPortionId(data.id);
        const paramsName = props.paramsName || 'chromatography';
        if (params[paramsName]?.pivot?.params) {
            if (!params[paramsName].pivot.params.finished_at) {
                setId(params[paramsName].pivot.id);
                setQueueId(params[paramsName].pivot.portion_to_operation_id);
                setType(params[paramsName].pivot.params.type);
                setMethod(params[paramsName].pivot.params.method);
                setBasis(params[paramsName].pivot.params.basis);
                setColumn(params[paramsName].pivot.params.column);
                setReactive(params[paramsName].pivot.params.reactive);
                setChromatograph(params[paramsName].pivot.params.chromatograph);
                setStartedAt(params[paramsName].pivot.params.started_at);
                setFinishedAt(params[paramsName].pivot.params.finished_at);
                setPik(params[paramsName].pivot.params.pik);
                setFiles(params[paramsName].pivot.params.files || []);
                setComment(params[paramsName].pivot.params.comment);
                setMaterials(params[paramsName].params.materials || []);
                setChromeResult(params[paramsName].pivot.params.chromeResult || 1);
                setResultProbs(params[paramsName].pivot?.params?.resultProbs || initialResultProbs);
                setTubes(params[paramsName].pivot?.params?.tubes || []);
                setFractions(params[paramsName].params?.fractions || []);
            }
        }
    }

    /**
     * Проверка наличия portion_id у всех выбранных фракций
     * @returns {true|false}
     */
    const allFractionsHasPortionId = () => {
        return selectedFractions.every(item => item.portion_id);
    }

    const load = () => {
        setLoading(true);
        return api.getJSON(props.options.url).then((result) => {
            let d = mapDataFunction(result);
            if (props.options.selectedRowKeys) {
                d = d.filter(item => props.options.selectedRowKeys.includes(item.id));
            }
            setData([...d]);
            updateParams(d[0]);
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        });
    }

    useEffect(() => {
        if (props.options.selectedRowKeys.length > 1) {
            NotifyError('Необходимо выбрать только одну пробирку');
            props.onHide();
        } else {
            if (!loaded.current) {
                loaded.current = true;
                loadDevices();
                load();
            }
        }
    }, []);

    function handleChangeType(value) {
        setType(value);
    }

    function getResultColumns() {

        function editField(prefix, value, index) {
            const newResultProbs = [...resultProbs];
            newResultProbs[index][prefix] = value;
            setResultProbs(newResultProbs);
        }

        return [
            {id: 1, dataIndex: 'num', key: 'num', title: '№', width: 50, render: (value, record, index) => (index + 1)},
            {
                id: 2, dataIndex: 'prefix', key: 'prefix', title: 'Префикс', width: 200,
                render: (value, record, index) => {
                    return <Input value={value} onChange={(e) => editField('prefix', e.target.value, index)}/>
                }
            },
            {
                id: 3,
                dataIndex: 'comment',
                key: 'comment',
                title: 'Комментарий',
                render: (value, record, index) => {
                    return <Input value={value} onChange={(e) => editField('comment', e.target.value, index)}/>
                }
            },
            {
                id: 4, dataIndex: 'delete', key: 'delete', title: '', width: 50, align: 'center',
                render: (text, record) => <Button disabled={resultProbs.length === 2} type={'link'} onClick={() => {
                    setResultProbs(resultProbs.filter(item => item.id !== record.id));
                }}>
                    <DeleteOutlined/>
                </Button>
            }
        ];
    }

    useEffect(() => {
        setDiapasonStart(null);
        setDiapasonEnd(null);
    }, [currentDevice]);

    function getCurrentDevicePlacesNames() {
        if (currentDevice) {
            const device = devices.find(item => item.id === currentDevice);
            if (device) {
                return device.places.map(place => place.name);
            }
        }
        return [];
    }

    function addFractions() {
        let fractionsToAdd = [];
        if (currentDevice && diapasonStart && diapasonEnd) {
            const fractionsCodes = getCurrentDevicePlacesNames();
            const codes = extractDiapason(fractionsCodes, diapasonStart, diapasonEnd);
            let maxId = fractions && fractions.length ? fractions.reduce((max, fraction) => (fraction.id > max ? fraction.id : max), 0) + 1 : 1;
            fractionsToAdd = codes.map((code, index) => {
                return {id: maxId + index, code, collector: collector};
            });
            let fractionsToAddFiltered = fractionsToAdd;
            if (fractions) {
                fractionsToAddFiltered = fractionsToAdd.filter(f => !fractions.find(item => item.code === f.code));
            }
            setFractions((oldFractions) => [...oldFractions, ...fractionsToAddFiltered]);
        }
    }

    function makeTube() {
        if (!id) {
            NotifyError('Сначала сохраните запись');
            return;
        }
        const newTubeId = generateTubeId();
        const fractionCodes = selectedFractions.map(f => f.code);
        if (newTubeId) {
            const tube = {
                id: newTubeId,
                fractions: [...selectedFractions],
                collector: collector
            }
            setTubes([...tubes, tube]);
            setSelectedTubes([]);
            setFractions(fractions.filter(f => !fractionCodes.includes(f.code)));
            setSelectedFractions(fractionCodes.filter(f => !fractionCodes.includes(f.code)));
        } else {
            NotifyError('Не удалось создать пробирку');
        }
    }

    function disassembleTubes() {
        const idsToRemove = [];
        let fractionsToAdd = [];
        selectedTubes.forEach(tube => {
            tube.fractions.forEach((fraction, index) => {
                fractionsToAdd.push({...fraction, id: generateFractionId(fractionsToAdd)});
            });

            idsToRemove.push(tube.id);
        });
        setFractions([...fractions, ...fractionsToAdd]);
        setTubes(tubes.filter(tube => !idsToRemove.includes(tube.id)));
        setSelectedTubes([]);
    }

    function generateFractionId(fractionsToAdd) {
        const fractionsIds = fractionsToAdd.map(f => f.id);
        const fractionsIds2 = fractions.map(f => f.id);
        const allIds = [...fractionsIds, ...fractionsIds2];
        return allIds.reduce((max, id) => (id > max ? id : max), 0) + 1;
    }

    function generateTubeId() {
        return tubes.reduce((max, tube) => (tube.id > max ? tube.id : max), 0) + 1;
    }

    function onChangeTube(id, propName, value) {
        const newTubes = [...tubes];
        const tube = newTubes.find(tube => tube.id === id);
        tube[propName] = value;
        setTubes(newTubes);
    }

    function deleteFractions() {
        if (selectedFractions.length === 0) {
            NotifyError('Не выбрано ни одной фракции');
            return;
        }
        const codesToRemove = selectedFractions.map(f => f.code);
        setFractions(fractions.filter(f => !codesToRemove.includes(f.code)));
        setSelectedFractions([]);
    }

    function onSendToMass() {
        if (selectedFractions.length === 0) {
            NotifyError('Не выбрано ни одной фракции');
            return;
        }
        setShowChromeTablet(true);
    }

    function getDevicesAsOptions() {
        return devices.map(device => ({value: device.id, label: device.name}));
    }

    function ApplyDisabled() {
        // если все условия для закрытия операции выполнены, скрываем кнопку применить, чтобы окно закрылось
        return !OKDisabled() && finishedAt;
    }

    return <Modal
        title="Хроматографирование"
        open={true}
        width={1800}
        destroyOnClose={false}
        onCancel={() => props.onHide()}
        footer={<>
            <Button onClick={() => onOk(true)} type={'primary'} disabled={OKDisabled() || saving || loading}>ОК</Button>
            <Button onClick={() => onOk(false)} disabled={(OKDisabled() || saving || loading) || ApplyDisabled()}
                    loading={saving}>Применить</Button>
            <Button onClick={props.onHide} disabled={saving || loading}>Отмена</Button>
        </>}
    >
        <Spin spinning={saving || loading}>
            <Row>
                <Col span={24}>
                    <Tabs defaultActiveKey={1}>
                        <TabPane key={1} tab={"Основные"}>
                            <Row>
                                <Col span={12}>
                                    Фактическое дата и время начала:&nbsp;
                                    <ConfigProvider locale={locale}>
                                        <NoFutureDate format={'DD.MM.YYYY HH:mm'}
                                                      value={startedAt ? dayjs(startedAt) : null}
                                                      placeholder={'Дата и время'}
                                                      showTime
                                                      onChange={(value) => setStartedAt(value)}/>
                                    </ConfigProvider>
                                </Col>
                                <Col span={12}>
                                </Col>
                            </Row>
                            <Row style={{marginTop: '20px'}}>
                                <Col span={24}>
                                    <Space size={6} direction="vertical" style={{width: '150px'}}>
                                        <div>Тип хроматографии</div>
                                        <Select value={type} options={props.typeOptions} onChange={handleChangeType}/>
                                    </Space>
                                    <Space size={6} direction="vertical" style={{width: '250px', marginLeft: '10px'}}>
                                        <div>Основание для вкола</div>
                                        <Select value={basis} options={props.basisOptions}
                                                onChange={handleChangeBasis}/>
                                    </Space>
                                    <Space size={6} direction="vertical" style={{width: '200px', marginLeft: '10px'}}>
                                        <div>Хроматограф</div>
                                        <Select value={chromatograph} options={props.chromatographOptions}
                                                onChange={handleChangeChromatograph}/>
                                    </Space>
                                    <Space size={6} direction="vertical" style={{width: '350px', marginLeft: '10px'}}>
                                        <div>Колонка</div>
                                        <Select value={column} options={props.columnOptions}
                                                onChange={handleChangeColumn}/>
                                    </Space>
                                    <Space size={6} direction="vertical" style={{width: '100px', marginLeft: '10px'}}>
                                        <div>Метод</div>
                                        <Input value={method} onChange={handleChangeMethod}/>
                                    </Space>
                                </Col>
                            </Row>
                            <Row style={{marginTop: '20px'}}>
                                <Col span={12}>
                                    <Space size={6} direction="vertical" style={{width: '250px'}}>
                                        <div>Количество собранных пиков</div>
                                        <Input value={pik} onChange={(e) => setPik(e.target.value)}/>
                                    </Space>
                                </Col>
                                <Col span={12}>
                                    <Space size={6} direction="vertical" style={{width: '500px'}}>
                                        <div>Комментарий</div>
                                        <Input width={200} value={comment}
                                               onChange={(e) => setComment(e.target.value)}/>
                                    </Space>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <MaterialParties onChange={(v) => {
                                        setMaterials(v);
                                    }} initialValue={materials}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Table rowKey="id" size={'small'} dataSource={data}
                                           columns={getDefaultOperationColumns()}
                                           pagination={false}/>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane key={3} tab={"Результат"}>
                            <Row>
                                <Col span={12}>
                                    Фактическое дата и время завершения:&nbsp;
                                    <ConfigProvider locale={locale}>
                                        <NoFutureDate format={'DD.MM.YYYY HH:mm'}
                                                      value={finishedAt ? dayjs(finishedAt) : null}
                                                      placeholder={'Дата и время'}
                                                      showTime
                                                      onChange={(value) => setFinishedAt(value)}/>
                                    </ConfigProvider>
                                </Col>
                                <Col span={12}>
                                    <div style={{display: 'flex'}}>
                                        <div style={{marginRight: 10}}>Хроматограмма:</div>
                                        <div style={{width: 200}}>
                                            <FileIds ids={files} onChange={(f) => setFiles(f)}/>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 20}}>
                                <Col span={24}>
                                    Результат хроматографии: &nbsp;
                                    <Radio.Group value={chromeResult} onChange={(e) => setChromeResult(e.target.value)}>
                                        <Radio key={3} value={3}>1 пробирка без фракций</Radio>
                                        <Radio key={1} value={1}>Пробирки без фракций</Radio>
                                        <Radio key={2} value={2}>Пробирки из фракций</Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>
                            {chromeResult === 2 && <div>
                                <Row style={{marginTop: 20}} gutter={20}>
                                    <Col span={6}>
                                        Кодировка фракций:
                                        <Select options={getDevicesAsOptions()} value={currentDevice}
                                                onChange={(v) => setCurrentDevice(v)}/>
                                    </Col>
                                    <Col span={6}>
                                        Коллектор:
                                        <Input value={collector} onChange={(e) => setCollector(e.target.value)}/>
                                    </Col>
                                    <Col span={6}>
                                        Диапазон фракций:<br/>
                                        <RangeSelector start={diapasonStart} end={diapasonEnd}
                                                       onChange={(start, end) => {
                                                           setDiapasonStart(start);
                                                           setDiapasonEnd(end);
                                                       }} options={getCurrentDevicePlacesNames()}
                                                       disabled={!currentDevice}
                                        />
                                    </Col>
                                    <Col span={6} style={{display: 'flex', alignItems: 'end'}}>
                                        <Space direction={'horizontal'}>
                                            <Button key={1} type={'primary'} onClick={addFractions}>Добавить</Button>
                                            <Button key={2} type={'primary'} onClick={deleteFractions}>Удалить</Button>
                                            <Button key={3} type={'primary'} style={{marginLeft: '10px'}}
                                                    disabled={sendToMassDisabled()}
                                                    onClick={onSendToMass}>Отправить на масс</Button>
                                            {showChromeTablet && <ChromeTablet data={selectedFractions}
                                                                               portionId={portionId}
                                                                               onClose={() => {
                                                                                   setShowChromeTablet(false);
                                                                               }}/>}
                                        </Space>
                                    </Col>
                                </Row>
                                <Row style={{marginTop: 20}}>
                                    <Col span={14}>
                                        <Fractions fractions={fractions}
                                                   selectedFractions={selectedFractions}
                                                   onSelectFractions={setSelectedFractions}
                                        />
                                    </Col>
                                    <Col span={1} style={{
                                        display: 'flex',
                                        alignItems: 'center', justifyContent: 'center'
                                    }}>
                                        <Space direction={'vertical'}>
                                            <Button onClick={makeTube} disabled={
                                                selectedFractions.length === 0 || !allFractionsHasPortionId()}
                                                    icon={<RightOutlined/>}/>
                                            <Button onClick={disassembleTubes} disabled={selectedTubes.length === 0}
                                                    icon={<LeftOutlined/>}/>
                                        </Space>
                                    </Col>
                                    <Col span={9}>
                                        <Tubes tubes={tubes}
                                               selectedTubes={selectedTubes}
                                               onSelectTubes={setSelectedTubes}
                                               onChange={onChangeTube}
                                        />
                                    </Col>
                                </Row>
                            </div>}
                            {chromeResult === 1 && <Row>
                                <Col span={24}>
                                    <Space direction={'vertical'} style={{width: '100%'}}>
                                        Пробирки:
                                        <Table dataSource={resultProbs} columns={getResultColumns()} rowKey={'id'}
                                               size={'small'}
                                               scroll={{x: 'max-content', y: 300}}
                                               pagination={false}
                                        />
                                        <div style={{marginTop: '10px'}}>
                                            <Button type={'primary'} onClick={() => {
                                                setResultProbs([...resultProbs, {
                                                    id: resultProbs.length > 0 ? Math.max(...resultProbs.map(item => item.id)) + 1 : 1,
                                                    prefix: '',
                                                    comment: ''
                                                }])
                                            }}>
                                                <PlusOutlined/> Добавить
                                            </Button>
                                        </div>
                                    </Space>
                                </Col>
                            </Row>}
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </Spin>
    </Modal>
}

Chromatography.propTypes = {
    data: PropTypes.array.isRequired,
    onHide: PropTypes.func.isRequired,
}

