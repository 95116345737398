import {Col, Modal, Row, Table} from "antd";
import {useEffect, useState} from "react";
import api from "../../../lib/util";
import {NotifyError} from "../../../lib/notify";
import {getDefaultOperationColumns} from "../Shared";

export function JoinPortions(props) {

    const [data, setData] = useState(props.data);
    const [resultData, setResultData] = useState([]);
    const [loaded, setLoaded] = useState(false);

    function loadResult() {
        if (props.data) {
            const ids = {ids: props.data.map((d) => d.id)};
            api.postJSON('/api/portion/merge-check', ids).then(response => {
                setLoaded(true);
                if (response.errors) {
                    NotifyError(response.errors.ids[0]);
                } else {
                    setResultData([{
                        id: 1,
                        olig_name: response.name,
                        olig_sequence: response.sequence,
                        types: response.type.map(t => t.name).join(','),
                        quantity: response.quantity,
                    }]);
                }
            }).catch((e) => {
                let message = "Невозможно объединить пробирки/колонки";
                if (e.errors) {
                    message += ": " + Object.values(e.errors).join(', ');
                }
                NotifyError(message);
            })
        }
    }

    useEffect(() => {
        if (!loaded) {
            loadResult();
        }
    }, [loaded, data, resultData]);

    function getColumns() {
        const columns = getDefaultOperationColumns();
        const onlyColumns = ['olig_name', 'olig_sequence', 'types', 'quantity'];
        return columns.filter((c) => onlyColumns.includes(c.dataIndex));
    }

    return <Modal
        title="Объединение пробирок"
        open={true}
        width={1300}
        onOk={props.onOK}
        onCancel={props.onHide}
        okButtonProps={{disabled: resultData.length == 0}}
        okText={'OK'}
        cancelText={'Отмена'}
        destroyOnClose={true}
    >
        <>
            <Row key={1}>
                <Col span={24}>Результат</Col>
                <Col span={24}>
                    <Table rowKey="id" size={'small'} dataSource={resultData} columns={getColumns()}/>
                </Col>
            </Row>
            <Row key={2}>
                <Col span={24}>Исходные данные</Col>
                <Col span={24}>
                    <Table rowKey="id" size={'small'} dataSource={props.data} columns={getColumns()}/>
                </Col>
            </Row>
        </>
    </Modal>

}
