import React, {useEffect, useState} from "react";
import {Table} from "antd";
import api from "../../lib/util";
import PropTypes from "prop-types";

export default function QueueTable(props) {

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [data, setData] = useState([]);
    const [loaded, setLoaded] = useState(false);


    function selectRow(record) {
        const sl = [...selectedRowKeys];
        if (sl.indexOf(record.key) >= 0) {
            sl.splice(sl.indexOf(record.key), 1);
        } else {
            sl.push(record.key);
        }
        setSelectedRowKeys(sl);
        props.onChange(sl);
    }

    function onSelectedRowKeysChange(sl) {
        setSelectedRowKeys(sl);
        props.onChange(sl);
    }

    async function load() {
        let data = await api.getJSON(`/api/operation/list/${props.operationId}/${props.stageId}`);
        data = data.map(item => ({key: String(item.queue), ...item}));
        setData(data);
    }

    useEffect(() => {
        if (!loaded) {
            setLoaded(true);
            load();
        }
    }, []);

    return (
        <div style={{marginTop: 20}}>
            <Table rowSelection={{
                selectedRowKeys,
                onChange: onSelectedRowKeysChange
            }}
                   size={'small'}
                   pagination={false}
                   columns={props.columns}
                   dataSource={data}
                   onRow={(record) => ({
                       onClick: () => {
                           selectRow(record);
                       }
                   })}
                   scroll={{x: 'max-content', y: 116}}
            />
        </div>
    );

}

QueueTable.propTypes = {
    columns: PropTypes.array.isRequired,
    stageId: PropTypes.number.isRequired,
    operationId: PropTypes.number.isRequired,
    onChange: PropTypes.func,
}
