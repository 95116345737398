import {Chromatography} from "./Chromatography";
import {ServerOperations} from "../Operatoins";

const basisOptions = [
    {id: 'Очистка', value: 'Очистка'},
    {id: 'Переочистка', value: 'Переочистка'},
    {id: 'Переочистка с других стадий', value: 'Переочистка с других стадий'},
];


const chromatographOptions = [
    {id: 'AKTA', value: 'AKTA'},
];

function getColumnOptions() {
    const columnOptions = [
        'Tosoh TSKgel Super-Q-5PW 13 μm 21.5×150 mm',
        'Tosoh TSKgel Super-Q-5PW 10 μm 7.5×75 mm',
        'стеклянная с гелем Tosoh TSKgel Super-Q-5PW 20 μm 50×135 mm',
    ];
    return columnOptions.map(item => ({id: item, value: item}))
}

const methodOptions = [
    {id: 'газ', value: 'газ'},
    {id: 'жидкость', value: 'жидкость'},
];

function getReactiveOptions() {
    const reactiveOptions = [
        'Буфер А для ИО',
        'Буфер Б для ИО',
        '0,2М NaOH',
    ];
    return reactiveOptions.map(item => ({id: item, value: item}))
}


export default function ChromatographyION(props) {

    return <Chromatography chromatographOptions={chromatographOptions}
                           basisOptions={basisOptions}
                           columnOptions={getColumnOptions()}
                           methodOptions={methodOptions}
                           reactiveOptions={getReactiveOptions()}
                           operation={ServerOperations.CHROMATOGRAPHY_ION}
                           paramsName={'chromatography_ion'}
                           data={props.data} onHide={props.onHide}
                           options={props.options}

    />;

}
