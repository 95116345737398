import {useEffect, useState} from "react";
import {Input} from "antd";

export function Duration(props) {

    const [value, setValue] = useState(null);
    const [minutes, setMinutes] = useState(null);
    const [hours, setHours] = useState(null);

    useEffect(() => {
        if (props.onChange) {
            props.onChange(value);
        }
    }, [value]);

    useEffect(() => {
        if (props.value) {
            setValue(props.value);
            setHours(Math.floor(props.value / 60));
            setMinutes(props.value % 60);
        }
    }, [props.value]);

    function onHoursChange(e) {
        setHours(e.target.value);
        let val = parseInt(e.target.value) || 0;
        setValue((val * 60) + (parseInt(minutes) || 0));
    }

    function onMinutesChange(e) {
        setMinutes(e.target.value);
        let val = parseInt(e.target.value) || 0;
        setValue(((parseInt(hours) || 0) * 60) + val);
    }

    return <div>
        <Input style={{width: 50}} value={hours} onChange={onHoursChange}/> ч. <Input style={{width: 50}}
                                                                                      value={minutes}
                                                                                      onChange={onMinutesChange}/> мин.
    </div>

}
