import {Button, Col, Form, Radio, Row, Table} from "antd";
import PageHeader from "../../components/mock/PageHeader";
import Search from "antd/es/input/Search";
import debounce from "lodash/debounce";
import {useEffect, useState} from "react";
import api from "../../lib/util";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import {getOrderRevisionStatuses, getOrderPriority} from "../Order/OrderEdit";

const showItemStatuses = [
    {label: 'На отгрузку', value: 1},
    {label: 'В очереди', value: 2},
    {label: 'Отгруженные', value: 3},
];

const Shipment = () => {

    const [shipments, setShipments] = useState();
    const [searchString, setSearchString] = useState();
    const [clients, setClients] = useState();
    const [selectedClient, setSelectedClient] = useState();
    const [orders, setOrders] = useState();
    const [selectedOrder, setSelectedOrder] = useState();
    const [itemTypeFilter, setItemTypeFilter] = useState();
    const [itemStatusFilter, setItemStatusFilter] = useState(showItemStatuses[0].value);

    const getColumns = () => {
        const columns = [
            {title: '№', dataIndex: 'order_number', key: 'order_number', width: 30},
            {
                title: 'Дата создания', dataIndex: 'created_at', key: 'created_at', width: 70, render: (text) => {
                    return dayjs(text).format('LL')
                }
            },
            {
                title: 'Заказчик', dataIndex: 'customer', key: 'customer', width: 200, render: (text, record) => {
                    return record.user.organization;
                }
            },
            {
                title: 'Приоритет', dataIndex: 'priority', width: 200, render: (value) => {
                    return getOrderPriority(value);
                }
            },

            {title: 'Тип заказа', dataIndex: 'order_type', key: 'order_type', width: 80},
            {
                title: 'Отгружено', dataIndex: 'shipped', key: 'shipped', width: 80, render: (val, record) => {
                    // подсчитывает сколько всего OE было отгружено
                    // const total = record.ordered ? record.ordered.reduce((acc, item) => { return acc + parseFloat(item.quantity_shipped)}, 0) : 0;
                    const total = record.ordered ? record.ordered.length : 0;
                    return `${record.fully_shipped || 0}/${total}`;
                }
            },
            {
                title: 'Количество отгрузок',
                dataIndex: 'shipment_count',
                key: 'shipment_count',
                width: 80,
                render: (val, record) => {
                    return record.shipments?.length;
                }
            },
            {
                title: 'Статус сверки',
                dataIndex: 'revision_status_id',
                key: 'revision_status_id',
                width: 80,
                render: getOrderRevisionStatuses
            },
            {title: 'Статус заказа', dataIndex: 'order_status', key: 'order_status', width: 80},
            {
                title: 'Действия',
                width: 100,
                key: 'action',
                align: 'right',
                render: (text, record) => (
                    <Link to={`/shipment/view/${record.id}`}>
                        <Button type="primary" onClick={() => {
                        }}>Отгрузки</Button>
                    </Link>
                ),
            }];
        return columns;
    }

    const loadShipments = () => {
        let url = '/api/shipment/orders';
        let params = [];
        if (searchString) {
            params.push(`search=${searchString}`);
        }
        if (selectedClient) {
            params.push(`user_id=${selectedClient}`);
        }
        if (selectedOrder) {
            params.push(`order_id=${selectedOrder}`);
        }
        if (itemStatusFilter) {
            params.push(`status=${itemStatusFilter}`);
        }
        url = `${url}?${params.join('&')}`;
        api.getJSON(url).then((response) => {
            setShipments(response.data);
        });
    }

    const loadOrders = () => {
        api.getJSON('/api/shipment/orders').then((response) => {
            let result = response.data.map((item) => {
                return {value: item.id, label: `Заказ №${item.id}, ${item.user.organization}`};
            });
            setOrders(result);
        });
    }

    const loadClients = () => {
        api.getJSON('/api/client/all').then((response) => {
            let result = response.map((item) => {
                return {value: item.id, label: item.organization};
            });
            setClients(result);
        });
    }

    useEffect(() => {
        loadShipments();
    }, [searchString, selectedClient, selectedOrder, itemTypeFilter, itemStatusFilter]);

    useEffect(() => {
        loadShipments();
        loadClients();
        loadOrders();
    }, []);

    return <div>
        <PageHeader title={'Заказы и отгрузки'}/>
        <div style={{background: '#fff', padding: 24}}>
            <Row style={{marginBottom: 20}}>
                <Col span={24}>
                    <Form layout={'inline'}>
                        <Form.Item label={'Поиск'}>
                            <Search placeholder="Поиск"
                                    onSearch={value => setSearchString(value)}
                                    onChange={debounce((e) => {
                                        setSearchString(e.target.value);
                                    }, 500)}
                                    style={{width: '500px'}}/>
                        </Form.Item>
                    </Form>
                </Col>

            </Row>
            <Row style={{marginBottom: 20}}>
                <Col span={24}>
                    <Form layout={'inline'}>
                        <Form.Item>
                            <Radio.Group
                                options={showItemStatuses}
                                optionType="button"
                                buttonStyle="solid"
                                value={itemStatusFilter}
                                onChange={(e) => {
                                    setItemStatusFilter(e.target.value);
                                }}
                            />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <h3>Заказы</h3>
                    <Table columns={getColumns()} size={'small'} dataSource={shipments} rowKey={'id'} bordered/>
                </Col>
            </Row>
        </div>
    </div>
}

export default Shipment;
