import {Col, Modal, Row, Select, Space, Tabs} from "antd";
import {DeblockTable} from "./DeblockTable";
import {useEffect, useState} from "react";
import {OperationsApi, ServerOperations} from "../Operatoins";
import MaterialParties from "../../Material/MaterialParties";
import TextArea from "antd/lib/input/TextArea";

export function DissolutionFiltration(props) {


    const [reactive, setReactive] = useState();
    const [materials, setMaterials] = useState();
    const [comment, setComment] = useState();

    const [portionsToSend, setPortionsToSend] = useState({});

    function onOk() {
        const ids = materials ? materials.map(m => m.id) : [];
        OperationsApi.doOperation(ServerOperations.DISSOLUTION_FILTRATION, portionsToSend,
            {materials: ids}).then((result) => {
            props.onHide();
        });
    }

    const reactiveOptions = [
        {id: 'Вода тип 1', value: 'Вода тип 1'},
        {id: 'Вода Sigma (Molecular Biology Reagent)', value: 'Вода Sigma (Molecular Biology Reagent)'},
        {id: 'Уксусная кислота (ледяная)*', value: 'Уксусная кислота (ледяная)*'},
    ]

    function handleReactiveChange(value) {
        setReactive(value);
    }

    function updateData() {
        setPortionsToSend(props.data.map(item => ({
            id: item.id, params: {
                reactive,
                comment,
            }
        })));
    }

    function OKDisabled() {
        return !(reactive);
    }

    useEffect(() => {
        updateData();
    }, [reactive, comment]);

    return <Modal
        title="Фильтрация"
        okButtonProps={{disabled: OKDisabled()}}
        open={true}
        width={1300}
        onOk={onOk}
        onCancel={props.onHide}
        okText={'OK'}
        cancelText={'Отмена'}
        destroyOnClose={true}
    >
        <Row>
            <Col span={24} style={{padding: '10px', display: 'flex', alignItems: 'start'}}>
                <Space size={12} direction="vertical" style={{width: '50%'}}>
                    <div>Реактив</div>
                    <Select value={reactive} options={reactiveOptions} onChange={handleReactiveChange}/>
                </Space>
                <Space size={12} direction="vertical" style={{width: '50%', marginLeft: 10}}>
                    <div>Комментарий</div>
                    <TextArea value={comment} style={{width: '100%', height: '80px'}}
                              onChange={(e) => setComment(e.target.value)}/>
                </Space>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <MaterialParties onChange={(v) => {
                    setMaterials(v);
                }} initialValue={materials}/>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <DeblockTable dataSource={props.data}/>
            </Col>
        </Row>
    </Modal>

}
