import {Col, ConfigProvider, Input, Modal, Row, Select, Table} from "antd";
import {useEffect, useState} from "react";
import locale from 'antd/lib/locale/ru_RU';
import {getDefaultOperationColumns} from "../Shared";
import {OperationsApi, ServerOperations} from "../Operatoins";
import {Duration} from "../../../components/Duration";
import dayjs from "dayjs";
import NoFutureDate from "../../../components/NoFutureDate";
import TextArea from "antd/lib/input/TextArea";

export function Evaporation(props) {

    const [id, setId] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [comment, setComment] = useState();
    const [duration, setDuration] = useState(null);
    const [temperature, setTemperature] = useState(null);
    const [concentratorNum, setConcentratorNum] = useState(1);
    const [portionsToSend, setPortionsToSend] = useState([]);
    const [queueId, setQueueId] = useState();
    const [saving, setSaving] = useState(false);

    function save() {
        return new Promise((resolve, reject) => {
            if (queueId) {
                OperationsApi.editOperation(ServerOperations.EVAPORATION, queueId, portionsToSend, null);

            } else {
                OperationsApi.doOperation(ServerOperations.EVAPORATION, portionsToSend, null);
            }
            resolve();
        });
    }

    function onOk() {
        setSaving(true);
        save().then(() => {
            setSaving(false);
            props.onHide();
        });
    }

    function updateData(index, record) {
        const newData = props.data;
        newData[index] = record;
        const dataToSend = newData.map(item => ({
            id: item.id, params: {
                started_at: startDate,
                finished_at: endDate,
                duration,
                temperature,
                concentratorNum,
                comment,
            }
        }));
        setPortionsToSend(dataToSend);
    }

    function generateConcentratorNumOptions() {
        return [...Array(12).keys()].map(v => ({label: v + 1, value: v + 1}))
    }

    useEffect(() => {
        updateData();
    }, [startDate, duration, temperature, concentratorNum, comment, endDate]);

    useEffect(() => {
        if (props.data[0].evaporation?.pivot) {
            if (!props.data[0].evaporation.pivot.params.finished_at) {
                setId(props.data[0].evaporation.pivot.id);
                setStartDate(dayjs(props.data[0].evaporation.pivot.started_at));
                setComment(props.data[0].evaporation.pivot.params.comment);
                setDuration(props.data[0].evaporation.pivot.params.duration);
                setQueueId(props.data[0].evaporation.pivot.portion_to_operation_id);
                setTemperature(props.data[0].evaporation.pivot.params.temperature);
                setConcentratorNum(props.data[0].evaporation.pivot.params.concentratorNum);
            }
        }
    }, [props.data]);

    function OKDisabled() {
        return !(startDate && duration && duration > 0 && temperature && concentratorNum);
    }

    return <Modal title="Упаривание" width={1300} onOk={onOk}
                  onCancel={props.onHide} open={true} okText={'OK'}
                  cancelText={'Отмена'} destroyOnClose={true}
                  okButtonProps={
                      {disabled: OKDisabled(), loading: saving}
                  }
                  cancelButtonProps={{disabled: saving}}
    >
        <Row>
            <Col span={4} style={{padding: '5px'}}>
                Дата и время начала
                <ConfigProvider locale={locale}>
                    <NoFutureDate placeholder={'Дата и время'} showTime value={startDate}
                                  format={'DD.MM.YYYY HH:mm'}
                                  onChange={(value) => setStartDate(value)}/>
                </ConfigProvider>
            </Col>
            <Col span={4} style={{padding: '5px'}}>
                Длительность
                <Duration value={duration} onChange={(v) => setDuration(v)}/>
            </Col>
            <Col span={4} style={{padding: '5px'}}>
                Температура, °С <Input value={temperature} onChange={(e) => setTemperature(e.target.value)}></Input>
            </Col>
            <Col span={4} style={{padding: '5px'}}>
                Номер концентратора <Select value={concentratorNum} options={generateConcentratorNumOptions()}
                                            onChange={value => setConcentratorNum(value)}/>
            </Col>
            <Col span={4} style={{padding: '5px'}}>
                Дата и время окончания
                <ConfigProvider locale={locale}>
                    <NoFutureDate placeholder={'Дата и время'} showTime value={endDate}
                                  format={'DD.MM.YYYY HH:mm'}
                                  onChange={(value) => setEndDate(value)}/>
                </ConfigProvider>
            </Col>
            <Col span={4} style={{padding: '5px'}}>
                Комментарий
                <TextArea value={comment} onChange={(e) => setComment(e.target.value)} />
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <Table rowKey={"id"} size={'small'} dataSource={props.data} columns={getDefaultOperationColumns()}/>
            </Col>
        </Row>
    </Modal>;

}
