import {useState} from 'react';
import {Col, Row, Table, Input} from "antd";
import Modal from "antd/es/modal/Modal";
import api from "../../../lib/util";
import PropTypes from "prop-types";
import {useColumns} from "../Shared";

export const SynthTypes = {
    NEW_SYNTH: 1, // Новый синтез
    EXTRA_SYNTH: 2, // Досинтез
    RE_SYNTH: 3 // Пересинтез
}

export const synthTypesLabels = [];
synthTypesLabels[SynthTypes.NEW_SYNTH] = 'Новый синтез';
synthTypesLabels[SynthTypes.EXTRA_SYNTH] = 'Досинтез';
synthTypesLabels[SynthTypes.RE_SYNTH] = 'Пересинтез';

export function Resynth(props) {

    const [dataToSend, setDataToSend] = useState(props.data);
    const {columns} = useColumns(false);

    function onOk() {
        const ids = dataToSend.map(item => ({id: item.id, quantity: item.add_synth}));
        let data = {
            portions: ids,
            reason: props.type,
        }
        api.postJSON('/api/portion/resynthesis', data).then(response => {
            props.onHide();
        });
    }

    function onCancel() {
        if (props.onCancel) {
            props.onCancel();
        }
        if (props.onHide) {
            props.onHide();
        }
    }

    function onChange(row, value) {
        let data = dataToSend.map(item => {
            if (item.id === row.id) {
                item.add_synth = value;
            }
            return item;
        });
        setDataToSend(data);
    }

    const isValid = () => {
        return dataToSend.filter(item => item.add_synth === undefined || item.add_synth <= 0).length === 0;
    }

    const getColumns = () => {
        const onlyColumns = ['date', 'device_name', 'place_name', 'olig_name', 'scale_name',
            'olig_sequence'];
        let result = columns.filter(column => onlyColumns.includes(column.dataIndex));
        result.push({title: 'Тип', dataIndex: 'type', key: 'type', width: 50});
        result.push({
            title: 'OE',
            dataIndex: 'add_synth',
            key: 'add_synth',
            width: 150,
            render: (value, row) => {
                return <Input value={value} onChange={(e) => onChange(row, e.target.value)}/>
            }});
        return result;
    }


    const title = synthTypesLabels[props.type];

    return <Modal
        title={title}
        open={true}
        width={1300}
        onOk={onOk}
        onCancel={onCancel}
        okText={'OK'}
        cancelText={'Отмена'}
        destroyOnClose={true}
        okButtonProps={{disabled: !isValid()}}
    >
        <Row>
            <Col span={24}>
                <Table columns={getColumns()} size={'small'} dataSource={dataToSend}/>
            </Col>
        </Row>
    </Modal>

}

Resynth.propTypes = {
    data: PropTypes.array.isRequired,
    type: PropTypes.number.isRequired,
};

Resynth.defaultProps = {
    data: [],
    type: 1,
}
