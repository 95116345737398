import PropTypes from "prop-types";
import {Button, Table, Tooltip} from "antd";
import dayjs from "dayjs";
import {cut} from "../lib/util";

const OrderHistoryList = ({orders}) => {

    const columns = [
        {
            title: 'Дата заказа', dataIndex: 'created_at', key: 'created_at',
            render: (date) => {
                return new Date(date).toLocaleDateString();
            }
        },
        {title: '№ заказа', dataIndex: 'order_number', key: 'order_number'},
        {title: 'Клиент', dataIndex: 'user', key: 'user', render: (user) => user?.organization},
    ];
    let more = null;

    if (orders.length > 0) {
        if (orders.length > 1) {
            const content = <Table dataSource={orders} columns={columns} pagination={false} rowKey={"id"}/>;
            more = <Tooltip destroyTooltipOnHide={true}
                            placement={'left'}
                            color={'#1a8d90'}
                            autoAdjustOverflow={true}
                            arrow={{pointAtCenter: true}}
                            title={content}><Button type="primary" size={'small'}>i</Button></Tooltip>;
        }
        const orderInfo = `${dayjs(orders[0].created_at).format('DD.MM.YYYY')}, ${orders[0].order_number}, ${orders[0].user.organization}`;
        return <div title={orderInfo}>{cut(orderInfo, 28)} {more}</div>
    } else {
        return '-';
    }

}

OrderHistoryList.propTypes = {
    orders: PropTypes.array.isRequired
};

export default OrderHistoryList;