import React, {Component} from 'react';
import {Col, Row, Skeleton, Card, Tabs, Select, List, Button, Typography, Space, Input} from 'antd';
import {
    DeleteOutlined,
    SaveOutlined
} from '@ant-design/icons';
import {Droppable} from "react-beautiful-dnd";
import {saveAs} from "file-saver";
import Title from "antd/lib/typography/Title";
import {NotifyError} from "../../lib/notify";
import PropTypes from "prop-types";
import TextArea from "antd/lib/input/TextArea";
import {getBearerAuthHeader} from "../../lib/util";

const {TabPane} = Tabs;
const {Option} = Select;

class Places extends Component {

    state = {
        activeKey: '1',
        loading: false,
        saving: false,
    }

    componentDidMount() {
        const {activeCycleIndex} = this.props;
        this.setState({activeKey: String(activeCycleIndex + 1)});
    }

    handleSave(device, date) {
        const notSelected = device.places.filter(function (item) {
            const selected = item.items.filter(function (item) {
                if (item.items?.length > 0 && !item.scale) {
                    return true;
                }
            });
            return (selected.length > 0);
        });
        if (notSelected.length > 0) {
            NotifyError('Не везде выставлен масштаб');
            return false;
        }
        this.setState({saving: true});
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({device, date})
        };

        requestOptions.headers = {
            ...requestOptions.headers,
            ...getBearerAuthHeader()
        }

        fetch(`/api/synthesis/${date}/${device.id}/save`, requestOptions)
            .then((response) => {
                return response.blob();
            })
            .then(() => {
                window.location.href = `/synth-planning`;
            }).then(function (data) {
        }).finally(() => {
            this.setState({saving: false});
        });
    }

    handleDownload(device, date) {

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({device: device, date: date})
        };
        requestOptions.headers = {
            ...requestOptions.headers,
            ...getBearerAuthHeader()
        }
        // не переделывать в api.postJSON!
        fetch(`/api/synthesis/${date}/${device.id}/download`, requestOptions)
            .then(function (response) {
                return response.blob();
            })
            .then(function (blob) {
                saveAs(blob, device.name + ' ' + date + ".pdf");
            })
            .then(function (data) {
                // console.log(data);
            });
    }

    render() {
        const {date, device, loading} = this.props;
        const handleDelete = this.props.handleDelete;
        const getBackground = this.props.getBackground;
        const handleRecalc = this.props.handleRecalc;
        const handleScaleChange = this.props.handleScaleChange;
        const handleChangeItem = this.props.onChangeItem;

        return (
            <Skeleton loading={loading}>
                <Row align={"end"} style={{marginTop: 30}}>
                    <Col><Button type="primary" icon={<SaveOutlined/>} loading={this.state.saving}
                                 onClick={() => this.handleSave(device, date)}>Сохранить</Button></Col>
                </Row>
                {device &&
                    <div>
                        <Tabs activeKey={this.state.activeKey} centered
                              style={{marginTop: 10}}
                              onTabClick={(key) => this.setState({activeKey: key})}>
                            >
                            {device.places && device.places.length > 0 &&
                                device.places.map((cycle, cycle_index) => {
                                    return <TabPane tab={"Запуск " + (cycle_index + 1)}
                                                    key={cycle_index + 1}
                                                    disabled={!cycle.editable}
                                                    style={{height: window.innerHeight - 280, overflowY: 'auto'}}>
                                        <Title level={5}>Всего: {cycle.items.map(i => i.total_count).reduce(
                                            function (prev, next) {
                                                return prev + next;
                                            }
                                        )} шт.</Title>
                                        {!cycle.editable &&
                                            <div>Изменение не доступно. Для доступа отмените заполнение на
                                                синтезе.</div>}
                                        {cycle.editable && cycle && cycle.items.length > 0 &&
                                            cycle.items.map((place, index) => {
                                                return <Card size="small"
                                                             key={index}
                                                             title={
                                                                 <Row>
                                                                     <Col
                                                                         span={12}>{place.name} ({place.total_count} из {place.places_count})</Col>
                                                                     <Col span={12}
                                                                          className={!place.scale && place.items.length > 0 ? 'select-has-error' : ''}>
                                                                         <Space direction={'horizontal'} style={{
                                                                             width: '100%',
                                                                             display: 'flex',
                                                                             justifyContent: 'flex-end'
                                                                         }}>
                                                                             <Select
                                                                                 onChange={function (value) {
                                                                                     handleScaleChange(value, cycle_index, place.id);
                                                                                 }}
                                                                                 defaultValue={place.scale ? place.scale : null}
                                                                                 placeholder="Выберите масштаб">
                                                                                 {device.scales && device.scales.length > 0 &&
                                                                                     device.scales.map((scale, index) => (
                                                                                         <Option key={index}
                                                                                                 value={scale.id}>{scale.scale} {scale.dimension}</Option>
                                                                                     ))}
                                                                             </Select>
                                                                         </Space>
                                                                     </Col>
                                                                 </Row>
                                                             }>
                                                    <Droppable
                                                        droppableId={cycle_index.toString() + "_" + place.id.toString()}>
                                                        {function (provided, snapshot) {
                                                            return <div
                                                                {...provided.droppableProps}
                                                                ref={provided.innerRef}
                                                            >
                                                                <List
                                                                    dataSource={place.items}
                                                                    style={{backgroundColor: getBackground(snapshot, place)}}
                                                                    renderItem={function (item, place_index) {
                                                                        return <List.Item id={item.id}
                                                                                          key={item.id}
                                                                                          title={item.full_sequence}>
                                                                            <div className="ant-row-flex"
                                                                                 style={{width: "100%"}}>
                                                                                <Row style={{
                                                                                    width: "100%",
                                                                                    marginBottom: "5px"
                                                                                }}>
                                                                                    <Col span={18}>
                                                                                        <strong>{item.name}</strong>
                                                                                    </Col>
                                                                                    <Col span={6}
                                                                                         style={{textAlign: 'right'}}>
                                                                                        <Input value={item.count}
                                                                                               type="number"
                                                                                               min={1}
                                                                                               max={place.places_count - place.total_count + item.count}
                                                                                               onChange={(e) => {
                                                                                                   handleRecalc(cycle_index, place, item, e.target.value);
                                                                                               }} name="quantity"/>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col span={22}>
                                                                                        <Typography>{item.full_sequence}</Typography>
                                                                                    </Col>
                                                                                    <Col span={2}
                                                                                         style={{textAlign: 'right'}}>
                                                                                        <Button size="small"
                                                                                                shape="circle"
                                                                                                className={'del-btn'}
                                                                                                id={item.id}
                                                                                                onClick={() => handleDelete(cycle_index, place.id, item.id)}><DeleteOutlined/></Button>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row style={{marginTop: 10}}>
                                                                                    <Col span={24}>
                                                                                        Комментарий:
                                                                                        <TextArea
                                                                                            value={item.params?.comment}
                                                                                            onChange={(e) => {
                                                                                                let newItem = item;
                                                                                                if (!newItem.params || newItem.params.length === 0) {
                                                                                                    newItem.params = {};
                                                                                                }
                                                                                                newItem.params.comment = e.target.value
                                                                                                handleChangeItem(cycle_index, place.id, newItem);
                                                                                            }}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        </List.Item>;
                                                                    }}
                                                                >
                                                                    {provided.placeholder}
                                                                </List>
                                                                {place.items.length === 0 &&
                                                                    <div
                                                                        style={{backgroundColor: getBackground(snapshot, place)}}>
                                                                        <br/><br/></div>
                                                                }
                                                            </div>
                                                        }}
                                                    </Droppable>
                                                </Card>
                                            })}
                                    </TabPane>
                                })}
                        </Tabs>
                    </div>
                }
            </Skeleton>
        );
    }
}

Places.propTypes = {
    activeCycleIndex: PropTypes.number.isRequired,
    onAddBtnClick: PropTypes.func.isRequired,
}

Places.defaultProps = {
    activeCycleIndex: 1,
}

export default Places;

