import {Modal} from "antd";
import PropTypes from "prop-types";
import {OperationsApi} from "../Operatoins";

export function DeleteOperation(props) {

    function onOK() {
        console.log(props);
        props.onHide();
        return;
        // operation_id, operation_to_portion_id, queue_id
        OperationsApi.deleteOperation(props.id).then((result) => {
            props.onHide();
        });

    }

    return <Modal
        open={true}
        destroyOnClose={true}
        onOk={onOK}>
        <p>Вы уверены, что хотите удалить операцию?</p>
    </Modal>;
}

DeleteOperation.propTypes = {
    onHide: PropTypes.func.isRequired,
};

DeleteOperation.defaultProps = {};