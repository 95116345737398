import React, {useContext} from "react";
import {FavoritesContext} from "../lib/FavoritesContext";
import {Button, Col, Popconfirm, Row} from "antd";
import {ArrowLeftOutlined, ArrowRightOutlined, CloseOutlined, LeftOutlined, RightOutlined} from "@ant-design/icons";
import {NotifyInfo} from "../lib/notify";
import {useHistory} from "react-router";
import '../assets/Favorites.scss';

export const Favorites = () => {

    const {favorites, removeFavorite, moveUp, moveDown} = useContext(FavoritesContext);
    const history = useHistory();

    return <>
        <h3 style={{marginTop: 0}}>Избранные разделы</h3>
        {favorites.length === 0 && <div className="empty">Здесь пока пусто</div>}
        <Row className={'favorites'}>
            {favorites.map((favorite, index) => {
                return <Col key={index} span={4} className={'favorite-item'}
                            onClick={() => {
                                history.push(favorite.path);
                            }}
                >
                    <div className="title">
                        {favorite.name}
                    </div>
                    <div className="del">
                        <Popconfirm title={'Удалить раздел ' + favorite.name + ' из избранного?'}
                                    okText={'Да'}
                                    cancelText={'Нет'}
                                    onConfirm={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        removeFavorite(favorite);
                                        NotifyInfo('Раздел ' + favorite.name + ' удален из избранного');
                                    }}
                                    onCancel={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}>
                            <Button icon={<CloseOutlined/>} onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                            }}></Button>
                        </Popconfirm>
                    </div>
                    <div className="buttons">
                        {index > 0 && <Button icon={<LeftOutlined/>} onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            moveUp(favorite);
                        }}></Button>}
                        {index < favorites.length - 1 && <Button icon={<RightOutlined/>} onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            moveDown(favorite);
                        }}></Button>}
                    </div>
                </Col>;
            })}
        </Row>
    </>

}