import {Tabs} from "antd";
import {StockBase} from "./StockBase";
import PackageOrders from "./PackageOrders";
import {Unpackaged} from "./Unpackaged";
import PageHeader from "../../components/mock/PageHeader";

const Packaging = () => {
    return <>
        <PageHeader title={'Фасовка'}/>
        <Tabs rootClassName={'tabs-20'} destroyInactiveTabPane>
            <Tabs.TabPane tab={'Фасовка к заказам'} key={'1'}>
                <PackageOrders/>
            </Tabs.TabPane>
            <Tabs.TabPane tab={'Нерасфасованные'} key={'2'}>
                <Unpackaged/>
            </Tabs.TabPane>
            <Tabs.TabPane tab={'База остатков'} key={'3'}>
                <StockBase/>
            </Tabs.TabPane>
        </Tabs>
    </>;
}

export default Packaging;
