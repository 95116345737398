import {Button, Modal, Space, Table, Upload} from "antd";
import PropTypes from "prop-types";
import {UploadOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {NotifyError} from "../../lib/notify";
import {OperationsApi, ServerOperations} from "./Operatoins";
import dayjs from "dayjs";
import {getBearerAuthHeader} from "../../lib/util";

const MassLoadResults = (props) => {

    const [result, setResult] = useState([]);
    const [portionsToSend, setPortionsToSend] = useState([]);

    const columns = [
        {key: 'id', title: 'ID', dataIndex: 'id'},
        {key: 'queue', title: 'Очередь', dataIndex: 'queue'},
        {
            key: 'date', title: 'Дата синтеза', dataIndex: 'date', render: (v, item) => {
                const date = item.places?.[0]?.calendar?.date || null;
                return dayjs(date).format('LLL');
            }
        },
        {
            key: 'analysis_date', title: 'Дата анализа', dataIndex: 'analysis_date', render: (v) => {
                return dayjs(v).format('LLL')
            }
        },
        {key: 'purity', title: 'Чистота, %', dataIndex: 'purity'},
        {key: 'nminus1', title: 'N-1, %', dataIndex: 'nminus1'},
        {key: 'nplus1', title: 'N+1, %', dataIndex: 'nplus1'},
        {key: 'additional', title: 'Дополнительно', dataIndex: 'additional'},
        {key: 'link', title: 'Ссылка на результат анализа', dataIndex: 'link'},
        {key: 'conclusion', title: 'Заключение', dataIndex: 'conclusion'},
    ];

    useEffect(() => {
        const dataToSend = result.map(item => ({
            id: item.id, params: {
                purity: item.purity,
                nminus1: item.nminus1,
                nplus1: item.nplus1,
                additional: item.additional,
                link: item.link,
                conclusion: item.conclusion,
            }
        }));
        setPortionsToSend(dataToSend);
    }, [result]);

    const uploadProps = {
        name: 'file',
        action: '/api/mass-spectrometry/import',
        accept: '.xlsx',
        headers: {
            ...getBearerAuthHeader()
        },
        onChange(info) {
            if (info.file.status === 'done') {
                setResult(info.file.response);
            } else if (info.file.status === 'error') {
                NotifyError(`${info.file.name} ошибка загрузки файла.`);
            }
        }
    };

    return (
        <Modal open={true}
               onOk={() => props.onHide()}
               onCancel={() => props.onHide()}
               destroyOnClose={true}
               width={'80%'}
        >
            <h3>Импорт результатов из Excel</h3>
            <Table columns={columns} size={'small'} dataSource={result} rowKey={'id'}/>
            <Upload {...uploadProps}>
                <Button icon={<UploadOutlined/>}>Выбрать файл</Button>
            </Upload>

        </Modal>
    );
}

export default MassLoadResults;

MassLoadResults.onHide = PropTypes.func.isRequired;
