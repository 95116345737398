import {Col, Modal, Row, Select, Table} from "antd";
import {useEffect, useState} from "react";
import {getDefaultOperationColumns} from "../Shared";
import {OperationsApi, ServerOperations} from "../Operatoins";
import MaterialParties from "../../Material/MaterialParties";
import TextArea from "antd/lib/input/TextArea";

const reactiveOptions = [
    {id: 'Этанол', value: 'Этанол'},
    {id: 'Ацетон', value: 'Ацетон'},
    {id: '4% LiClO4/ацетон', value: '4% LiClO4/ацетон'},
];

export function Desalination(props) {

    const [reactive, setReactive] = useState(null);
    const [portionsToSend, setPortionsToSend] = useState([]);
    const [materials, setMaterials] = useState([]);
    const [comment, setComment] = useState();

    function updateData(index, record) {
        const newData = props.data;
        newData[index] = record;
        const dataToSend = newData.map(item => ({
            id: item.id, params: {
                reactive, comment
            }
        }));
        setPortionsToSend(dataToSend);
    }

    useEffect(() => {
        updateData();
    }, [reactive, comment]);

    function onOk() {
        const ids = materials.map(m => m.id);
        OperationsApi.doOperation(ServerOperations.DESALINATION, portionsToSend, {materials: ids}).then((result) => {
            props.onHide();
        });
    }

    function OKDisabled() {
        return reactive === null;
    }

    return <Modal title="Обессоливание" width={1300} onOk={onOk}
                  okButtonProps={{disabled: OKDisabled()}}
                  onCancel={props.onHide}
                  open={true}
                  okText={'OK'}
                  cancelText={'Отмена'}
                  destroyOnClose={true}>
        <Row gutter={10}>
            <Col span={6}>
                Реактив
                <Select value={reactive} options={reactiveOptions}
                        onChange={value => setReactive(value)}
                />
            </Col>
            <Col span={18} >
                Комментарий
                <TextArea value={comment} style={{width: '100%', height: '80px'}}
                          onChange={(e) => setComment(e.target.value)}/>
            </Col>
        </Row>
        <Row>
            <Col span={24} style={{marginBottom: '20px'}}>
                <MaterialParties onChange={(v) => {
                    setMaterials(v);
                }} initialValue={materials}/>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <Table rowKey={"id"} size={'small'} dataSource={props.data} columns={getDefaultOperationColumns()}
                       pagination={false}
                />
            </Col>
        </Row>
    </Modal>

}
