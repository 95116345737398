import {Col, Input, Row, Select, Table} from "antd";
import {useEffect, useState} from "react";
import {OperationsApi, ServerOperations} from "../Operatoins";
import Modal from "antd/es/modal/Modal";
import {getDefaultOperationColumns} from "../Shared";

export function MKLMeasuring(props) {

    const [portionsToSend, setPortionsToSend] = useState([]);

    function onOk() {
        OperationsApi.doOperation(ServerOperations.MKL_MEASURING, portionsToSend).then((result) => {
            props.onHide();
        });
    }

    function renderMKL(value, record) {
        function onChange(e) {
            const index = props.data.indexOf(record);
            record['mkl'] = e.target.value;
            updateData(index, record);
        }

        return <Input value={record['mkl']} onChange={onChange}/>
    }

    function updateData(index, record) {
        const newData = props.data;
        newData[index] = record;
        const dataToSend = newData.map(item => ({id: item.id, mkl: item.mkl}));
        setPortionsToSend(dataToSend);
    }

    function getColumns() {
        let columns = getDefaultOperationColumns();
        return columns.concat([
            {title: 'МЛ', dataIndex: 'mkl', key: 'mkl', width: 60,
                render: (value, record) => renderMKL(value, record)}
        ]);
    }

    function OKDisabled() {
        return false;
    }

    useEffect(() => {
        let record = props.data[0] || false;
        if (record) {
            updateData(0, record);
        }
    }, [props.data])

    return <Modal title="Измерение МЛ"
                  width={1300}
                  onOk={onOk}
                  okButtonProps={{disabled: OKDisabled()}}
                  onCancel={props.onHide}
                  open={true}
                  okText={'OK'}
                  cancelText={'Отмена'}
                  destroyOnClose={true}>
        <Row>
            <Col span={24}>
                <Table rowKey={"id"} size={'small'} dataSource={props.data} columns={getColumns()}/>
            </Col>
        </Row>
    </Modal>

}
