import React, {Component} from 'react';
import {Row, Col, Button, Input, Form, Select, Skeleton} from "antd"
import PageHeader from "../../components/mock/PageHeader";
import {SaveOutlined} from "@ant-design/icons";
import api, {getBearerAuthHeader} from "../../lib/util";
import {withRouter} from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";

const { Option } = Select;
const layout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 17 },
};

class OligonucleotideEdit extends Component {

    state = {
        item: {},
        loading: true,
    };

    formRef = React.createRef();

    componentDidMount() {
        api.getJSON(`/api/oligonucleotid/edit/${this.props.match.params.id}`).then((result) => {
            this.setState({
                item: result
            });
        }).catch((error) => {
            this.setState({
                error
            });
        }).finally(() => {
            this.setState({loading: false});
        });
    }

    handleSubmit(values){
        const params = {method: 'POST', body: JSON.stringify(values), headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }};
        params.headers = {
            ...params.headers,
            ...getBearerAuthHeader(),
        };
        // не переделывать в api.postJSON!
        fetch(`/api/oligonucleotid/update/${this.props.match.params.id}`, params)
            .then(res => res.json())
            .then(
                (result) => {
                    if(result.message&&result.errors){
                        for (const [key, value] of Object.entries(result.errors)) {
                            let keys = key.split('.');
                            this.formRef.current.setFields([
                                {
                                    name: (keys.length>1)?[keys[0], parseInt(keys[1]), keys[2]]:key,
                                    errors: [value]
                                },
                            ]);
                        }
                    }else{
                        this.props.history.push("/oligs");
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    console.log('Me error');
                    this.setState({
                        loading: false,
                        error
                    });
                }
            )
    }

    render() {
        const { item, loading } = this.state;
        return (
            <React.Fragment>
                <PageHeader
                    title="Редактирование олигонуклеотида" showFavorite={false}
                />
                <Skeleton loading={loading}>
                    <Form
                        ref={this.formRef}
                        {...layout}
                        preserve={false}
                        name="basic"
                        initialValues={{ remember: true }}
                        style={{background: '#fff', padding: 24, minHeight: 280}}
                        onFinish={(values) => { this.handleSubmit(values); }}
                    >
                        <Row>
                            <Col span={24} >
                                <Form.Item
                                    label="Название"
                                    name="name"
                                    initialValue={item.name}
                                    rules={[{ required: true, message: 'Поле "Название" обязательно' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Последовательность"
                                    name="sequence"
                                    initialValue={item.full_sequence}
                                    rules={[{ required: true, message: 'Поле "Последовательность" обязательно' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Комментарий"
                                    name="comment"
                                    rules={[{ required: false }]}
                                    initialValue={item.comment}
                                >
                                    <TextArea />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col offset={22} style={{paddingTop:'20px'}}>
                                <Form.Item>
                                    <Button type="primary" icon={<SaveOutlined />} htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Skeleton>
            </React.Fragment>
        );
    }
}
export default withRouter(OligonucleotideEdit);

