import React, {createRef, useEffect, useState} from "react";
import {Col, Row, Table} from "antd";
import './deblock.scss';
import ColumnsVisibleEditor, {loadHiddenColumns} from "../../components/ColumnsVisibleEditor";
import {copyToClipboard} from "../../lib/util";

const Popup = ({record, visible, x, y, onMenuClick}) => {
    const selectedText = window.getSelection().toString();
    return visible && <ul className="popup" style={{left: `${x}px`, top: `${y}px`}}>
        <li key={1} onClick={() => {
            onMenuClick("copy", selectedText);
        }}>Копировать
        </li>
        <li className={"delimiter"}></li>
        <li onClick={() => onMenuClick("edit")}>Настроить столбцы</li>
    </ul>;
}


export function StageData(props) {

    const ref = createRef();
    const [scrollY, setScrollY] = useState(0);
    const [popup, setPopup] = useState({record: null, visible: false, x: 0, y: 0});
    const [showColumnsEditor, setShowColumnsEditor] = useState(false);

    function onSelectChange(keys) {
        let data = keys.map((item) => {
            return props.data.filter(item1 => item1.id === item)[0]
        })
        props.onSelectRow(data);
    }

    const getSelectedRowKeys = () => {
        let result = props.selectedRows ? props.selectedRows.map(item => item.id) : [];
        return result;
    }

    const scroll = {
        y: scrollY
    }

    useEffect(() => {
        if (ref.current) {
            const posOfTable = ref.current.getBoundingClientRect();
            const clientHeight = document.documentElement.clientHeight;
            const offsetY = !!props.pagination ? 146 : 86;
            setScrollY(clientHeight - posOfTable.top - offsetY);
        }
    }, [props.data]);

    const renderSelectedRows = () => {
        const selectedRows = document.getElementsByClassName('selected-rows');
        if (selectedRows.length > 0) {
            selectedRows[0].remove();
        }
        if (props.selectedRows && props.selectedRows.length > 0) {
            const ref = document.querySelector('.data-table.ant-table-wrapper');
            const div = document.createElement('div');
            div.className = 'selected-rows';
            div.innerHTML = props.selectedRows.length;
            ref.appendChild(div);
        }
    }

    const onRow = record => ({
        onContextMenu: event => {
            event.preventDefault();
            if (!popup.visible) {
                document.addEventListener("click", function onClickOutside() {
                    setPopup({record: null, visible: false, x: 0, y: 0});
                    document.removeEventListener("click", onClickOutside)
                });
            }
            const el = document.querySelector('.data-table');
            const rect = el.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;
            setPopup({
                record,
                visible: true,
                x, y
            });
        }
    });

    const getStageUrl = () => {
        if (props.stage) {
            return props.stage;
        }
        return window.location.pathname.split('/').pop();
    }

    const hiddenColumns = loadHiddenColumns(getStageUrl());

    const filterColumns = (columns) => {
        let result = [...columns.filter(column => !hiddenColumns.includes(column.dataIndex))];
        let totalColumnWidth = result.reduce((acc, item) => acc + (item.width ? item.width : 0), 0);
        let screenWidth = document.documentElement.clientWidth;
        if (totalColumnWidth < screenWidth - 200 && result.length > 1) {
            result.push({
                dataIndex: 'none',
                title: '',
            });
        }
        return result;
    }

    return <>
        <Row style={{marginTop: '0px'}}>
            <Col span={24} ref={ref}>
                <Table rowKey="id"
                       dataSource={props.data}
                       onRow={onRow}
                       ref={ref}
                       bordered
                       size={'small'}
                       scroll={scroll}
                       loading={props.loading}
                       rowSelection={{
                           selectedRowKeys: getSelectedRowKeys(),
                           onChange: onSelectChange,
                       }}
                       pagination={props.pagination ? props.pagination : false}
                       onChange={props.onDataTableChange ? props.onDataTableChange : null}
                       columns={filterColumns(props.columns)}
                       className={'data-table'}
                >
                </Table>
                <Popup {...popup} onMenuClick={(action, selectedText = "") => {
                    if (action === 'edit') {
                        setShowColumnsEditor(true);
                    }
                    if (action === 'copy') {
                        if (selectedText) {
                            copyToClipboard(selectedText);
                        }
                    }
                }}/>
            </Col>
        </Row>
        {renderSelectedRows()}
        <ColumnsVisibleEditor stage={getStageUrl()} initialColumns={props.columns} open={showColumnsEditor}
                              onHide={() => setShowColumnsEditor(false)}/>
    </>

}
