import api from "../../../lib/util";
import {useEffect, useRef, useState} from "react";
import {NotifyError} from "../../../lib/notify";
import {Button, Col, Input, Modal, Row, Space, Table} from "antd";
import PropTypes from "prop-types";
import {getDefaultOperationColumns} from "../Shared";


export function SplitPortions(props) {

    const [data, setData] = useState(props.data);
    const [splitColumnsCount, setSplitColumnsCount] = useState(2);
    const [okDisabled, setOkDisabled] = useState(false);
    const [saving, setSaving] = useState(false);
    const calculating = useRef();

    async function onOK() {
        setOkDisabled(true);
        setSaving(true);
        let errors = false;
        for (let item of data) {
            let qtys = [];
            Object.keys(item).map((key) => {
                if (key.startsWith('split_')) {
                    let value = item[key];
                    if (value === null || value === undefined || value === '') {
                        value = null;
                    } else {
                        value = parseFloat(value);
                    }
                    qtys.push(value);
                }
            });
            const promises = [];
            promises.push(api.postJSON(`/api/portion/split/${item.id}`, {quantities: qtys}).then(response => {
                props.onHide();
            }).catch((e) => {
                let message = "Невозможно разъединить пробирки/колонки";
                if (e.errors) {
                    message += ": " + Object.values(e.errors).join(', ');
                }
                NotifyError(message);
            }));
            Promise.all(promises).then(() => {
                props.onHide();
            }).finally(() => {
                setSaving(false);
                setOkDisabled(false);
            });
        }
    }

    useEffect(() => {
        let actualSplitColumns = [];
        for (let i = 0; i < splitColumnsCount; i++) {
            actualSplitColumns.push(`split_${i}`);
        }
        let newData = [...data].map((item, index) => {
            let newItem = {...item};
            for (let i = 0; i < splitColumnsCount; i++) {
                if (!newItem[`split_${i}`]) {
                    newItem[`split_${i}`] = isQuantityZero(index) ? 0 : '';
                } else {
                    if (isQuantityZero(index)) {
                        newItem[`split_${i}`] = 0;
                    }
                }
            }
            Object.keys(newItem).forEach((key) => {
                if (key.startsWith('split_') && !actualSplitColumns.includes(key)) {
                    delete newItem[key];
                }
            });
            return newItem;
        });
        setData(newData);
    }, [splitColumnsCount]);

    useEffect(() => {
        if (calculating.current) {
            clearTimeout(calculating.current);
        }
        calculating.current = setTimeout(() => {
            recalculateSplitColumns();
        }, 50);
    }, [data]);

    function isRowEditable(index) {
        return data[index]['quantity'] > 0;
    }

    function isQuantityZero(index) {
        return parseFloat(data[index]['quantity']) === 0;
    }

    function sumFields(row) {
        let result = 0.0;
        for (let i = 0; i < splitColumnsCount - 1; i++) {
            result += (parseFloat(row[`split_${i}`]) || 0);
        }
        return result;
    }

    function recalculateSplitColumns() {
        const newData = data.map((item, index) => {
            let newItem = {...item};
            if (newItem.quantity !== undefined && newItem.quantity !== null && newItem.quantity >= 0) {
                let rest = item.quantity - sumFields(item);
                if (rest <= 0) {
                    rest = 0;
                }
                if (newItem['split_0'] === undefined || newItem['split_0'] === null) {
                    newItem['split_0'] = 0;
                }
                newItem[`split_${splitColumnsCount - 1}`] = rest;
            } else {
                newItem[`split_0`] = '';
                newItem[`split_${splitColumnsCount - 1}`] = '';
            }
            return newItem;
        });
        setData(newData);
    }

    function getColumns() {

        function changeData(value, record, field) {
            let newData = data;
            const index = data.indexOf(record);
            newData[index][field] = value;
            let result = record.quantity - sumFields(record);
            if (result < 0) {
                result = 0;
            }
            newData[index][`split_${splitColumnsCount - 1}`] = result;
            setData([...newData]);
        }

        function handleChange(e, record, field) {
            changeData(e.target.value, record, field);
        }

        function renderQtyColumn(value, record, field, num) {
            let disabled = (num === splitColumnsCount - 1) || !isRowEditable(data.indexOf(record));
            return <Input disabled={disabled} value={value} onChange={(e) => handleChange(e, record, field)}/>
        }

        let columns = getDefaultOperationColumns();
        const onlyColumns = ['olig_name', 'olig_sequence', 'types', 'quantity'];
        columns = columns.filter((c) => onlyColumns.includes(c.dataIndex));

        for (let i = 0; i < splitColumnsCount; i++) {
            columns.push({
                title: `Кол-во ${i + 1}`,
                dataIndex: `split_${i}`,
                key: `split_${i}`,
                width: 50,
                align: 'right',
                render: (value, record) => {
                    return renderQtyColumn(value, record, `split_${i}`, i)
                }
            });
        }
        return columns;
    }

    function isValid() {
        const result = true;
        for (let item of data) {
            const qty = parseFloat(item.quantity);
            if (qty > 0) {
                if (qty !== 0) {
                    for (let i = 0; i < splitColumnsCount; i++) {
                        if (item[`split_${i}`] === null || item[`split_${i}`] === undefined || item[`split_${i}`] === '') {
                            return false;
                        }
                    }
                }
                let sum = 0;
                for (let i = 0; i < splitColumnsCount; i++) {
                    sum += parseFloat(item[`split_${i}`]) || 0;
                }
                if (sum !== qty) {
                    return false;
                }
            }
        }
        return result;
    }

    return <Modal
        title="Разъединение пробирок"
        open={true}
        width={1300}
        onOk={onOK}
        onCancel={props.onHide}
        okButtonProps={{disabled: (!isValid() || okDisabled), loading: saving}}
        okText={'OK'}
        cancelText={'Отмена'}
        destroyOnClose={true}
    >
        <Row>
            <Col xs={24} style={{display: 'flex', justifyContent: 'end',}}>
                <Space direction={'horizontal'}>
                    <Button onClick={() => setSplitColumnsCount(splitColumnsCount + 1)}>Добавить</Button>
                    <Button disabled={splitColumnsCount <= 2}
                            onClick={() => setSplitColumnsCount(splitColumnsCount - 1)}>Убрать</Button>
                </Space>
            </Col>
        </Row>
        <Row>
            Пробирки
            <Col span={24}>
                <Table rowKey="id" size={'small'} dataSource={data} columns={getColumns()}/>
            </Col>
        </Row>
    </Modal>;

}

SplitPortions.propTypes = {
    data: PropTypes.array.isRequired,
    onHide: PropTypes.func.isRequired,
}
