import {createContext, useEffect, useState} from "react";
import api from "./util";

export const PermissionsContext = createContext(null);

const PermissionsProvider = ({children}) => {

    const [permissions, setPermissions] = useState([]);

    const loadPermissions = () => {
        return api.getJSON('/api/user/me')
            .then(function (response) {
                let result = response.menu_items;
                result.push({id: 0, url: '/main', can_view: true, can_edit: true, title: 'Главная'});
                setPermissions(response.menu_items);
            });
    }

    const getPermission = (url) => {
        let result = permissions.find(permission => (url.startsWith(permission.url) && permission.url !== '/'));
        if (!result) {
            result = {id: 0, can_view: false, can_edit: false};
        } else {
            result.can_view = true;
        }
        return result;
    }

    const canView = (url) => {
        const permission = getPermission(url);
        return permission && permission.can_view;
    }

    const canEdit = (url) => {
        const permission = getPermission(url);
        return !!permission && permission.can_edit;
    }

    useEffect(() => {
        loadPermissions();
    }, []);

    return <PermissionsContext.Provider value={{canView, canEdit}}>
        {children}
    </PermissionsContext.Provider>;
}

export default PermissionsProvider;
