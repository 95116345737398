import {Button, Modal} from "antd";
import PropTypes from "prop-types";
import {useState} from "react";
import ChooseMaterialParty from "./ChooseMaterialParty";


const ChooseMaterialPartyWindow = (props) => {

    const [materialPartyIds, setMaterialPartyIds] = useState(null);
    const [materialParties, setMaterialParties] = useState(null);


    const onChange = (party, rows) => {
        setMaterialPartyIds(party);
        setMaterialParties(rows);
    }
    const onOk = () => {
        props.onOk(materialPartyIds, materialParties);
    }

    const onCancel = () => {
        if (props.onCancel) {
            props.onCancel();
        }
    }

    const onDetach = () => {
        if (props.onDetach) {
            props.onDetach();
        }
        onCancel();
    }

    return <Modal width={'1020px'} onOk={onOk} onCancel={onCancel} open={true}>
        <ChooseMaterialParty onSelectChange={onChange} {...props} />
        <Button onClick={onDetach}>Открепить материал</Button>
    </Modal>

}

ChooseMaterialPartyWindow.propTypes = {
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    onDetach: PropTypes.func,
}

export default ChooseMaterialPartyWindow;
