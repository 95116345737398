export class FavoritesManager {

    constructor() {
        this.favorites = JSON.parse(localStorage.getItem('favorites')) || [];
    }

    addFavorite = (favorite) => {
        this.favorites.push(favorite);
        localStorage.setItem('favorites', JSON.stringify(this.favorites));
    };

    removeFavorite = (favorite) => {
        this.favorites = this.favorites.filter((fav) => fav.path !== favorite.path);
        localStorage.setItem('favorites', JSON.stringify(this.favorites));
    };

    moveUp = (favorite) => {
        const index = this.favorites.findIndex((fav) => fav.path === favorite.path);
        if (index > 0) {
            const temp = this.favorites[index - 1];
            this.favorites[index - 1] = favorite;
            this.favorites[index] = temp;
            localStorage.setItem('favorites', JSON.stringify(this.favorites));
        }
    };

    moveDown = (favorite) => {
        const index = this.favorites.findIndex((fav) => fav.path === favorite.path);
        if (index < this.favorites.length - 1) {
            const temp = this.favorites[index + 1];
            this.favorites[index + 1] = favorite;
            this.favorites[index] = temp;
            localStorage.setItem('favorites', JSON.stringify(this.favorites));
        }
    }

    getFavorites = () => {
        return this.favorites;
    };
}
