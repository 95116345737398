import React, {createContext, useEffect, useState} from 'react';
import {FavoritesManager} from "./FavoritesManager";

export const FavoritesContext = createContext(null);

export const FavoritesProvider = ({children}) => {
    const [favorites, setFavorites] = useState([]);
    const favoritesManager = new FavoritesManager();

    useEffect(() => {
        setFavorites(favoritesManager.getFavorites());
    }, []);

    const addFavorite = (favorite) => {
        favoritesManager.addFavorite(favorite);
        setFavorites([...favorites, favorite]);
    };

    const removeFavorite = (favorite) => {
        favoritesManager.removeFavorite(favorite);
        setFavorites(favorites.filter((fav) => fav.path !== favorite.path));
    };

    const inFavorites = (favorite) => {
        return favorites.some((fav) => fav.path === favorite.path);
    };

    const moveUp = (favorite) => {
        favoritesManager.moveUp(favorite);
        setFavorites(favoritesManager.getFavorites());
    };

    const moveDown = (favorite) => {
        favoritesManager.moveDown(favorite);
        setFavorites(favoritesManager.getFavorites());
    };

    return (
        <FavoritesContext.Provider value={{favorites, addFavorite, removeFavorite, inFavorites, moveDown, moveUp}}>
            {children}
        </FavoritesContext.Provider>
    );
};
