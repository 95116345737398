import StageManager from "./StageManager";
import {useState} from "react";
import {
    evaporationQueueColumns,
    filterOperationOptions,
    getChromeColumns,
    getDesalinationColumns, getDissolationColumns,
    getEvaporationColumns, getMassResultsColumns,
    getSeatingColumns,
    mapDataFunction,
    operationMarkup as oms,
    operations as ops, useColumns
} from "./Shared";

import {Col, Radio, Row, Select} from "antd";
import {ServerOperations, Stages} from "./Operatoins";
import QueueTable from "./QueueTable";
import dayjs from "dayjs";

const subStages = [
    {value: null, label: "Все"},
    {value: 13, label: "ОФ ВЭЖХ"},
    {value: 14, label: "ОФ ВЭЖХ на щелочных буферах"},
    {value: 15, label: "DNAPac"},
    {value: 16, label: "ММ ВЭЖХ"},
    {value: 17, label: "ИО+ОФ ВЭЖХ"},
    {value: 18, label: "ИО ВЭЖХ"},
];

const visibleModes = {
    Waiting: 1,
    NonCompletedCrome: 2,
    NonCompletedUpar: 3,
}

const visibleOptions = [
    {label: 'Ожидание', value: visibleModes.Waiting},
    {label: 'Незавершенное хромотографирование', value: visibleModes.NonCompletedCrome},
    {label: 'Незавершенное упаривание', value: visibleModes.NonCompletedUpar},
]

const chromeQueueColumns = [
    {id: 1, width: 60, title: 'Очередь', dataIndex: 'queue'},
    {
        id: 3, width: 150, title: 'начало', dataIndex: 'started_at', render: function (v) {
            return dayjs(v).format('DD.MM.YYYY HH:mm')
        }
    },
    {id: 2, width: 150, title: 'Тип хроматографии', dataIndex: 'type'},
    {id: 4, width: 150, title: 'Основание', dataIndex: 'basis'},
    {id: 5, width: 150, title: 'Хроматограф', dataIndex: 'chromatograph'},
    {id: 6, width: 150, title: 'Колонка', dataIndex: 'column'},
    {id: 7, width: 150, title: 'Метод', dataIndex: 'method'},
];


export default function Chromatography() {

    const [visibleMode, setVisibleMode] = useState(visibleModes.Waiting);
    const [queueIds, setQueueIds] = useState(null);
    const [currentSubStage, setCurrentSubStage] = useState(subStages[0].value);

    const {columns, filters} = useColumns();
    const restCols = columns.concat(getChromeColumns(), getEvaporationColumns(), getSeatingColumns(),
        getDissolationColumns(), getDesalinationColumns(), getMassResultsColumns());

    function getOperations() {
        let result = [];
        if (visibleMode === visibleModes.Waiting) {
            result = [
                [oms[ops.JOIN], oms[ops.CHROMATOGRAPHY_VZH], oms[ops.EVAPORATION], oms[ops.DESALINATION], oms[ops.OE_MEASURING], oms[ops.ADD_PREFIX], oms[ops.DEFECT]],
                [oms[ops.SPLIT], oms[ops.DISSOLUTION_FILTRATION], oms[ops.SEATING], null, oms[ops.TO_MASS_SPECTR], null, oms[ops.NEXT_STAGE]],
            ];
        } else if (visibleMode === visibleModes.NonCompletedCrome) {
            result = [[oms[ops.CHROMATOGRAPHY_VZH]]];
        } else if (visibleMode === visibleModes.NonCompletedUpar) {
            return [
                [oms[ops.EVAPORATION_COMPLETE]],
            ];
        }
        return result;
    }

    function getQueryParams() {
        let result = [];
        if (queueIds) {
            for (let id of queueIds) {
                result.push(`queue_id[]=${id}`);
            }
        }
        if (visibleMode === visibleModes.Waiting) {
            result.push(`operations[0][id]=${ServerOperations.CHROMATOGRAPHY_VZH}&operations[0][status]=` + filterOperationOptions.hasCompleted);
            result.push(`operations[1][id]=${ServerOperations.EVAPORATION}&operations[1][status]=` + filterOperationOptions.hasCompleted);
        } else if (visibleMode === visibleModes.NonCompletedCrome) {
            result.push(`operations[0][id]=${ServerOperations.CHROMATOGRAPHY_VZH}&operations[0][status]=` + filterOperationOptions.hasNonCompleted);
        } else if (visibleMode === visibleModes.NonCompletedUpar) {
            result.push(`operations[0][id]=${ServerOperations.EVAPORATION}&operations[0][status]=` + filterOperationOptions.hasNonCompleted);
        }
        if (currentSubStage) {
            result.push(`type=${currentSubStage}`);
        }
        return result;
    }

    function onFilterRows(queueId) {
        setQueueIds(queueId);
    }

    function getExtraFilter(onChange, disabled) {
        return <>
            <Row>
                <Col span={24} style={{display: 'flex', alignItems: 'center'}}>
                    Этап хроматографии: &nbsp;
                    <Select style={{width: 260}} options={subStages} value={currentSubStage}
                            onChange={setCurrentSubStage} disabled={disabled}/>
                </Col>
            </Row>
            <Row>
                <Col span={24} style={{display: 'flex', alignItems: 'center', marginTop: 20}}>
                    <Radio.Group
                        options={visibleOptions} size={'small'}
                        onChange={e => {
                            setVisibleMode(e.target.value);
                            setQueueIds(null);
                            if (onChange) {
                                onChange();
                            }
                        }}
                        value={visibleMode}
                        optionType="button"
                        buttonStyle="solid"
                        disabled={disabled}
                    /></Col>
                <Col span={24} style={{display: 'flex', alignItems: 'center', marginTop: 0}}>
                    {visibleMode === visibleModes.NonCompletedCrome ?
                        <QueueTable columns={chromeQueueColumns} operationId={ServerOperations.CHROMATOGRAPHY_VZH}
                                    stageId={Stages.VZH} onChange={onFilterRows}/> : null}

                    {visibleMode === visibleModes.NonCompletedUpar ?
                        <QueueTable columns={evaporationQueueColumns} operationId={ServerOperations.EVAPORATION}
                                    stageId={Stages.VZH} onChange={onFilterRows}/> : null}
                </Col>
            </Row>
        </>
    }

    const refreshQueue = () => {
        if (visibleMode === visibleModes.NonCompletedCrome) {
            setVisibleMode(visibleModes.Waiting);
            setTimeout(() => {
                setVisibleMode(visibleModes.NonCompletedCrome);
            }, 1000);
        }
    }

    return <StageManager
        title={'Хроматография'}
        currentStageId={Stages.VZH}
        operationButtons={getOperations()}
        columns={restCols}
        mapFucntion={mapDataFunction}
        url={'/api/vzh'}
        queryParams={getQueryParams()}
        filter={filters}
        extraFilter={getExtraFilter}
        onAfterOperation={refreshQueue}
        options={{url: '/api/vzh'}}
    />;

}
