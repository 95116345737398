import {useEffect, useState} from 'react';
import {Col, Row, Select, Form, Table, Input} from "antd";
import Modal from "antd/es/modal/Modal";
import api from "../../../lib/util";
import PropTypes from "prop-types";
import {ServerOperations, Stages} from "../Operatoins";
import TextArea from "antd/lib/input/TextArea";
import {NotifyError} from "../../../lib/notify";
import {getDefaultOperationColumns} from "../Shared";

export function NextStage(props) {

    const [nextStage, setNextStage] = useState(null);
    const [stages, setStages] = useState([]);
    const [dataToSend, setDataToSend] = useState(props.data);
    const [form] = Form.useForm();

    async function onOk() {
        const postData = {
            portions: dataToSend.map(item => ({id: item.id, stage_comment: item.stage_comment || ''})),
            stageId: nextStage,
        };
        if (nextStage === ServerOperations.DETRITYLATION) {
            try {
                await form.validateFields();
            } catch (e) {
                NotifyError('Заполните все поля');
                return;
            }
            postData.portions = postData.portions.map(portion => {
                return {
                    ...portion,
                    params: {...form.getFieldsValue()}
                }
            });
        }
        api.postJSON('/api/portion/next', postData).then(response => {
            props.onHide();
        })
    }

    function onCommentChange(row, value) {
        let data = dataToSend.map(item => {
            if (item.id === row.id) {
                item.stage_comment = value;
            }
            return item;
        });
        setDataToSend(data);
    }

    function getColumns() {
        let columns = getDefaultOperationColumns();
        return columns.concat([{
            title: 'Комментарий на этап', dataIndex: 'stage_comment', key: 'stage_comment', render: (value, row) => {
                return <Input value={value} onChange={(e) => {
                    onCommentChange(row, e.target.value);
                }}/>
            }, width: 150
        }
        ]);
    }

    function loadStages() {
        api.getJSON('/api/stage/list').then(response => {
            let result = response.map(item => ({value: item.id, label: item.label}));
            if (props.excludeStageId) {
                result = result.filter(item => item.value !== props.excludeStageId);
            }
            result = result.filter(item => item.value !== Stages.SYNTHESIS);
            if (!props.options.showStockBase) {
                result = result.filter(item => item.value !== 19);
            }
            setStages(result);
        });
    }

    useEffect(() => {
        loadStages();
    }, []);

    const isValid = () => {
        return nextStage !== null;
    }

    return <Modal
        title="Передать на следующий этап"
        open={true}
        width={'100%'}
        onOk={onOk}
        onCancel={props.onHide}
        okText={'OK'}
        cancelText={'Отмена'}
        destroyOnClose={true}
        okButtonProps={{disabled: !isValid()}}
    >
        <Row>
            <Col span={6}>
                <Form.Item label={'Следующий этап'}>
                    <Select options={stages} onChange={(v) => setNextStage(v)}></Select>
                </Form.Item>
            </Col>
        </Row>
        <Row>
            {nextStage === ServerOperations.DETRITYLATION &&
                <Col span={24}>
                    <Row gutter={10}>
                        <Col span={24}>
                            <Form form={form} layout={'inline'}>
                                <Form.Item label={'Холодильник'} name={'refrigerator'}
                                           rules={[{required: true, message: 'Заполните поле холодильник'}]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item label={'Полка'} name={'shelf'}
                                           rules={[{required: true, message: 'Заполните поле полка'}]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item label={'Штатив'} name={'stand'}
                                           rules={[{required: true, message: 'Заполните поле штатив'}]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item label={'Позиция'} name={'position'}
                                           rules={[{required: true, message: 'Заполните поле позиция'}]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Col span={24}>
                                    <Form.Item label={'Комментарий'} name={'comment'}
                                               style={{marginTop: 20, marginBottom: 40}}>
                                        <TextArea/>
                                    </Form.Item>
                                </Col>
                            </Form>
                        </Col>
                    </Row>
                </Col>}
            <Col span={24}>
                <Table columns={getColumns()} size={'small'} dataSource={dataToSend} rowKey={'id'}/>
            </Col>
        </Row>
    </Modal>

}

NextStage.propTypes = {
    data: PropTypes.array.isRequired,
    excludeStageId: PropTypes.number,
    options: PropTypes.object,
};

NextStage.defaultProps = {
    options: {
        showStockBase: false,
    },
}
