import {Router, Route} from 'react-router-dom'
import {createBrowserHistory} from 'history';
import './assets/App.scss';
import {useContext, useEffect, useState} from "react";
import Index from "./routes";
import Login from "./Login";
import {AuthContext} from "./lib/AuthContext";
import {FavoritesProvider} from "./lib/FavoritesContext";
import {ThemeProvider} from 'antd-style';

import HotKeysProvider from "./lib/HotKeyContext";
import PermissionsProvider from "./lib/PermissionsContext";
import {ConfigProvider} from "antd";

// настройка локали
import locale from 'antd/lib/locale/ru_RU';
import dayjs from "dayjs";
import 'dayjs/locale/ru'
import utc from 'dayjs/plugin/utc';

dayjs.locale('ru')
const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);
dayjs.extend(utc);

const timezone = require('dayjs/plugin/timezone');
dayjs.extend(timezone);
dayjs.tz.setDefault('UTC');

const isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

export const hist = createBrowserHistory();

function App() {

    const auth = useContext(AuthContext);
    const [token, setToken] = useState(auth.token);

    useEffect(() => {
        hist.listen((location, action) => {
            // у страницы synth/edit установлен минимальный размер экрана 1920px
            // для остальных сбрасываем его
            if (!location.pathname.includes('synth/edit')) {
                document.getElementById('root').style.minWidth = null;
            }
        });
    });

    return (
        <ConfigProvider locale={locale}>
            <ThemeProvider
                theme={{
                    token: {
                        colorPrimary: '#02824b',
                    },
                }}
            >
                <AuthContext.Provider value={{token, setToken}}>
                    {token ? <PermissionsProvider>
                        <FavoritesProvider>
                            <Router history={hist}>
                                <HotKeysProvider>
                                    <Route path="/" component={Index}/>
                                </HotKeysProvider>
                            </Router>
                        </FavoritesProvider>
                    </PermissionsProvider> : <Login/>}
                </AuthContext.Provider>
            </ThemeProvider>
        </ConfigProvider>
    );

}

export default App;
