import React, {useState, useEffect, useMemo, useRef, useContext} from 'react';
import {Row, Col, Table, Button, Input, Popconfirm,} from 'antd';
import PageHeader from "../../components/mock/PageHeader";
import {DeleteOutlined, EditOutlined, EllipsisOutlined, FileAddOutlined,} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import {buildQuery, api} from '../../lib/util';
import PropTypes from "prop-types";
import usePermissions from "../../lib/Permissions";

const {Search} = Input;

const ClientListData = props => {
    return <Table dataSource={props.data}
                  scroll={props.scroll}
                  columns={props.columns}
                  rowKey="id"
                  pagination={{pageSize: 50, total: props.total, pageSizeOptions: []}}
                  loading={props.loading}
                  onChange={props.onChange}
    />;
}

ClientListData.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    scroll: PropTypes.object.isRequired,
};


const ClientList = () => {
    const [search, setSearch] = useState('');
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState(0);

    const ref = useRef();
    const [scrollY, setScrollY] = useState(0);

    const {canEdit, canView} = usePermissions();

    useEffect(() => {
        setTimeout(() => {
            load({});
        }, 1000);
    }, []);

    const handleSearch = (value) => {
        setSearch(value);
        load({search: value});
    };

    const handleTableChange = (pagination, filters, sorter) => {
        load({
            page: pagination.current,
            search,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });
    };

    const load = (params = {}) => {
        setLoading(true);
        const query = buildQuery(params);
        api.getJSON(`/api/client?${query}`)
            .then((result) => {
                setData(result.data);
                setTotal(result.total);
                updateScroll();
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const deleteClient = (id) => {
        setLoading(true);
        api
            .postJSON(`/api/client/delete/${id}`, {})
            .then(() => {
                handleTableChange({}, {}, {});
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getColumns = () => {
        const columns = [
            {
                title: 'Организация',
                dataIndex: 'organization',
                key: 'organization',
                sorter: true,
                ellipsis: false,
                render: (value) => <div>{value}</div>,
            },
            {
                title: 'Адрес',
                dataIndex: 'address',
                key: 'address',
                sorter: true,
                ellipsis: false,
                render: (value) => <div>{value}</div>,
            },
            {
                title: 'Телефон',
                dataIndex: 'phone',
                key: 'phone',
                sorter: true,
            },
            {
                title: 'E-mail',
                dataIndex: 'email',
                key: 'email',
                sorter: true,
            },
        ];

        if (canEdit) {
            columns.push({
                    title: '',
                    dataIndex: 'edit',
                    key: 'edit',
                    render: (text, record) => (
                        <div>
                            <Link to={`/client/edit/${record.id}`}>
                                <Button size="small" shape="circle" className={'edit-btn'}>
                                    <EditOutlined/>
                                </Button>
                            </Link>
                            <Popconfirm
                                title="Вы уверены что хотите удалить клиента?"
                                okText="Да"
                                onConfirm={() => deleteClient(record.id)}
                                cancelText="Нет"
                            >
                                <Button
                                    size="small"
                                    className={'del-btn'}
                                    style={{marginLeft: 5}}
                                    shape="circle"
                                >
                                    <DeleteOutlined/>
                                </Button>
                            </Popconfirm>
                        </div>
                    ),
                    width: 85,
                },
            );
        } else if (canView) {
            columns.push({
                    title: '',
                    dataIndex: 'edit',
                    key: 'edit',
                    render: (text, record) => (
                        <div>
                            <Link to={`/client/edit/${record.id}`}>
                                <Button size="small" shape="circle" className={'edit-btn'}>
                                    <EllipsisOutlined/>
                                </Button>
                            </Link>
                        </div>
                    ),
                    width: 85,
                },
            );
        }

        return columns;
    }

    const scroll = {
        y: scrollY
    }

    const updateScroll = () => {
        if (ref.current) {
            const posOfTable = ref.current.getBoundingClientRect();
            const clientHeight = document.documentElement.clientHeight;
            const offsetY = 116;
            setScrollY(clientHeight - posOfTable.top - offsetY);
        }
    }

    return <><PageHeader title="Клиенты" path={'/client'}/>
        <div style={{background: '#fff', padding: 24, minHeight: 280}}>
            <Row>
                <Col span={24}>
                    <Row style={{paddingBottom: 10}}>
                        <Col span={12}>
                            <Search placeholder="поиск" onSearch={handleSearch}/>
                        </Col>
                        <Col span={12}>
                            {canEdit && <Button style={{float: 'right'}}>
                                <Link to="/client/edit">
                                    <FileAddOutlined/> Добавить
                                </Link>
                            </Button>}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col span={24} ref={ref}>
                    <ClientListData
                        data={data}
                        columns={getColumns()}
                        total={total}
                        loading={loading}
                        onChange={handleTableChange}
                        scroll={scroll}
                    />
                </Col>
            </Row>
        </div>
    </>;
}

export default ClientList;
