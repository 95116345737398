import StageManager from "./StageManager";
import {useState, useEffect} from "react";
import {getMassResultsColumns, mapDataFunction, operationMarkup as oms, operations as ops, useColumns} from "./Shared";
import {Col, Row, Select, Form} from "antd";
import api from "../../lib/util";


export default function Portions() {

    const [selectedRows, setSelectedRows] = useState([]);
    const [stages, setStages] = useState([]);
    const [selectedStages, setSelectedStages] = useState([]);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [total, setTotal] = useState();

    const urlParams = new URLSearchParams(window.location.search);
    const full_sequence = urlParams.get('select') || null;
    const [queryParams, setQueryParams] = useState({});
    const [tempSelectedStage, setTempSelectedStage] = useState([]);

    const extraColumns = [{
        title: 'Текущий этап',
        dataIndex: 'current_stage_name',
        key: 'current_stage_name',
        width: 150,
        after: 'date',
        fixed: 'left',
    }];

    const {columns, filters} = useColumns(true, true, true, extraColumns);
    const restColumns = columns.concat(getMassResultsColumns());

    function getOperations() {
        return [
            [oms[ops.EXTRASYNTHESIS]]
        ];
    }

    const loadStages = () => {
        api.getJSON('/api/stage/filter-list').then(resp => {
            if (resp) {
                const options = resp.map((item) => {
                    return {label: item.label, value: item.id};
                });
                setStages(options);
            }
        });
    }

    const getExtraFilter = () => {
        return <Row>
            <Col span={8}>
                <Form.Item label="Этап" style={{margin: 0}}>
                    <Select size={'small'} mode="multiple" allowClear={true} style={{width: '100%'}} placeholder="Выберите этап"
                            onChange={(values) => {
                                setTempSelectedStage(values);
                            }}
                            value={tempSelectedStage} options={stages}/>
                </Form.Item>
            </Col>
        </Row>
    }

    useEffect(() => {
        loadStages();
    }, []);

    const getQueryParams = () => {
        const params = {};
        if (selectedStages.length) {
            params['stage'] = [...selectedStages];
        }
        params['page'] = page;
        params['pageSize'] = pageSize;
        return params;
    }

    useEffect(() => {
        setQueryParams(getQueryParams());
    }, [selectedStages, page, pageSize]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setSelectedStages(tempSelectedStage);
        }, 1000);
        return () => clearTimeout(timeout);
    }, [tempSelectedStage]);

    const mapFunction = (rawData) => {
        const {data, meta} = rawData;
        const mapped = mapDataFunction(data);
        setTotal(meta.total);
        return mapped;
    }

    return <>
        <StageManager
            title={'Пробирки в производстве'}
            operationButtons={getOperations()}
            columns={restColumns}
            mapFucntion={mapFunction}
            url={'/api/portion'}
            queryParams={queryParams}
            filter={filters}
            selectedRows={selectedRows}
            initialSearchString={full_sequence}
            pagination={{
                pageSizeOptions: ['50', '100', '250', '500', '1000', '2000', '3000'],
                pageSize: pageSize,
                total: total,
                defaultPageSize: pageSize,
                onChange: (page, pageSize) => {
                    setPageSize(pageSize);
                }
            }}
            extraFilter={getExtraFilter}
            onSelectionChange={(rows) => {
                setSelectedRows(rows.map((item) => item.id));
            }}
            onDataTableChange={(pagination, filters, sorter, extra) => {
                setPage(pagination.current);
            }}/></>;

}
