import {useEffect, useState} from "react";
import api, {formatFIO} from "../../lib/util";
import {Col, Row, Input, Table, Form} from "antd";
import PropTypes from "prop-types";

const ChooseMaterialParty = (props) => {

    const columns = [
        {title: 'Наименование', dataIndex: 'name', key: 'name'},
        {title: 'Производитель', dataIndex: 'manufacturer', key: 'manufacturer'},
        {title: 'Номер партии', dataIndex: 'batch_number', key: 'batch_number'},
        {title: 'Каталожный номер', dataIndex: 'catalogue_number', key: 'catalogue_number'},
        {
            title: 'Дата производства', dataIndex: 'production_date', key: 'production_date',
            render: (text, record) => {
                return text ? new Date(text).toLocaleDateString('ru-RU') : <span>&mdash;</span>;
            }
        },
        {
            title: 'Годен до', dataIndex: 'sell_by', key: 'sell_by', render: (text, record) => {
                return text ? new Date(text).toLocaleDateString('ru-RU') : <span>&mdash;</span>;
            }
        },
        {
            title: 'Автор', dataIndex: 'name', key: 'name', render: (text, record) => {
                return formatFIO(record?.created?.name);
            }
        },
    ];

    const [parties, setParties] = useState([]);
    const [searchString, setSearchString] = useState('');
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);

    function loadParties() {
        setLoading(true);
        let url = '/api/material/variants';
        if (searchString) {
            url += `?search=${searchString}`;
        }
        api.getJSON(url).then(data => {
            setParties(data.data);
            if (props.selectedIds) {
                console.log(props.selectedIds);
                setSelectedRowKeys(props.selectedIds);
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            loadParties();
        }, 500);
        return () => clearTimeout(timer);
    }, [searchString]);

    return <div>
        <Row>
            <h3>Выбор материала</h3>
        </Row>
        <Row style={{marginTop: 10}}>
            <Col span={12}>
                <Form>
                    <Form.Item label={'Поиск'}>
                        <Input.Search onChange={(e) => {
                            setSearchString(e.target.value);
                        }}/>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
        <Row style={{marginTop: 10}}>
            <Col span={24}>
                <Table columns={columns} dataSource={parties} rowKey={'id'} size={'small'}
                       loading={loading}
                       rowSelection={{
                           selectedRowKeys,
                           type: props.type,
                           onChange: (selectedRowKeys, selectedRows) => {
                               setSelectedRowKeys(selectedRowKeys);
                               if (props.onSelectChange) {
                                   props.onSelectChange(selectedRowKeys, selectedRows);
                               }
                           }
                       }}/>
            </Col>
        </Row>
    </div>;

}

ChooseMaterialParty.propTypes = {
    selectedIds: PropTypes.array,
    onSelectChange: PropTypes.func,
    type: PropTypes.string,
}

ChooseMaterialParty.defaultProps = {
    type: 'checkbox',
}

export default ChooseMaterialParty;

