import {Col, ConfigProvider, Input, Modal, Row, Space, Table} from "antd";
import {useEffect, useRef, useState} from "react";
import NoFutureDate from "../../../../components/NoFutureDate";
import locale from 'antd/lib/locale/ru_RU';
import dayjs from "dayjs";
import {getDefaultOperationColumns} from "../../Shared";
import {OperationsApi, ServerOperations} from "../../Operatoins";

export const defaultPositions = [
    'A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7',
    'A8', 'A9', 'A10', 'A11', 'A12', 'B1', 'B2', 'B3', 'B4', 'B5',
    'B6', 'B7', 'B8', 'B9', 'B10', 'B11', 'B12', 'C1', 'C2', 'C3',
    'C4', 'C5', 'C6', 'C7', 'C8', 'C9', 'C10', 'C11', 'C12', 'D1',
    'D2', 'D3', 'D4', 'D5', 'D6', 'D7', 'D8', 'D9', 'D10', 'D11',
    'D12', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6', 'E7', 'E8', 'E9',
    'E10', 'E11', 'E12', 'F1', 'F2', 'F3', 'F4', 'F5', 'F6',
    'F7', 'F8', 'F9', 'F10', 'F11', 'F12', 'G1', 'G2', 'G3', 'G4',
    'G5', 'G6', 'G7', 'G8', 'G9', 'G10', 'G11', 'G12', 'H1', 'H2',
    'H3', 'H4', 'H5', 'H6', 'H7', 'H8', 'H9', 'H10', 'H11', 'H12',
];

const MassSpectrTablet = ({data, onHide}) => {

    const [started, setStarted] = useState();
    const [tabletNumber, setTabletNumber] = useState();
    const [portionsToSend, setPortionsToSend] = useState([]);

    const [currentValue, setCurrentValue] = useState();

    const hasInitialized = useRef(false);

    function onOk() {
        const positions = portionsToSend.map((portion) => ({
            id: portion.id,
            tablet_position: portion.tablet_position
        }));
        const params = {
            date: started,
            tablet_number: tabletNumber
        }
        OperationsApi.doOperation(ServerOperations.TO_MASS_SPECTR, positions, params).then((result) => {
            onHide();
        });
    }

    function OKDisabled() {
        const positions = portionsToSend.map((portion) => portion.tablet_position);
        const isFilled = positions.every((position) => position);
        return !(started && tabletNumber && isFilled);
    }

    function updatePortionsToSend(record, index) {
        const newData = portionsToSend;
        newData[index] = record;
        setPortionsToSend([...newData]);
    }

    function initialFillDefaultPositions() {
        const newData = portionsToSend.map((item, index) => {
            return {...item, tablet_position: defaultPositions[index]}
        });
        setPortionsToSend(newData);
    }

    function autoFillPositions(startIndex) {
        const currentPosition = portionsToSend[startIndex].tablet_position;
        const currentPositionIndex = defaultPositions.findIndex((position) => position === currentPosition);
        if (currentPositionIndex >= 0) {
            const newData = portionsToSend.map((item, index) => {
                if (index >= startIndex) {
                    return {...item, tablet_position: defaultPositions[currentPositionIndex + index - startIndex]};
                }
                return item;
            });
            setPortionsToSend(newData);
        }
    }

    function getColumns() {
        let columns = getDefaultOperationColumns();
        columns = columns.filter(item => item.dataIndex !== 'types' && item.dataIndex !== 'quantity');
        columns.push({
            title: 'Позиция в планшете',
            dataIndex: 'tablet_position',
            width: 200,
            render: (value, record, index) => {
                return <Input value={value} onChange={e => {
                    const newValue = e.target.value.toUpperCase();
                    record.tablet_position = newValue;
                    updatePortionsToSend(record, index);
                }}
                              onFocus={() => setCurrentValue(value)}
                              onBlur={() => {
                                  if (currentValue !== value) {
                                      autoFillPositions(index);
                                  }
                              }}
                />;
            }
        });
        return columns;
    }

    useEffect(() => {
        setPortionsToSend([...data]);
    }, []);

    useEffect(() => {
        if (!hasInitialized.current && portionsToSend.length > 0) {
            hasInitialized.current = true;
            initialFillDefaultPositions();
        }
    }, [portionsToSend]);

    return <Modal
        title="Масс спектр (планшет)"
        width={1300}
        okButtonProps={{disabled: OKDisabled()}}
        onOk={onOk}
        onCancel={onHide}
        okText={'Отправить'}
        cancelText={'Отмена'}
        destroyOnClose={true}
        open={true}>
        <Row>
            <Col span={12}>
                Дата передачи
                <ConfigProvider locale={locale}>
                    <NoFutureDate format={'DD.MM.YYYY HH:mm'} value={started ? dayjs(started) : null}
                                  placeholder={'Дата и время'}
                                  showTime
                                  onChange={(value) => setStarted(value)}
                                  style={{marginLeft: '10px'}}
                    />
                </ConfigProvider>
            </Col>
            <Col span={12}>
                <Space>
                    Номер планшета
                    <Input type="number" value={tabletNumber} onChange={e => setTabletNumber(e.target.value)}/>
                </Space>
            </Col>
        </Row>
        <Row style={{marginTop: 20}}>
            <Col span={24}>
                <Table dataSource={portionsToSend} size={'small'} columns={getColumns()} rowKey={'id'}/>
            </Col>
        </Row>
    </Modal>;
}

export default MassSpectrTablet;
