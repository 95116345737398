import React from 'react';
import {Col, Row} from "antd";
import PageHeader from "../../components/mock/PageHeader";
import {Favorites} from "../../components/Favorites";


const Main = () => {
    return <>
        <PageHeader title="Главная" showFavorite={false} showCloseBtn={false}/>
        <div style={{background: '#fff', padding: 24, minHeight: '90vh'}}>
            <Row>
                <Col span={24}>
                    <Favorites/>
                </Col>
            </Row>
        </div>
    </>

}

export default Main;