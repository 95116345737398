import React, {useEffect, useState} from "react";
import api from "../../lib/util";
import {Column, Group, SynthTable as SynthTable} from "./components/SynthTable";
import {Button, Dropdown, Input, Menu, Select, Space, Tabs} from "antd";
import PageHeader from "../../components/mock/PageHeader";
import {NotifyError, NotifyInfo} from "../../lib/notify";
import {EllipsisOutlined} from '@ant-design/icons'
import BankMaterials from "./components/BankMaterials";
import ChooseMaterialPartyWindow from "../Material/ChooseMaterialPartyWindow";
import {SynthStatuses} from "../Stages/Shared";
import {withRouter} from "react-router-dom";
import dayjs from "dayjs";

const {TabPane} = Tabs;

const cpgOptions = [
    {key: null, value: ''},
    {key: 'A', value: 'A'},
    {key: 'C', value: 'C'},
    {key: 'G', value: 'G'},
    {key: 'T', value: 'T'},
    {key: 'BHQ1', value: 'BHQ1'},
    {key: 'BHQ2', value: 'BHQ2'},
    {key: 'BHQ3', value: 'BHQ3'},
    {key: 'PO4', value: 'PO4'},
    {key: 'Alk', value: 'Alk'},
    {key: 'Uni', value: 'Uni'},
    {key: 'NH2', value: 'NH2'},
    {key: 'TriGalNac', value: 'TriGalNac'},
    {key: 'GalNac', value: 'GalNac'},
];

const poreOptions = [
    {key: null, value: ''},
    {key: '500 Å', value: '500 Å'},
    {key: '1000 Å', value: '1000 Å'},
    {key: '2000 Å', value: '2000 Å'}
];

const tritilOptions = [{key: null, value: ''}, {key: 'on', value: 'on'}, {key: 'off', value: 'off'}];
const yesNoOptions = [{key: null, value: ''}, {key: 'Да', value: 'Да'}, {key: 'Нет', value: 'Нет'}];

const cpgTypeOptions = [
    {key: null, value: ''},
    {key: 'CPG', value: 'CPG'},
    {key: 'Полистирол', value: 'Полистирол'},
];

function SynthDataManager(props) {

    const device_id = parseInt(props.props.match.params.device_id);
    const cycle_id = parseInt(props.props.match.params.cycle_id);
    const synth_date = props.props.match.params.date;

    const [synthName, setSynthName] = useState(null);
    const [data, setData] = useState([]);
    const [programs, setPrograms] = useState({});

    const [places, setPlaces] = useState([]);
    const [scales, setScales] = useState([]);
    const [currentScale, setCurrentScale] = useState(null);
    const [loading, setLoading] = useState(false);
    const [calendarId, setCalendarId] = useState(null);
    const [status, setStatus] = useState(0);

    const [showChooseMaterialWindow, setShowChooseMaterialWindow] = useState(false);
    const [currentRowIndex, setCurrentRowIndex] = useState(null);

    // мастшабы по группам {group1_id: scale, group2_id: scale, etc...}
    const [groupScales, setGroupScales] = useState({});
    const [postErrors, setPostErrors] = useState([]);
    const [cancelable, setCancelable] = useState(true);
    const [programErrors, setProgramErrors] = useState({});

    useEffect(() => {
        if (!loading) {
            setLoading(true);
            loadDeviceParameters().then((result) => {
                setSynthName(`Синтез на ${dayjs(synth_date).format('DD.MM.YYYY')} ${result.name} Запуск ${cycle_id + 1}`);
                loadData().then((_data) => {
                    setPlaces(attachDataToPlaces(result.places, _data.places));
                    setScales(_data.places.map(item => ({id: item.synthesis_device_scale_id, scale: item.scale})));
                    let _progs = {}
                    result.places.forEach(item => {
                        _progs[item.id] = item.programm || '';
                    });
                    setPrograms(_progs);
                    setData(remapData(_data.places, result.scales));
                    setCalendarId(_data['calendar_id']);
                    setStatus(_data.calendar.status);
                    if (_data.calendar.cancelable !== undefined) {
                        setCancelable(_data.calendar.cancelable);
                    }
                });
            });
        }
        document.getElementById('root').style.minWidth = '1920px';
    }, []);

    function attachDataToPlaces(places, data) {
        places.map((place) => {
            data.map((item) => {
                if (item.synthesis_device_place_id === place.id) {
                    place.calendar_id = item.id;
                    place.programm = item.program;
                }
            });
        });
        return places;
    }

    function _genRandomId() {
        return parseInt(Math.random() * 100000);
    }

    function remapData(_data) {
        let _result = [];
        let _scales = {};
        for (let _gr in _data) {
            _scales[_data[_gr]['synthesis_device_place_id']] = _data[_gr]['scale'];
            for (let _row in _data[_gr]['items']) {
                let _res_row = _data[_gr]['items'][_row];
                _res_row['group_id'] = _data[_gr]['synthesis_device_place_id'];
                _res_row['comment'] = _res_row.params?.comment;
                _res_row['type'] = getType(_res_row['types']);
                _res_row['scale'] = _data[_gr]['scale'];
                _res_row['tritil'] = _res_row.params?.tritil ? 'on' : 'off';
                _res_row['dob'] = _res_row.params?.dob === 1 ? 'Да' : 'Нет';
                _res_row['catalogue_number'] = _res_row.params?.catalogue_number;
                _res_row['batch_number'] = _res_row.params?.batch_number;
                _res_row['manufacturer'] = _res_row.params?.manufacturer;
                _res_row['material_id'] = _res_row.params?.material_id;
                _res_row['cpg'] = _res_row.params?.cpg;
                _res_row['type_cpg'] = _res_row.params?.type_cpg;
                _res_row['glass_loading'] = _res_row.params?.glass_loading;
                _res_row['old_full_sequence'] = _res_row.full_sequence;
                _res_row['full_sequence'] = _res_row.current_sequence || _res_row.full_sequence;
                _result.push(_res_row);
            }
        }
        setGroupScales(_scales);
        _result = applyEmptyRows(_result);
        return _result;
    }

    function getType(types) {
        return types.map(m => m.name).join(',');
    }

    async function loadDeviceParameters() {
        return await api.getJSON(`/api/synthesis-device/view/${device_id}`);
    }

    async function loadData() {
        return await api.getJSON(`/api/synthesis-device/calendar/${synth_date}/${device_id}/${cycle_id}`);
    }

    function deleteUndefinedFields(data) {
        for (let i in data) {
            if (data[i] === undefined) {
                delete data[i];
            } else if (typeof data[i] === 'object') {
                deleteUndefinedFields(data[i]);
            }
        }
        return data;
    }

    async function saveData() {
        setProgramErrors({});
        return new Promise((resolve, reject) => {
            const dataToSave = data.filter(item => !item._lastRow).map(({portion_id, ...rest}) => {
                console.log('rest', rest);
                let {
                    comment, order, group_id, params, tritil, cpg, type_cpg, pore_size, glass_loading, dob
                } = rest;

                params = {
                    ...params,
                    cpg, type_cpg, pore_size, glass_loading,
                    comment,
                    tritil: tritil === 'on' ? 1 : 0,
                    dob: dob === 'Да' ? 1 : 0,
                }

                return {
                    id: portion_id,
                    group_id: group_id,
                    order,
                    custom_sequence: rest.full_sequence,
                    params,
                }
            });
            const dataToSend = deleteUndefinedFields({items: dataToSave, programms: programs, scales: groupScales});
            const url = `/api/synthesis/save/${synth_date}/${device_id}/${cycle_id}`;
            api.postJSON(url, dataToSend).then(response => {
                NotifyInfo('Сохранено');
                resolve(response);
            }).catch(error => {
                NotifyError('Ошибка сохранения');
                reject(error);
            })
        });
    }

    function convertFilteredIndexesToOriginal(indexes) {
        let _filteredData = getFilteredData();
        if (typeof indexes === 'object') {
            let result = [];
            for (const index in indexes) {
                const _row = _filteredData[indexes[index]];
                result.push(data.indexOf(_row));
            }
            return result;
        } else {
            const _row = _filteredData[indexes];
            return data.indexOf(_row);
        }
    }

    /**
     * Переупорядочивание массива
     * @param dropData id элементов которые надо поставить после dropIndex
     * @param dropIndex id после которого надо поставить данные dropData
     */
    function dataDropHandler(dropData, dropIndex) {
        // старые dropData и dropIndex теряет смысл на отфильтрованных данных,
        dropData = convertFilteredIndexesToOriginal(dropData);
        dropIndex = convertFilteredIndexesToOriginal(dropIndex);

        for (let i in dropData) {
            data[dropData[i]]['group_id'] = data[dropIndex]['group_id'];
        }
        let resultIndexes = [];
        let result = [];
        for (let i = 0; i < dropIndex; i++) {
            if (!dropData.includes(i)) {
                resultIndexes.push(i);
            }
        }
        for (let i = 0; i < dropData.length; i++) {
            resultIndexes.push(dropData[i]);
        }
        for (let i = dropIndex; i < data.length; i++) {
            if (!dropData.includes(i)) {
                resultIndexes.push(i);
            }
        }
        for (let i in resultIndexes) {
            result.push(data[resultIndexes[i]]);
        }
        result = rebuildGroupIndex(result);
        setData(result);
    }

    function rebuildGroupIndex(data) {
        let _group = null;
        let groupIndex = 1;

        for (let i in data) {
            if (_group !== data[i]['group_id']) {
                _group = data[i]['group_id'];
                groupIndex = 1;
            }
            data[i]['group_index'] = groupIndex;
            groupIndex++;
        }
        return data
    }

    /**
     * Добавляет пустые строки в конце групп
     *
     * было:
     *     [
     *      {group_id: 1, data...}
     *      {group_id: 1, data...}
     *      {group_id: 2, data...}
     *      {group_id: 2, data...}
     *      ]
     * стало:
     *     [
     *      {group_id: 1, data...}
     *      {group_id: 1, data...}
     *      {group_id: 1, _last_row: true, data...}  <-- добавлено
     *      {group_id: 2, data...}
     *      {group_id: 2, data...}
     *      {group_id: 2, _last_row: true, data...} <-- добавлено
     *     ]
     *
     * Пустые строки в конце каждой группы нужны чтобы на них можно было перетаскивать
     * данные из других групп
     *
     * @param _data
     * @returns []
     */
    function applyEmptyRows(_data) {
        let _result = [];
        let _groups = {};
        _data.forEach(item => _groups[item.group_id] = {id: item.group_id, scale: item.scale, group_id: item.group_id});
        if (!_groups) {
            return _data;
        }
        // удаляем старые _last_row
        _data = _data.filter(item => !item['_lastRow']);

        for (let _gr in _groups) {
            let _result_by_group = _data.filter(item => item.group_id === _groups[_gr].group_id);
            const _last_row = {
                id: _genRandomId(),
                _lastRow: true, name: 'last row', scale: _groups[_gr]['scale'], group_id: _groups[_gr]['id']
            }
            _result_by_group.push(_last_row);
            _result = [..._result, ..._result_by_group];
        }
        return _result;
    }

    function cmp(a, b, reverseSorting = false) {
        if (reverseSorting) {
            a = a.split('').reverse().join('');
            b = b.split('').reverse().join('');
        }
        return a > b ? 1 : -1
    }

    function sortBy(columnName, sortDirection, reverseSorting = false) {
        // берем данные только одного масштаба
        function _getColumnsCount(_data) {
            let _result = {};
            _data.map(item => {
                if (_result[item.group_id])
                    _result[item.group_id]++;
                else
                    _result[item.group_id] = 1;
            });
            return _result;
        }

        // берем отфильтрованные данные
        let _data = getFilteredData();

        // запоминаем сколько олигов было в какой колонке
        const columnsCount = _getColumnsCount(_data);
        _data = _data.filter(item => !item._lastRow);

        // вычленяем id этих записей
        let _data_ids = _data.map(item => item.id);
        if (_data.length) {
            // сортируем
            let ordered = [];
            if (sortDirection === 'asc') {
                ordered = _data.sort((a, b) => {
                    if (a.group_id === b.group_id) {
                        return cmp(a[columnName], b[columnName], reverseSorting);
                    } else {
                        return a.group_id - b.group_id;
                    }
                })
            } else {
                ordered = _data.sort((a, b) => {
                    if (a.group_id === b.group_id) {
                        return cmp(b[columnName], a[columnName], reverseSorting);
                    } else {
                        return a.group_id - b.group_id;
                    }
                })
            }
            // берем все данные без фильтра
            let newData = [...data];
            // удаляем те записи, которые были отсортированы
            newData = newData.filter(item => !_data_ids.includes(item.id))

            // восстанавливаем исходные количества олигов в банках
            if (columnsCount) {
                let startIndex = 0;
                for (const groupId in columnsCount) {
                    for (let j = 0; j < columnsCount[groupId] - 1; j++) {
                        ordered[startIndex++]['group_id'] = parseInt(groupId);
                    }
                }
            }
            // добавляем новые записи в начало, в отсортированном виде
            newData = [...ordered, ...newData];
            newData = applyEmptyRows(newData);
            setData(newData);
        }
    }

    function getPlaceName(group_id, group_index) {
        const place = places.find(item => item.id === group_id);
        if (place?.place_names[group_index - 1]) {
            return place.place_names[group_index - 1];
        } else {
            return group_index;
        }
    }

    function renderYellowColumn(value, col, rowIndex, colIndex, rawData) {
        const width = (col.props.width + 'px') || "100px";
        return <div key={colIndex} className="cell system" style={{width: width}}>{getPlaceName(rawData.group_id,
            rawData.group_index)}</div>;
    }

    function onChangeData(rowIndex, changedData, oldData) {
        // rowIndex теряет смысл при отфильтрованных данных, поэтому ищем по строй записи
        rowIndex = data.indexOf(oldData);
        let _data = [...data];
        _data[rowIndex] = {..._data[rowIndex], ...changedData};
        setData(_data);
    }

    function onCopy(buffer, rawData) {
        if (buffer['batch_info'] === null || buffer['batch_info']) {
            if (!buffer['params']) {
                buffer['params'] = {};
            }
            buffer['params']['batch_number'] = rawData['params']['batch_number'];
            buffer['params']['catalogue_number'] = rawData['params']['catalogue_number'];
            buffer['params']['manufacturer'] = rawData['params']['manufacturer'];
            buffer['params']['material_id'] = rawData['params']['material_id'];
            buffer['params']['material_name'] = rawData['params']['material_name'];
            delete buffer['batch_info'];
        }
        return buffer;
    }

    function onPaste(pastedData) {
        let _data = [...data]
        for (let i in pastedData) {
            _data[i] = {..._data[i], ...pastedData[i]};
        }
        setData(_data);
    }


    function renderProgram(group, groupIndex) {
        function handleChangeProgram(e) {
            let _programs = {...programs};
            _programs[group.props.group_id] = e.target.value;
            setPrograms(() => _programs);
            setTimeout(() => {
                validatePrograms();
            }, 50)
        }

        const _class = programErrors[group.props.group_id] ? 'error' : '';
        return <span>
            <Input style={{width: '200px'}} rootClassName={_class} onChange={handleChangeProgram} className={_class}
                   value={programs[group.props.group_id] || ''}/>
        </span>;
    }

    const renderActionButtons = () => {
        switch (status) {
            case SynthStatuses.NONE:
            default:
                return <Button className={"ant-btn"} onClick={() => {
                    changeStatus(SynthStatuses.FILLING_STARTED)
                }}>Начать заполнение
                </Button>;
            case SynthStatuses.FILLING_STARTED:
                return <Space direction={'horizontal'}>
                    <Button onClick={() => {
                        changeStatus(SynthStatuses.NONE);
                    }}>Отменить заполнение
                    </Button>
                    <Button onClick={() => {
                        saveData();
                    }}>Сохранить как черновик
                    </Button>
                    <Button onClick={() => {
                        if (postValidate()) {
                            saveData().then(() => {
                                changeStatus(SynthStatuses.SYNTH_STARTED);
                                NotifyInfo('Синтез начат');
                            });
                        } else {
                            NotifyError('Не все поля заполнены либо неправильно заполнены');
                        }
                    }}>Начать синтез
                    </Button>
                </Space>;
            case SynthStatuses.SYNTH_STARTED:
                return <Space><Button onClick={() => {
                    changeStatus(SynthStatuses.FILLING_STARTED)
                }} disabled={!cancelable}>
                    Отменить синтез
                </Button>
                    <Button type={'primary'} onClick={() => {
                        window.location.href = `/synth-end/${synth_date}/${device_id}/${cycle_id}`;
                    }}>Завершить синтез</Button>
                </Space>
        }
    }

    function validatePrograms() {
        setProgramErrors({});
        // get unique group_ids from data
        let groups = [...new Set(data.map(item => item.group_id))];

        let _progErrors = {};
        groups.forEach(group => {
            _progErrors[group] = programs[group] === undefined || programs[group] === null || programs[group] === '';
        });
        setProgramErrors(_progErrors);
        return Object.values(_progErrors).filter(item => item).length === 0;
    }

    function validateBanks() {
        // проверка на то что все банки содержат количество
        // пробирок не больше чем может вместить аппарат
        let _groups = {};
        data.forEach(item => {
            if (!item._lastRow) {
                if (_groups[item.group_id]) {
                    _groups[item.group_id]++;
                } else {
                    _groups[item.group_id] = 1;
                }
            }
        });
        let _errors = [];
        places.forEach(item => {
            if (_groups[item.id] && _groups[item.id] > item.places_count) {
                _errors.push(`${item.name} содержит ${_groups[item.id]} пробирок (максимум  ${item.places_count})`);
            }
        });
        if (_errors.length) {
            NotifyError(_errors.join(', '));
            return false;
        }
        return true;
    }

    function postValidate() {
        setPostErrors([]);
        let _postErrors = [];
        data.forEach((row, rowIndex) => {
            if (row._lastRow) {
                return;
            }
            if (!row.tritil) {
                _postErrors.push({row: rowIndex, column: 'tritil', messages: ['Не указано']});
            }
            if (!row.cpg) {
                _postErrors.push({row: rowIndex, column: 'cpg', messages: ['Не указано']});
            }
            if (!row.type_cpg) {
                _postErrors.push({row: rowIndex, column: 'type_cpg', messages: ['Не указано']});
            }
            if (row.type_cpg === 'CPG' && !row.pore_size) {
                _postErrors.push({row: rowIndex, column: 'pore_size', messages: ['Не указано']});
            }
            if (row.type_cpg === 'CPG' && !row.glass_loading) {
                _postErrors.push({row: rowIndex, column: 'glass_loading', messages: ['Не указано']});
            }
            if (!row.params?.material_id) {
                _postErrors.push({row: rowIndex, column: 'batch_info', messages: ['Не указано']});
            }
        });
        setPostErrors(_postErrors);
        const programsValid = validatePrograms();
        const banksValid = validateBanks();
        return _postErrors.length === 0 && programsValid && banksValid;
    }

    /**
     * Перемена местами групп (банков)
     *
     * @param placeId1 - по сути group_id, только в таблице places
     * @param placeId2 - то же самое
     */
    function swapPlaces(placeId1, placeId2) {
        let _data = [...data];
        // убираем пустые строки (которые нужны для помечания последней строки группы, чтобы туда перетаскивать)
        _data = _data.filter(item => !item._lastRow);
        // берем данные групп по отдельности
        let _data1 = _data.filter(item => parseInt(item.group_id) === parseInt(placeId1));
        let _data2 = _data.filter(item => parseInt(item.group_id) === parseInt(placeId2));

        // сохраним позиции элементов в группах, для последующей сортировки
        const _positions = {};
        _data1.forEach((item, index) => _positions[item.id] = index);
        _data2.forEach((item, index) => _positions[item.id] = index);

        // берем остальные данные, которые не входят в эти группы
        let _rest = _data.filter(item => parseInt(item.group_id) !== parseInt(placeId1) && parseInt(item.group_id) !== parseInt(placeId2));
        // меняем группы местами
        _data1 = _data1.map(item => {
            item.group_id = parseInt(placeId2);
            return item;
        });
        _data2 = _data2.map(item => {
            item.group_id = parseInt(placeId1);
            return item;
        });
        // формируем новый массив
        _data = [..._data1, ..._data2, ..._rest];

        // сортируем по группам
        // сортировка сначала по группе, потом по позиции _positions
        _data = _data.sort((a, b) => {
            if (a.group_id === b.group_id) {
                return _positions[a.id] - _positions[b.id];
            } else {
                return a.group_id - b.group_id;
            }
        });
        // восстанавливаем пустые строки и индексы групп
        _data = applyEmptyRows(rebuildGroupIndex(_data));
        setData(_data);

        // меняем местами масштабы групп
        let _groupScales = {...groupScales};
        let tmp = _groupScales[placeId1];
        _groupScales[placeId1] = _groupScales[placeId2];
        _groupScales[placeId2] = tmp;
        setGroupScales(_groupScales);

        // меняем местами программы
        let _programs = {...programs};
        let tmp_program = _programs[placeId1];
        _programs[placeId1] = _programs[placeId2];
        _programs[placeId2] = tmp_program;
        setPrograms(_programs);
    }

    function renderGroupHeader(group, groupIndex) {
        // берем названия групп для выпадающего списка
        let groups = places.map((place) => {
            return {key: place.id, label: place.name}
        });
        // исключить текущую группу из списка
        groups = groups.filter(item => item.key !== group.props.value);

        return <div className={"group-header"}><strong>{group.props.name}</strong>
            <span style={{marginLeft: 100}}>Масштаб: {group.props.scale}</span>
            <span style={{marginLeft: 100}}>Программа синтеза: {renderProgram(group, groupIndex)}</span>
            <span style={{marginLeft: 10}}>
                <Dropdown overlay={<Menu items={groups} onClick={(id) => {
                    swapPlaces(group.props.value, id.key);
                }}/>} trigger={['click']}
                          children={<Button title={"Переместить в другой банк"}>&#8645;</Button>}>
                </Dropdown>
            </span>
            {/*<span style={{marginLeft: 100}}>GroupId: {group.props.group_id}</span>*/}
        </div>
    }

    function renderGroups() {
        let result = places.map((place, index) => {
            return <Group key={index} name={place.name} scale={groupScales[place.id]} count={place.places_count}
                          field={'group_id'}
                          group_id={place.id}
                          value={place.id}
                          calendar_id={place.calendar_id}
                          programm={place.programm}
            />
        });
        return result;
    }

    function applyRowOrder() {
        let last_group = null;
        let counter = null;
        data.forEach((d) => {
            if (d.group_id !== last_group) {
                counter = 1;
                last_group = d.group_id;
            }
            d.order = counter++;
        });
        return data;
    }

    function getFilteredData() {
        let result = rebuildGroupIndex(applyRowOrder());
        return currentScale ? result.filter(item => item.scale === currentScale) : result;
    }

    // TODO перенести в utils
    const sortByFirstNumber = (a, b) => {
        const regexp = /(\d+)/;
        const a_num = a.match(regexp)[0];
        const b_num = b.match(regexp)[0];
        return a_num - b_num;
    }

    const extraTabs = () => {
        const uniqueScales = [...new Set(data.map(item => item.scale))];
        uniqueScales.sort(sortByFirstNumber);

        return <Space align="start">{uniqueScales.map((item, index) => {
            function switchScale(scale) {
                (currentScale === scale) ? setCurrentScale(null) : setCurrentScale(scale);
            }

            let type = item === currentScale ? 'primary' : null;
            const has_scale = true; // data.filter(sc_item => sc_item === item).length > 0;
            return has_scale ? <Button key={index} type={type}
                                       onClick={() => switchScale(item)}>{item}</Button> : null;
        })}</Space>
    }

    function generateComboEditor(col, options) {
        return function (callback, value, indexRow, colName, oldData) {
            function onChange(item) {
                if (callback && item) {
                    let v = {};
                    v[col] = item;
                    callback(indexRow, v, oldData)
                }
            }

            return <Select onClick={e => e.stopPropagation()} value={value} options={options}
                           onChange={onChange}></Select>
        }
    }

    async function validateSequence(value) {
        return new Promise((resolve, reject) => {
            api.postJSON('/api/oligonucleotid/validate', {sequence: value}, {notifyErrors: false}).then((response) => {
                let result = null;
                if (response.errors && response.errors.sequence && response.errors.sequence[0]) {
                    result = response.errors.sequence[0];
                }
                resolve(result);
            }).catch((error) => {
                let result = null;
                if (error.errors && error.errors.sequence && error.errors.sequence[0]) {
                    result = error.errors.sequence[0];
                }
                resolve(result);
            });
        });
    }

    function changeStatus(status) {
        api.getJSON(`/api/synthesis-device/calendar/${synth_date}/${device_id}/${cycle_id}/set-status/${status}`).then((response) => {
            setStatus(status);
            if (status === SynthStatuses.SYNTH_DONE) {
                props.history.push(`/synth-end/${synth_date}/${device_id}/${cycle_id}`);
            }
        }).catch((error) => {
            NotifyError('Ошибка изменения статуса');
        });
    }

    return <>
        <PageHeader title={synthName} showFavorite={false}/>
        <Tabs rootClassName={'tabs-20'}>
            <TabPane key={1} tab={'Ячейки'}>
                <SynthTable data={getFilteredData()} draggable={true} onDataDrop={dataDropHandler} onSort={sortBy}
                            onChangeData={onChangeData}
                            onCopy={onCopy}
                            onPaste={onPaste}
                            groupHeaderRenderer={renderGroupHeader}
                            groups={renderGroups()} // <-- раньше группы были в виде тегов, потом упаковал в параметр чтобы
                            extraTabs={extraTabs()}   // при обновлении была перерисовка
                            onSave={saveData}
                            deviceId={device_id}
                            cycleId={cycle_id}
                            synthDate={synth_date}
                            postErrors={postErrors}
                            renderActionButtons={renderActionButtons}
                >
                    <Column width={60} title={"№"} name={'group_index'} render={renderYellowColumn}/>
                    {/*<Column width={60} title={"ID"} name={'id'}/>*/}
                    <Column name={'scale'} title={"Масштаб"} width={80}/>
                    <Column name={'name'} title={"Название"} width={150} sorted filtered/>
                    <Column name={'full_sequence'}
                            title={"Последовательность"}
                        // Показываем в подсказке old_full_sequence, которое при изменении данных тоже меняется
                        // в onValidValue
                            bgcolor={(data, column) => {
                                if (data.old_full_sequence !== data.full_sequence) {
                                    return '#dbeaff';
                                }
                            }}
                            tooltip={(index, name, data, column) => {
                                return data.old_full_sequence;
                            }}
                            width={300} sorted filtered reverseSorting editable
                            direction={'rtl'}
                            validator={validateSequence}
                    />
                    <Column name={'length'} title={"Длина"} width={70} sorted filtered/>
                    <Column name={'type'} title={"Тип"} width={120} sorted filtered/>
                    <Column name={'comment'} title={"Комментарий"} width={120} sorted filtered/>
                    <Column name={'tritil'} title={"Тритил"} width={65} editable filtered
                            filterOptions={tritilOptions} editor={generateComboEditor('tritil', tritilOptions)}
                    />
                    <Column name={'cpg'} title={"Носитель"} editable width={85} sorted filtered
                            filterOptions={cpgOptions} editor={generateComboEditor('cpg', cpgOptions)}/>
                    <Column name={'type_cpg'} title={"Тип носителя"} editable width={110} sorted filtered
                            filterOptions={cpgTypeOptions} editor={generateComboEditor('type_cpg', cpgTypeOptions)}/>
                    />
                    <Column name={'pore_size'} title={"Размер пор"} editable width={100} sorted filtered
                            filterOptions={poreOptions} editor={generateComboEditor('pore_size', poreOptions)}
                    />
                    <Column name={'glass_loading'} title={
                        <div title={'Загрузка носителя, мкмоль/г'}>Загрузка носителя</div>
                    } editable width={150} sorted filtered/>
                    {/*Не менять название batch_info - оно используется при копировании ячейки */}
                    <Column name={'batch_info'} title={"Материал"} width={240} copyable
                            format={(value, column, rowIndex, colIndex, dataRow) => {
                                let tmp_result = [];
                                let result = '';
                                if (dataRow?.params?.material_name) {
                                    const prod_date = dayjs(dataRow.params.production_date).format('DD.MM.YYYY');
                                    tmp_result.push(`${dataRow.params.material_name || ''}`)
                                    if (dataRow['catalogue_number']) {
                                        tmp_result.push(`${dataRow.params?.catalogue_number || ''}`)
                                    }
                                    if (dataRow['batch_number']) {
                                        tmp_result.push(`${dataRow.params?.batch_number || ''}`)
                                    }
                                    if (dataRow['manufacturer']) {
                                        tmp_result.push(`${dataRow.params?.manufacturer || ''}`)
                                    }
                                    tmp_result.push(`${prod_date || ''}`)
                                    result = tmp_result.join(', ')
                                }
                                return result;
                            }}
                    />

                    <Column name={'material_choose'} title={"..."} width={30}
                            render={(name, col, indexRow, indexCol, rowData) => {
                                return <Button key={`btn_${indexRow}`} size={'small'} shape={'circle'}
                                               icon={<EllipsisOutlined/>}
                                               style={{border: 'none', marginTop: 4, width: 20}}
                                               onClick={(e) => {
                                                   setCurrentRowIndex(indexRow);
                                                   setShowChooseMaterialWindow(true);
                                               }}
                                ></Button>
                            }}/>
                    <Column name={'dob'} title={"Доб"} width={65} editable filtered
                            filterOptions={yesNoOptions} editor={generateComboEditor('dob', yesNoOptions)}
                    />
                </SynthTable>
                {showChooseMaterialWindow && <ChooseMaterialPartyWindow
                    onCancel={() => setShowChooseMaterialWindow(false)}
                    type={'radio'}
                    onOk={(ids, materials) => {
                        setShowChooseMaterialWindow(false);
                        let newData = [...data];
                        if (materials) {
                            let material = materials[0];
                            if (!newData[currentRowIndex].params) {
                                newData[currentRowIndex].params = {};
                            }
                            newData[currentRowIndex].params.material_id = material.id;
                            newData[currentRowIndex].params.material_name = material.name;
                            newData[currentRowIndex].params.catalogue_number = material.catalogue_number;
                            newData[currentRowIndex].params.batch_number = material.batch_number;
                            newData[currentRowIndex].params.manufacturer = material.manufacturer;
                            setData([...newData]);
                        }
                    }}
                    onDetach={() => {
                        let newData = [...data];
                        if (!newData[currentRowIndex].params) {
                            newData[currentRowIndex].params = {};
                        }
                        newData[currentRowIndex].params.material_id = null;
                        newData[currentRowIndex].params.material_name = null;
                        newData[currentRowIndex].params.catalogue_number = null;
                        newData[currentRowIndex].params.batch_number = null;
                        newData[currentRowIndex].params.manufacturer = null;
                        setData(newData);
                    }}
                />
                }
            </TabPane>

            <TabPane key={2} tab={'Банки и сырье'} style={{backgroundColor: '#FFF'}}>
                <BankMaterials deviceId={device_id} cycleId={cycle_id} synthDate={synth_date}/>
            </TabPane>
        </Tabs>
    </>;
}

function SynthesisDetails(props) {
    return (
        <div style={{margin: '0 auto', width: '100%'}}>
            <SynthDataManager props={props}/><br/>
        </div>
    );
}

export default withRouter(SynthesisDetails);

