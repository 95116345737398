import {Button, Col, Dropdown, Menu, Modal, Row, Spin, Table} from "antd";
import PageHeader from "../../components/mock/PageHeader";
import React, {useEffect, useState} from "react";
import api, {getBearerAuthHeader} from "../../lib/util";
import dayjs from "dayjs";
import ShipmentEdit from "./ShipmentEdit";
import {DownOutlined} from "@ant-design/icons";
import {NotifyError, NotifySuccess} from "../../lib/notify";
import {FINISHED, IN_PROCESS, orderColumns, orderedColumns, templates} from "./ShipmentShared";
import ShipmentView from "./ShipmentView";
import usePermissions from "../../lib/Permissions";


const ShipmentDetails = (props) => {

    const [shipment, setShipment] = useState({});

    const [orderInfo, setOrderInfo] = useState([]);
    const [orderInfoOriginal, setOrderInfoOriginal] = useState({});
    const [createShipment, setCreateShipment] = useState(false);
    const [editShipment, setEditShipment] = useState(false);
    const [viewShipment, setViewShipment] = useState(false);
    const [ordered, setOrdered] = useState([]);
    const [assigned, setAssigned] = useState([]);
    const [shipments, setShipments] = useState([]);
    const [loading, setLoading] = useState(false);

    const {canEdit} = usePermissions();

    const shipmentColumns = [
        {id: 'id', title: 'Номер паспорта', dataIndex: 'passport_num', width: 200},
        {
            id: 'passport_date', title: 'Дата оформления паспорта', dataIndex: 'passport_date', width: 200,
            render: (value) => dayjs(value).format('LL')
        },
        {
            id: 'shipment_date', title: 'Дата отгрузки', dataIndex: 'shipment_date', width: 200,
            render: (value) => dayjs(value).format('LL')
        },
        {
            id: 'edit',
            title: 'Редактирование',
            dataIndex: 'edit',
            width: 300,
            render: (text, record) => {
                return <>
                    <Button style={{marginRight: 10}} onClick={() => {
                        setShipment(() => record);
                        setViewShipment(true);
                    }}>Просмотр</Button>
                    <Button onClick={() => {
                        setShipment(record);
                        setEditShipment(true);
                    }} disabled={!canEdit}>Редактировать</Button></>
            }
        },
        {
            id: 'download', title: 'Скачать паспорт', dataIndex: 'download', width: 200, render: (text, record) => {
                // хз почему-то выдает ошибку
                // return <Dropdown menu={{
                //     items: templates,
                //     onClick: (item) => downloadPassport(record.id, item.key)
                // }}><><Button><Space>Скачать паспорт<DownOutlined/></Space></Button></>
                // </Dropdown>
                // сделал через overlay
                let menu =
                    <Menu>
                        {templates.map((item, index) => {
                            return <Menu.Item key={index}
                                              onClick={() => downloadPassport(record.id, item.key)}>{item.label}</Menu.Item>
                        })}
                    </Menu>;
                return <Dropdown overlay={menu}><Button icon={<DownOutlined/>}>Скачать паспорт</Button></Dropdown>;
            }
        },
        {
            id: 'delete', title: 'Удалить', dataIndex: 'delete', width: 200, render: (text, record) => {
                return <Button danger disabled={!canEdit}
                               onClick={() => deleteShipment(record.id)}>Удалить</Button>;
            }
        }
    ];

    const downloadPassport = (id, template_id) => {
        // не переделывать в api.getJSON!
        const params = {
            method: 'GET',
            headers: {}
        };
        params.headers = {
            ...params.headers,
            ...getBearerAuthHeader(),
        }
        fetch(`/api/shipment/download/${id}/${template_id}`, params).then(response => {

            const header = response.headers.get('Content-Disposition');
            const filename = header.match(/filename=(.+)/)[1].replaceAll('"', '');
            response.blob().then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
        });
    }

    const loadOriginalOrder = () => {
        return new Promise((resolve, reject) => {
            const id = props.match.params?.id;
            api.getJSON(`/api/order/${id}`).then((resp) => {
                if (resp) {
                    setOrderInfoOriginal(resp);
                }
            }).finally(() => {
                resolve();
            });
        });
    }

    const map = data => {
        const mappedData = data.map(item => {
            const roundValue = 1000;
            const e260 = item.current_oligonucleotide?.e260 ? (Math.round(item.current_oligonucleotide.e260 * roundValue) / roundValue) : (item.oligonucleotid?.e260 ? Math.round(item.oligonucleotid?.e260 * roundValue) / roundValue : '-');
            const mw = item.current_oligonucleotide?.mw ? Math.round(item.current_oligonucleotide.mw * roundValue) / roundValue : (item.oligonucleotid?.mw ? Math.round(item.oligonucleotid?.mw * roundValue) / roundValue : '-');
            item = {
                ...item,
                olig_sequence: item.oligonucleotid?.sequence || '-',
                full_sequence: item.oligonucleotid?.full_sequence || '-',
                work_sequence: item.current_oligonucleotide?.full_sequence || '-',
                target_sequence: item.oligonucleotid?.full_sequence || '-',
                e260,
                mw,
            };
            return item;
        });
        return mappedData;
    }


    const loadData = () => {
        return new Promise((resolve, reject) => {
            const id = props.match.params?.id;
            api.getJSON(`/api/shipment/order/${id}`).then((resp) => {
                if (resp) {
                    setOrderInfo([{
                        id: resp.id,
                        created_at: resp.created_at,
                        organization: resp.user?.organization,
                        priority: resp.priority,
                        order_type: resp.order_type,
                        order_status: resp.order_status,
                        order_number: resp.order_number,
                        revision_status_id: resp.revision_status_id,
                    }]);
                    resp.ordered = resp.ordered.map((item, index) => {
                        item.index = index + 1;
                        return item;
                    });
                    setOrdered(resp.ordered);
                    setAssigned(map(resp.assigned));
                    setShipments(resp.shipments);
                }

            }).finally(() => {
                resolve();
            });
        });
    }

    const update = () => {
        setLoading(true);
        Promise.all([loadData(), loadOriginalOrder()]).then(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        update();
    }, []);

    function getAssigned() {
        return assigned.filter((item) => !item.is_shipped);
    }

    const deleteShipment = (shipment_id) => {
        Modal.confirm({
            title: "Внимание",
            content: "Вы уверены, что хотите удалить отгрузку?",
            onOk: () => {
                api.requestJSON('DELETE', `/api/shipment/delete/${shipment_id}`, null).then((response) => {
                    update();
                    NotifySuccess("Отгрузка удалена");
                })
                    .catch((error) => {
                        NotifyError("Ошибка при удалении отгрузки");
                    });
            }
        });
    }

    const changeOrderStatus = (order_status_id) => {

        const message = order_status_id === FINISHED ? 'Вы уверены, что хотите завершить заказ?' : 'Вы уверены, что хотите вернуть заказ в производство?'

        Modal.confirm({
            title: "Внимание",
            content: message,
            onOk: () => {
                const id = props.match.params?.id;
                api.postJSON(`/api/order/change-status/${id}`, {order_status_id,}).then(
                    () => {
                        update();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            onCancel: () => {
            },
        });
    };

    const endCheck = () => {
        Modal.confirm({
            title: "Внимание",
            content: "Вы уверены, что хотите завершить сверку?",
            onOk: () => {
                api.requestJSON('POST', `/api/order/check/${orderInfoOriginal?.id}`, null).then((response) => {
                    update();
                    NotifySuccess("Сверка завершена");
                })
                    .catch((error) => {
                        NotifyError("Ошибка при завершении сверки");
                    });
            }
        });
    }

    const cancelCheck = () => {
        Modal.confirm({
            title: "Внимание",
            content: "Вы уверены, что хотите отменить сверку?",
            onOk: () => {
                api.requestJSON('POST', `/api/order/cancel-check/${orderInfoOriginal?.id}`, null).then((response) => {
                    update();
                    NotifySuccess("Сверка отменена");
                })
                    .catch((error) => {
                        NotifyError("Ошибка при отмене сверки");
                    });
            }
        });
    }

    const title = `Отгрузки по заказу №${orderInfoOriginal?.order_number}`;

    const spin = <div
        style={{width: '100%', height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Spin/>
    </div>


    return loading ? spin : <div>
        <PageHeader title={title} showFavorite={false}/>
        <div style={{background: '#fff', padding: 24, minHeight: 280}}>
            <Row>
                <Col span={24}>
                    <Table columns={orderColumns} size={'small'} dataSource={orderInfo} rowKey={'id'}
                           pagination={false}/>
                </Col>
            </Row>
            <Row style={{marginTop: 20}}>
                <Col span={24}>
                    Отгрузки
                    <Row style={{display: 'flex', marginTop: 20}} gutter={24}>
                        <Col>
                            <Table columns={shipmentColumns} size={'small'} dataSource={shipments} rowKey={'id'}
                                   pagination={false}/>
                        </Col>
                        <Col>
                            <Row style={{marginBottom: 5}}>
                                <Button type={'primary'}
                                        disabled={getAssigned().length === 0}
                                        onClick={() => {
                                            setCreateShipment(true);
                                        }}
                                        disabled={!canEdit}
                                >Создать отгрузку</Button>
                            </Row>
                            {orderInfoOriginal?.revision_status_id === 2 && <Row>
                                <Button type={'primary'} disabled={!canEdit}
                                        onClick={() => endCheck()}>Завершить сверку</Button>
                            </Row>}
                            {orderInfoOriginal?.revision_status_id === 3 && <Row>
                                <Button type={'primary'} disabled={!canEdit}
                                        onClick={() => cancelCheck()}>Отменить сверку</Button>
                            </Row>}
                        </Col>
                        <Col>
                            {orderInfoOriginal?.order_status_id !== FINISHED && <Button type={'primary'}
                                                                                        disabled={!canEdit}
                                                                                        onClick={() => changeOrderStatus(FINISHED)}>Завершить
                                заказ</Button>}
                            {orderInfoOriginal?.order_status_id === FINISHED && <Button type={'primary'}
                                                                                        disabled={!canEdit}
                                                                                        onClick={() => changeOrderStatus(IN_PROCESS)}>
                                Вернуть заказ в производство</Button>}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{marginTop: 20, marginBottom: 20}}>
                <Col span={24}>
                    Состояние пробирок в заказе
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table columns={orderedColumns} size={'small'} dataSource={ordered} rowKey={'id'}
                           pagination={false}/>
                </Col>
            </Row>
        </div>
        {/* Появляется при создании отгрузки (разница в параметрах) */}
        {createShipment &&
            <ShipmentEdit
                onClose={(isCanceled = false) => {
                    setShipment({});
                    setCreateShipment(false);
                    if (!isCanceled) {
                        update();
                    }
                }}
                data={shipment}
                assigned={getAssigned()}
                orderInfo={orderInfo}
                onOk={() => {
                    setCreateShipment(false);
                    update();
                }}
            />}

        {/* Появляется при редактировании отгрузки (разница в параметрах) */}
        {editShipment &&
            <ShipmentEdit
                onClose={(isCanceled = false) => {
                    setEditShipment(false);
                    setShipment({});
                    if (!isCanceled) {
                        update();
                    }
                }}
                data={shipment}
                assigned={getAssigned()}
                orderInfo={orderInfo}
                onOk={() => {
                    setEditShipment(false);
                    update();
                }}
            />}
        {/* Появляется при редактировании отгрузки (разница в параметрах) */}
        {viewShipment &&
            <ShipmentView
                onClose={(isCanceled = false) => {
                    setViewShipment(false);
                    setShipment({});
                    if (!isCanceled) {
                        update();
                    }
                }}
                data={shipment}
                orderInfo={orderInfo}
                assigned={[]}
                onRefresh={() => {
                    update();
                }}
            />}

    </div>;
}

export default ShipmentDetails;
