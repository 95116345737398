import {Col, Modal, Row, Select, Space, Input} from "antd";
import {DeblockTable} from "./DeblockTable";
import {useEffect, useState} from "react";
import {OperationsApi, ServerOperations} from "../Operatoins";
import MaterialParties from "../../Material/MaterialParties";

export function RemoveMalonate(props) {

    const [notes, setNotes] = useState();
    const [materials, setMaterials] = useState([]);
    const [portionsToSend, setPortionsToSend] = useState({});

    function onOk() {
        const ids = materials.map(item => item.id);
        const portions = portionsToSend.map(item => {
            item.params.materials = ids;
            return item;
        });
        OperationsApi.doOperation(ServerOperations.REMOVE_MALONATE, portions).then((result) => {
            props.onHide();
        });
    }

    function updateData() {
        const dataToSend = props.data.map(item => ({
            id: item.id, params: {
                notes
            }
        }));
        setPortionsToSend(dataToSend);
    }

    function OKDisabled() {
        return false;
    }

    useEffect(() => {
        updateData();
    }, [notes, materials]);

    return <Modal
        title="Снятие малоната"
        okButtonProps={{disabled: OKDisabled()}}
        open={true}
        width={1300}
        onOk={onOk}
        onCancel={props.onHide}
        okText={'OK'}
        cancelText={'Отмена'}
        destroyOnClose={true}
    >
        <Row>
            <Col span={24}>
                <Space direction={'horizontal'} align={'start'}>
                    <Space size={14} direction="vertical" style={{width: 400}}>
                        <div>Примечание</div>
                        <Input.TextArea value={notes} onChange={(e) => setNotes(e.target.value)}/>
                    </Space>
                </Space>
            </Col>
        </Row>
        <Row>
            <Col span={24} style={{marginTop: 20}}>
                <MaterialParties onChange={(v) => {
                    setMaterials(v);
                }} initialValue={materials}/>
            </Col>
        </Row>
        <Row>
            <Col span={24} style={{marginTop: 20}}>
                <DeblockTable dataSource={props.data}/>
            </Col>
        </Row>
    </Modal>

}
