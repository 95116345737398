import React, {Component} from 'react';
import {Row, Col, Button, Input, Form} from "antd"
import PageHeader from "../../components/mock/PageHeader";
import {SaveOutlined} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import moment from 'moment';
import api, {getBearerAuthHeader} from "../../lib/util";
import 'moment/locale/ru';

moment.locale('ru');

const layout = {
    labelCol: {span: 7},
    wrapperCol: {span: 17},
};

class OligonucleotideCreate extends Component {

    state = {
        item: {},
        loading: false,
    };

    formRef = React.createRef();

    componentDidMount() {
    }

    handleSubmit(values) {
        // не переделывать в api.postJSON!
        const params = {
            method: 'POST', body: JSON.stringify(values), headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };
        params.headers = {
            ...params.headers,
            ...getBearerAuthHeader(),
        }
        fetch(`/api/oligonucleotid/store`, params)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.message && result.errors) {
                        for (const [key, value] of Object.entries(result.errors)) {
                            let keys = key.split('.');
                            this.formRef.current.setFields([
                                {
                                    name: (keys.length > 1) ? [keys[0], parseInt(keys[1]), keys[2]] : key,
                                    errors: [value]
                                },
                            ]);
                        }
                    } else {
                        this.props.history.push("/oligs");
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        loading: false,
                        error
                    });
                }
            )
    }

    render() {
        const {items, loading, total} = this.state;

        return (

            <React.Fragment>
                <PageHeader
                    title="Создание олигонуклеотида"
                />
                <Form
                    ref={this.formRef}
                    {...layout}
                    preserve={false}
                    name="basic"
                    initialValues={{remember: true}}
                    style={{background: '#fff', padding: 24, minHeight: 280}}
                    onFinish={(values) => {
                        this.handleSubmit(values);
                    }}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label="Название"
                                name="name"
                                rules={[{required: true, message: 'Поле "Название" обязательно'}]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="Последовательность"
                                name="sequence"
                                rules={[{required: true, message: 'Поле "Последовательность" обязательно'}]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="Комментарий"
                                name="comment"
                                rules={[{required: false}]}
                            >
                                <TextArea/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col offset={22} style={{paddingTop: '20px'}}>
                            <Form.Item>
                                <Button type="primary" icon={<SaveOutlined/>} htmlType="submit">Сохранить</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }
}

export default OligonucleotideCreate;

