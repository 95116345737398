import React, {useEffect, useState} from "react";
import {Button, Col, Input, Popover, Row, Space, Table, Tag} from "antd";
import PageHeader from "../../components/mock/PageHeader";
import {CheckCircleOutlined, EditOutlined} from "@ant-design/icons";
import api from "../../lib/util";
import {NotifyError, NotifyInfo} from "../../lib/notify";
import {Link} from "react-router-dom";
import {Defect} from "../Stages/Operations/Defect";
import {renderSequence} from "../Stages/Shared";
import dayjs from "dayjs";

function EditableCell(props) {

    const [editMode, setEditMode] = useState(false);
    const [value, setValue] = useState(props.value);

    function handleChange(e) {
        setValue(e.target.value);
        if (props.onChange) props.onChange(e.target.value, props.index, props.record);
    }

    function handleKeyDown(e) {
        if (e.keyCode === 13 || e.keyCode === 27) {
            setEditMode(false);
        }
    }

    return editMode ? <Input onKeyDown={handleKeyDown} autoFocus={true} value={value} onChange={handleChange}/> :
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
             onClick={() => setEditMode(true)}>&nbsp;{value}
            <Button onClick={() => setEditMode(true)}
                    size="small" shape="circle"><EditOutlined/>
            </Button>
        </div>;

}

function renderEditableCell(text, record, index) {

    function onCellChange(value, index, record) {
        record.comment = value;
    }

    return <EditableCell value={text} record={record} index={index} onChange={onCellChange}/>

}


export function SynthEnding(props) {

    const [selectedRows, setSelectedRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [defectWindow, setDefectWindow] = useState(false);
    const [device, setDevice] = useState({});
    const [places, setPlaces] = useState([]);
    const [comment, setComment] = useState('');
    const [status, setStatus] = useState(0);
    const [dataByGroup, setDataByGroup] = useState({});

    const date = props.match.params.date
    const device_id = props.match.params.device_id
    const cycle_id = props.match.params.cycle_id

    function applyColNums(rows) {
        const result = [...rows];
        const byGroup = {};
        let curGroup = '';
        let colNum = 1;
        result.forEach(row => {
            if (row.column !== curGroup) {
                curGroup = row.column;
                colNum = 1;
            }
            row.col_num = colNum++;
            if (byGroup[row.column]) {
                byGroup[row.column].push(row);
            } else {
                byGroup[row.column] = [];
                byGroup[row.column].push(row);
            }
            row.work_sequence = row.current_oligonucleotide?.full_sequence || '-';
            row.target_sequence = row.oligonucleotid?.full_sequence || '-';
        });
        setDataByGroup(byGroup);
        return result;
    }

    function loadData() {
        api.getJSON(`/api/synthesis/list/${date}/${device_id}/${cycle_id}`).then(result => {
            setData(applyColNums(result.items));
            setComment(result.comment);
            setStatus(result.status);
            setDevice(result.device);
            setPlaces(result.places);
        })
    }

    useEffect(() => {
        if (!loading) {
            loadData();
            setLoading(true);
        }
    })

    function doEnding() {
        if (!selectedRows.length) {
            NotifyError('Выберите пробирки для завершения синтеза')
            return
        }
        const postData = {
            portions: data.filter(item => selectedRows.includes(item.id)).map(item => ({
                id: item.id,
                stage_comment: item.comment
            })),
            comment: comment,
        };
        api.postJSON('/api/synthesis/close', postData).then(result => {
            if (result.success) {
                NotifyInfo('Синтезирование успешно завершено.');
                setComment('');
                setSelectedRows([]);
                loadData();
            } else {
                NotifyInfo('Произошла ошибка: ' + result.error);
            }
        })

    }

    function getColumns() {
        return [
            {title: '№ Колонки', dataIndex: 'position', key: 'position', width: 50, align: 'center'},
            {title: 'Название', dataIndex: 'name', key: 'name', width: 200},
            {
                title: 'Последовательность',
                dataIndex: 'full_sequence',
                key: 'full_sequence',
                width: 500,
                render: (value, record, index) => renderSequence(value, record, index, 54),
            },
            {title: 'Масштаб', dataIndex: 'scale', key: 'scale', width: 100, align: 'center'},
            {title: 'Длина', dataIndex: 'length', key: 'length', width: 100, align: 'center'},
            {
                title: 'Тип', dataIndex: 'type', key: 'type', width: 250, render: (text, record) => {
                    return record.types && record.types.length ? record.types.map(item => item.name).join(', ') : '';
                }
            },
            {title: 'Комментарий', dataIndex: 'stage_comment', key: 'stage_comment', render: renderEditableCell},
        ]
    }

    function onSelectChange(key, selectedRowKeys) {
        const sameGroupIds = data.filter(item => item.column === key).map(item => item.id);
        const oldRowsKeys = selectedRows.filter(item => !sameGroupIds.includes(item));
        setSelectedRows([...oldRowsKeys, ...selectedRowKeys]);
    }

    function showDefectWindow() {
        if (!selectedRows.length) {
            NotifyError('Выберите пробирки');
        } else {
            setDefectWindow(true);
        }
    }

    function onHideDefectWindow() {
        setDefectWindow(false);
        loadData();
    }

    function getSelectedData() {
        let result = selectedRows.map(key => data.filter(item => item.id === key)[0]);
        return result;
    }

    function getDefectColumns() {
        return getColumns();
    }

    function selectAll() {
        setSelectedRows(data.map(item => item.id));
    }

    function getProgramByScale(scale) {
        const result = places.filter(item => item.scale === scale);
        if (result.length) {
            return result[0].program || '-';
        }
        return '-';
    }

    function saveComment() {
        api.postJSON(`/api/synthesis/comment/${date}/${device_id}/${cycle_id}`, {comment: comment}).then(result => {
            if (result.success) {
                NotifyInfo('Сохранение комментария успешно завершено.');
            } else {
                NotifyInfo('Произошла ошибка: ' + result.error);
            }
        })

    }

    function saveStatus(status) {
        api.postJSON(`/api/synthesis/comment/${date}/${device_id}/${cycle_id}`, {comment: comment}).then(result => {
            if (result.success) {
                NotifyInfo('Сохранение комментария успешно завершено.');
            } else {
                NotifyInfo('Произошла ошибка: ' + result.error);
            }
        })

    }

    return <>
        <PageHeader
            title="Завершение синтеза"
        />
        <div style={{background: '#fff', padding: 24, minHeight: 280}}>
            <Row style={{marginBottom: '20px'}}>
                <Col span={4}>
                    {dayjs(date).format('LL')}
                </Col>
                <Col span={8}>
                    Синтезатор: {device ? device.name : ''}
                </Col>
                <Col span={6}>
                    <Button type="primary" onClick={showDefectWindow}>Указать бракованные</Button>
                </Col>
                <Col span={6} align="right">
                    <Space size={10}>
                        <Button type={"primary"} onClick={doEnding}>Передать на следующий этап</Button>
                        <Link to={'/synth'}>
                            <Button>Закрыть</Button>
                        </Link>
                    </Space>
                </Col>
            </Row>
            <Row>
                <Col span={1} style={{display: 'flex', alignItems: 'end', justifyContent: 'center'}}>
                    <Button onClick={selectAll} icon={<CheckCircleOutlined/>}></Button>
                </Col>
                <Col span={23}>
                    <Row type="flex" style={{alignItems: 'center'}}>
                        <Col span={21}>
                            <label>Комментарий
                                <Input.TextArea value={comment}
                                                onChange={e => setComment(e.target.value)}></Input.TextArea>
                            </label>
                        </Col>
                        <Col span={2} style={{paddingLeft: "10px"}}>
                            <Button onClick={saveComment}>Сохранить</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{marginTop: '20px'}}>
                <Col span={24}>
                    {Object.keys(dataByGroup).map(key => {
                        const d = dataByGroup[key];
                        return <div>
                            <Row gutter={10}>
                                <Col style={{padding: 10}}>
                                    <Space key={1} size={'small'}>{key}
                                        Масштаб: {d[0] && d[0].scale}
                                        Программа: {getProgramByScale(d[0] && d[0].scale)}
                                    </Space>
                                </Col>
                            </Row>
                            <Table key={key} rowKey="id" dataSource={d}
                                   size={'small'}
                                   columns={getColumns()}
                                   showCopyPaste={false}
                                   pagination={false}
                                   rowSelection={{
                                       selectedRowKeys: selectedRows.filter(item => d.map(i => i.id).includes(item)),
                                       onChange: (keys) => onSelectChange(key, keys),
                                   }}>
                            </Table>
                        </div>
                    })}
                </Col>
            </Row>
            {defectWindow ?
                <Defect columns={getDefectColumns()} data={getSelectedData()} onHide={onHideDefectWindow}/> : null}
        </div>
    </>

}
