import {Col, Modal, Row, Space, Table} from "antd";
import {useEffect, useState} from "react";
import {getDefaultOperationColumns} from "../Shared";
import {OperationsApi, ServerOperations} from "../Operatoins";
import PropTypes from "prop-types";

function ToMass(props) {

    const [portionsToSend, setPortionsToSend] = useState([]);

    function onOk() {
        OperationsApi.doOperation(ServerOperations.TO_MASS_SPECTR, portionsToSend, {
            from_stage_id: props.StageId,
        }).then((result) => {
            props.onHide();
        });
    }

    function updateData(index, record) {
        const newData = props.data;
        newData[index] = record;
        const dataToSend = newData.map(item => ({
            id: item.id, params: {}
        }));
        setPortionsToSend(dataToSend);
    }

    useEffect(() => {
        updateData();
    }, [])

    return <Modal title="Масс спектрометрия" width={1400} onOk={onOk}
                  onCancel={props.onHide} open={true} okText={'OK'}
                  cancelText={'Отмена'} destroyOnClose={true}
    >
        <Row>
            <Col span={24}>
                <Table rowKey={"id"} size={'small'} dataSource={props.data} columns={getDefaultOperationColumns()}/>
            </Col>
        </Row>

    </Modal>;

}

ToMass.propTypes = {
    StageId: PropTypes.number.isRequired,
};

export default ToMass;
