import {Table} from "antd";
import PropTypes from "prop-types";
import dayjs from "dayjs";

export function Places(props) {

    const columns = [
        {
            title: 'Сведения о синтезе', children: [
                {
                    title: 'Дата',
                    dataIndex: 'created_at',
                    key: 'created_at',
                    width: 150,
                    render: (v, row) => {
                        return dayjs(row.calendar.date).format('LL');
                    }
                },
                {
                    title: 'Оборудование/период',
                    dataIndex: 'device_short_name',
                    key: 'device_short_name',
                    width: 200,
                    render: (value, row) => {
                        return `${row.device_short_name} / ${row.device_cycle_name}`;
                    }
                },
                {
                    title: 'Позиция пробирки',
                    dataIndex: 'device_place_position',
                    key: 'device_place_position',
                    width: 200,
                    render: (value, row) => {
                        return `${row.device_place_name} / ${row.device_place_position}`;
                    }
                },
                {
                    title: 'Масштаб',
                    dataIndex: 'scale',
                    key: 'scale',
                    width: 100,
                    render: (value, row) => {
                        return `${row.scale.scale} ${row.scale.dimension}`;
                    }
                }
            ]
        },
    ];

    return <Table columns={columns} size={'small'} dataSource={props.data} pagination={false}/>;
}

Places.propTypes = {
    data: PropTypes.array.isRequired,
}
