import {
    getMassResultsColumns, getStockColumns,
    mapDataFunction,
    operationMarkup as oms,
    operations as ops, useColumns
} from "../Stages/Shared";
import {Col, Row} from "antd";
import StageManager from "../Stages/StageManager";

export function StockBase() {

    const {columns, filters} = useColumns();
    const restColumns = columns.concat(getStockColumns(), getMassResultsColumns());

    function getOperations() {
        return [
            [
                oms[ops.DEFECT], oms[ops.NEXT_STAGE], oms[ops.TO_PACKAGING]
            ],
        ];
    }

    return <Row>
        <Col span={24}>
            <StageManager
                operationButtons={getOperations()}
                columns={restColumns}
                mapFucntion={mapDataFunction}
                url={"/api/stock"}
                queryParams={['status=3']}
                filter={filters}
                extraFilter={null}
                stage={'stock'}
            />
        </Col>
    </Row>;
}
