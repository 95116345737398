import {createContext, useState} from "react";
import {useHotkeys} from "react-hotkeys-hook";
import {useHistory} from "react-router";

export const HotKeysContext = createContext(null);

const HotKeysProvider = ({children}) => {

    const [hotKeysEnabled, setHotKeysEnabled] = useState(true)
    const history = useHistory();

    useHotkeys('esc', () => {
        history.push('/main');
    }, {enabled: hotKeysEnabled});

    return (
        <HotKeysContext.Provider value={{hotKeysEnabled, setHotKeysEnabled}}>
            {children}
        </HotKeysContext.Provider>
    );
}

export default HotKeysProvider;