import PropTypes from "prop-types";
import {Button, Space, Table, Tooltip} from "antd";
import dayjs from "dayjs";
import {cut} from "../lib/util";
import {HistoryOutlined} from "@ant-design/icons";

const OpHistory = props => {

    const columns = [
        {
            title: 'Дата',
            fieldName: 'created_at',
            dataIndex: 'created_at',
            width: 150,
            render: (text, record) => {
                return <span>{dayjs(record.created_at).format('LLL')}</span>
            }
        },
        {
            title: 'Операция',
            fieldName: 'name',
            dataIndex: 'name',
            width: 200
        },
    ]

    // исправление ошибки
    // с сервера приходит неверный id
    const remap_history = (history) => {
        let fake_id = 1;
        return history.map(item => {
            return {
                ...item,
                id: fake_id++
            }
        })
    }

    const short_history = () => {
        return cut(props.history.map(h => h.name).reverse().slice(0, props.limit).join(", "), 23);
    }

    const render = () => {

        const table = <>
            <Table columns={columns} size={'small'} dataSource={remap_history(props.history)} rowKey={'id'}
                   pagination={false}/>
        </>

        return <>
            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                {short_history()}
                {props.history.length > 1 ? <Tooltip destroyTooltipOnHide={true}
                                                     placement={'left'}
                                                     color={'#1a8d90'}
                                                     autoAdjustOverflow={true}
                                                     arrow={{pointAtCenter: true}}
                                                     title={table}><Button type="primary"
                                                                           size={'small'}>i</Button></Tooltip> : null}
                <Button icon={<HistoryOutlined/>} size={'small'} onClick={() => {
                    window.location.href = `/portion/${props.record.id}`;
                }}/>
            </div>
        </>

    }

    return props.history ? render() : '-';

}

OpHistory.history = PropTypes.array.isRequired;
OpHistory.record = PropTypes.object;
OpHistory.defaultProps = {
    history: [],
    limit: 1,
    record: {}
}

export default OpHistory;
