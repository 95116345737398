import {Col, Row, Select, Space, Table, Modal, ConfigProvider, Radio, Button, Input} from "antd";
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import locale from 'antd/lib/locale/ru_RU';
import {getDefaultOperationColumns} from "../Shared";
import {OperationsApi, ServerOperations} from "../Operatoins";
import MaterialParties from "../../Material/MaterialParties";
import NoFutureDate from "../../../components/NoFutureDate";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";

export function Elution(props) {

    const initialResultProbs = [
        {id: 1, prefix: '', comment: ''},
        {id: 2, prefix: '', comment: ''},
    ];

    const [id, setId] = useState(null);
    const [reagent, setReagent] = useState();
    const [started, setStarted] = useState();
    const [finished, setFinished] = useState();
    const [comment, setComment] = useState();
    const [queueId, setQueueId] = useState();
    const [materials, setMaterials] = useState();
    const [elutionResult, setElutionResult] = useState(3);
    const [resultProbs, setResultProbs] = useState(initialResultProbs);

    const [portionsToSend, setPortionsToSend] = useState();

    function onOk() {

        const ids = materials ? materials.map(m => m.id) : [];

        if (queueId) {
            OperationsApi.editOperation(ServerOperations.ELUTION, queueId, portionsToSend, {materials: ids}).then((result) => {
                props.onHide();
            });
        } else {
            OperationsApi.doOperation(ServerOperations.ELUTION, portionsToSend, {materials: ids}).then((result) => {
                props.onHide();
            });
        }
    }

    const reagentOptions = [
        {id: '0,5M буфер ТБЕ', value: '0,5M буфер ТБЕ'},
        {id: '0,05M буфер ТБЕ', value: '0,05M буфер ТБЕ'},
        {id: '0,005M буфер ТБЕ', value: '0,005M буфер ТБЕ'},
        {id: '10% персульфат аммония', value: '10% персульфат аммония'},
        {id: '80% формамид', value: '80% формамид'},
        {id: 'Буфер для р-ра акриламида', value: 'Буфер для р-ра акриламида'},
        {id: 'Раствор акриламида', value: 'Раствор акриламида'},
        {id: 'Маркерный краситель', value: 'Маркерный краситель'},
    ];

    function handleChangeReagent(value) {
        setReagent(value);
    }

    function updateData() {
        const dataToSend = props.data.map(item => ({
            id: item.id, params: {
                reagent: reagent,
                started_at: started,
                finished_at: finished,
                elutionResult: elutionResult,
                resultProbs,
                comment
            }
        }));
        setPortionsToSend(dataToSend);
    }

    function OKDisabled() {
        let result = !(reagent && started);
        if (finished) {
            if (elutionResult === 1) {
                result = result || resultProbs.length === 0 || resultProbs.some(item => !item.prefix);
            }
        }
        return result;
    }

    useEffect(() => {
        updateData();
    }, [reagent, started, finished, comment, resultProbs]);

    useEffect(() => {
        if (props.data[0].elution?.pivot?.params) {
            if (!props.data[0].elution.pivot.params.finished_at) {
                setId(props.data[0].elution.pivot.id);
                setReagent(props.data[0].elution.pivot.params.reagent);
                setStarted(props.data[0].elution.pivot.params.started_at);
                setFinished(props.data[0].elution.pivot.params.finished_at);
                setComment(props.data[0].elution.pivot.params.comment);
                setQueueId(props.data[0].elution.pivot.portion_to_operation_id);
                setElutionResult(props.data[0].elution.pivot?.params.elutionResult);
                setResultProbs(props.data[0].elution.pivot?.params?.resultProbs);
                setMaterials(props.data[0].elution.params.materials);
            }
        }
    }, [props.data]);

    function getResultColumns() {

        function editField(prefix, value, index) {
            const newResultProbs = [...resultProbs];
            newResultProbs[index][prefix] = value;
            setResultProbs(newResultProbs);
        }

        return [
            {id: 1, dataIndex: 'num', key: 'num', title: '№', width: 50, render: (value, record, index) => (index + 1)},
            {
                id: 2, dataIndex: 'prefix', key: 'prefix', title: 'Префикс', width: 200,
                render: (value, record, index) => {
                    return <Input value={value} onChange={(e) => editField('prefix', e.target.value, index)}/>
                }
            },
            {
                id: 3,
                dataIndex: 'comment',
                key: 'comment',
                title: 'Комментарий',
                render: (value, record, index) => {
                    return <Input value={value} onChange={(e) => editField('comment', e.target.value, index)}/>
                }
            },
            {
                id: 4, dataIndex: 'delete', key: 'delete', title: '', width: 50, align: 'center',
                render: (text, record) => <Button disabled={resultProbs.length === 2} type={'link'} onClick={() => {
                    setResultProbs(resultProbs.filter(item => item.id !== record.id));
                }}>
                    <DeleteOutlined/>
                </Button>
            }
        ];
    }

    return <Modal
        title="Элюция"
        open={true}
        width={1300}
        okButtonProps={{disabled: OKDisabled()}}
        onOk={onOk}
        onCancel={props.onHide}
        okText={'OK'}
        cancelText={'Отмена'}
        destroyOnClose={true}
    >
        <Row>
            <Col span={12}>
                Дата и время начала
                <ConfigProvider locale={locale}>
                    <NoFutureDate format={'DD.MM.YYYY HH:mm'} value={started ? dayjs(started) : null}
                                  placeholder={'Дата и время'}
                                  showTime
                                  onChange={(value) => setStarted(value)}
                                  style={{marginLeft: 10}}
                    />
                </ConfigProvider>
            </Col>
            <Col span={12}>
                Дата и время окончания
                <ConfigProvider locale={locale}>
                    <NoFutureDate format={'DD.MM.YYYY HH:mm'} value={finished ? dayjs(finished) : null}
                                  placeholder={'Дата и время'}
                                  showTime
                                  onChange={(value) => setFinished(value)}
                                  style={{marginLeft: 10}}
                    />
                </ConfigProvider>
            </Col>
        </Row>
        <Row style={{marginTop: 10}}>
            <Col span={12}>
                <Space size={12} direction="vertical" style={{width: '150px'}}>
                    <div>Реактив</div>
                    <Select style={{width: 350}} value={reagent} options={reagentOptions}
                            onChange={handleChangeReagent}/>
                </Space>
            </Col>
            <Col span={12}>
                <Space size={12} direction="vertical" style={{width: '450px'}}>
                    <div>Комментарий</div>
                    <TextArea value={comment} onChange={(e) => setComment(e.target.value)}/>
                </Space>
            </Col>
        </Row>
        <Row style={{marginTop: 20}}>
            <Col span={24}>
                Результат элюции: &nbsp;
                <Radio.Group value={elutionResult} onChange={(e) => setElutionResult(e.target.value)}>
                    <Radio key={3} value={3}>1 пробирка</Radio>
                    <Radio key={1} value={1}>Пробирки</Radio>
                </Radio.Group>
            </Col>
        </Row>

        {elutionResult === 1 && <Row>
            <Col span={24}>
                <Space direction={'vertical'} style={{width: '100%'}}>
                    Пробирки:
                    <Table dataSource={resultProbs} columns={getResultColumns()} rowKey={'id'}
                           size={'small'}
                           scroll={{x: 'max-content', y: 300}}
                           pagination={false}
                    />
                    <div style={{marginTop: '10px'}}>
                        <Button type={'primary'} onClick={() => {
                            setResultProbs([...resultProbs, {
                                id: resultProbs.length > 0 ? Math.max(...resultProbs.map(item => item.id)) + 1 : 1,
                                prefix: '',
                                comment: ''
                            }])
                        }}>
                            <PlusOutlined/> Добавить
                        </Button>
                    </div>
                </Space>
            </Col>
        </Row>}
        <Row>
            <Col span={24}>
                <MaterialParties onChange={(v) => {
                    setMaterials(v);
                }} initialValue={materials}/>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <Table rowKey="id" size={'small'} dataSource={props.data} columns={getDefaultOperationColumns()}
                       style={{marginTop: 10}}/>
            </Col>
        </Row>
    </Modal>

}
