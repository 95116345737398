import React from "react";
import PageHeader from "../../components/mock/PageHeader";
import Materials from "./Materials";


const MaterialList = () => {
    return <>
        <PageHeader title="Материалы" path={'/materials'}/>
        <div style={{background: '#fff', padding: 24, minHeight: 280}}>
            <Materials />
        </div>
    </>;
}

export default MaterialList;