import React, {useEffect, useState} from 'react';
import {Modal, Checkbox, Table} from "antd";
import PropTypes from "prop-types";

const extractText = (node) => {
    if (typeof node === 'string') {
        return node;
    }
    if (typeof node === 'object' && node !== null) {
        if (node.props && node.props.children) {
            return React.Children.toArray(node.props.children).map(extractText).join('');
        }
    }
    return '';
};

const saveHiddenColumns = (stage, hiddenColumns) => {
    localStorage.setItem(`${stage}_hidden_columns`, JSON.stringify(hiddenColumns));
}

export const loadHiddenColumns = (stage) => {
    let result = JSON.parse(localStorage.getItem(`${stage}_hidden_columns`));
    return result || [];
}

const ColumnsVisibleEditor = ({stage, initialColumns, onHide, open}) => {

    const [visibleColumns, setVisibleColumns] = useState([]);
    const columns = [
        {id: 1, title: 'Столбец', dataIndex: 'title'},
    ];

    let data = [];
    initialColumns.forEach((column, index) => {
        data.push({id: column.dataIndex, title: extractText(column.title), dataIndex: column.dataIndex});
    });

    useEffect(() => {
        const hiddenColumns = loadHiddenColumns(stage) || [];
        const visibleColumns = initialColumns.filter(column => !hiddenColumns.includes(column.dataIndex)).map(column => column.dataIndex);
        setVisibleColumns(visibleColumns);
    }, []);

    return <Modal title="Настройка столбцов" open={open}
                  destroyOnClose={true}
                  onCancel={() => onHide()}
                  onOk={() => {
                      const hiddenColumns = initialColumns.map(column => column.dataIndex).filter(column => !visibleColumns.includes(column));
                      saveHiddenColumns(stage, hiddenColumns);
                      onHide();
                  }}>
        <Table rowKey={"id"} columns={columns} dataSource={data} scroll={{y: 400}}
               pagination={false}
               rowSelection={{
                   type: 'checkbox',
                   selectedRowKeys: visibleColumns,
                   onChange: (selectedRowKeys, selectedRows) => {
                       setVisibleColumns(selectedRowKeys);
                   }
               }}/>
    </Modal>
}

ColumnsVisibleEditor.propTypes = {
    stage: PropTypes.string.isRequired,
    initialColumns: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired
}

export default ColumnsVisibleEditor;

